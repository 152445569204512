import { Card, DatePicker, Spin } from "antd";
import dayjs from 'dayjs';
import _ from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { selectFilters, setFilters } from "../../../redux/DashboardSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectMasterDataDictByType } from "../../../redux/MasterDataSlice";
import { useProjectStatusStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { AntDRangePresets } from "../../common/Constants";
import { DashboardStatsType, ProjectStatus } from "../../common/Enums";

const ProjectStatusColors: Record<number, string> = {
  [ProjectStatus.Open]: '#e0e0e0',
  [ProjectStatus.Submitted]: '#4fc3f7',
  [ProjectStatus.DidNotParticipate]: '#ffb74d',
  [ProjectStatus.Won]: '#4db6ac',
  [ProjectStatus.Lost]: '#e57373',
}

const ProjectStatusOvertimeStats: React.FC = () => {
  const [options, setOptions] = useState<any>(null);
  const [series, setSeries] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<any>(null);
  const projectStatusDict = useAppSelector(state => selectMasterDataDictByType(state, "ProjectStatus"));
  const [triggerProjectStatusStats, projectStatusStatsResult] = useProjectStatusStatsMutation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(state => selectFilters(state, DashboardStatsType.ProjectStatusOvertime));
  
  const onRangeChange = (range: any) => {
    if (range) {
      let toDate: dayjs.Dayjs = range[1];
      range[1] = toDate.endOf('M');
    }

    setDateRange(range);
    loadStats(range);

    dispatch(setFilters({
      type: DashboardStatsType.ProjectStatusOvertime,
      filters: {
        range: [range[0].format("MM/DD/YYYY"), range[1].format("MM/DD/YYYY")]
      }
    }));
  }

  const loadStats = (dateRange: dayjs.Dayjs[]) => {
    triggerProjectStatusStats({
      fromDate: dateRange?.[0].format("MM/DD/YYYY") || null,
      toDate: dateRange?.[1].format("MM/DD/YYYY") || null
    });
  }

  const initOptions = (chartColors: string[], chartLabels: string[]) => {
    setOptions({
      labels: chartLabels,
      colors: chartColors,
      chart: {
        toolbar: {
          show: false
        },
        events: {
          selection: function (chart: any, e: any) {
            console.log(new Date(e.xaxis.min))
          }
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        },
        formatter: function (val:any, opts:any) {
          return opts.w.config.series[opts.seriesIndex]
        },
      },
      pie: {
        offsetX: -40,
        customScale: 1,
      },
      legend: {
        position: 'right',
        offsetY: 50,
        fontSize: '13px',
        formatter: function (seriesName: any, opts: any) {
          return [seriesName, " : ", opts.w.globals.series[opts.seriesIndex]]
        },
      }
    });
  }

  useEffect(() => {
    let dateRange = AntDRangePresets?.[0].value;

    if(filters?.range){
      dateRange = [dayjs(filters.range[0]), dayjs(filters.range[1])];
    }

    onRangeChange(dateRange);
  }, []);

  useEffect(() => {
    if (projectStatusStatsResult.requestId && !projectStatusStatsResult.isLoading &&
      projectStatusStatsResult.data && projectStatusStatsResult.data.success) {

      let chartLabels: string[] = [], chartSeries: number[] = [], chartColors: string[] = [];

      _.forEach(projectStatusStatsResult.data.data, x => {
        chartLabels.push(projectStatusDict[x.statusId].name);
        chartSeries.push(x.total);
        chartColors.push(ProjectStatusColors[x.statusId]);
      });

      setSeries(chartSeries);
      initOptions(chartColors, chartLabels);
    }
  }, [projectStatusStatsResult]);

  return (
    <Card className="h-100">
      <Spin spinning={projectStatusStatsResult.isLoading}>
        <div className='d-flex align-items-center justify-content-between mb-5'>
          <span className='fs-3 fw-bold text-dark me-2 lh-1'>Projects Status</span>
          <DatePicker.RangePicker
            presets={AntDRangePresets}
            picker="month"
            size="small"
            value={dateRange}
            onChange={onRangeChange}
          />
        </div>
        {
          options !== null &&
          <ReactApexChart options={options}
            series={series}
            type="pie"
            width={'100%'}
            height={'300'} />
        }
      </Spin>
    </Card>
  )
}

export { ProjectStatusOvertimeStats };

