import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

const ErrorCommon: FC = () => {
  const { state }: any = useLocation();
  
  return (
    <>
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>

      <div className='fw-semibold fs-2 text-gray-500 mb-7'>
        {state.error_description || "Some error occurred, while processing request."}</div>

      <div className='mb-0'>
        <Link to={state.redirect_url || '/dashboard'} className='btn btn-sm btn-primary'>
          Return
        </Link>
      </div>
    </>
  )
}

export { ErrorCommon };

