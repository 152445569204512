import { useNavigate, useParams } from "react-router-dom";
import { Tabs, TabsProps } from "antd";

const RoutedTabs = (props: TabsProps) => {
    const navigate = useNavigate();
    const { source } = useParams();

    return (
        <>
            <Tabs
                {...props}
                activeKey={source}
                onChange={(key: string) => {
                    navigate(`../${key}`); // sibling path
                }}
            />
        </>
    )
}

export { RoutedTabs }