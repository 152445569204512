import { DeleteOutlined, EditOutlined, FilterOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Divider, Input, List, Modal, Popconfirm, Popover, Row, Select, Space, Tag } from "antd";
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetDatasetSelectListQuery } from '../../../redux/rtkquery/DatasetApi';
import { useDeleteQuestionMutation, useListMutation } from "../../../redux/rtkquery/LibraryApi";
import { filterOptions, getDatasetGroups } from '../../common/CommonFunctions';
import AttachmentTag from '../../common/components/AttachmentTag';
import HTMLContent from '../../common/components/HtmlContent';
import { LibraryBasicFilterModel, LibraryFilterModel, LibraryQuestionModel } from "../../models";
import { AddEditQuestion } from "./AddEditQuestion";
import { ImportQuestions } from './ImportQuestions';

export const Library: React.FC = () => {
    const [triggerGetList, getListResult] = useListMutation();
    const [triggerDeleteQuestion, deleteQuestionResult] = useDeleteQuestionMutation();
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [filterOpen, setFilterOpen] = useState(false);
    const [questionStartNo, setQuestionStartNo] = useState(1);
    const [selectedQuestion, setSelectedQuestion] = useState<LibraryQuestionModel | null>(null);
    const [questions, setQuestions] = useState<LibraryQuestionModel[]>([]);
    const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
    const [hasImportFilter, setHasImportFilter] = useState<boolean>(false);
    const [filters, setFilters] = useState<LibraryFilterModel>({
        datasetIds: null,
        keyword: null,
        import_id: null,
        offset: 0,
        currentPage: 1,
        pageSize: 10,
        totalRecords: 0
    });

    const getFiltersText = () => {
        let count = 0;

        filters.keyword && (count++);
        filters.import_id && (count++);
        _.size(filters.datasetIds) && (count++);

        return `Filters (${count})`;
    }

    useEffect(() => {
        loadQuestions(filters);
    }, []);

    const loadQuestions = (filters: LibraryFilterModel) => {
        triggerGetList(filters).then(response => {
            if ('data' in response) {
                let listingModel = response.data.data;
                setQuestions(listingModel?.libraryQuestionList || []);

                if (filters.totalRecords === 0) {
                    filters.totalRecords = listingModel?.totalRecords || 0;
                    setFilters({ ...filters });
                }
                setQuestionStartNo(filters.offset + 1);
            }
        })
    }

    const onDeleteQuestion = async (questionId: number) => {
        let response = await triggerDeleteQuestion({ libraryQuestionId: questionId });

        if ('data' in response && response.data.success) {
            filters.currentPage = questions.length > 1 ? filters.currentPage :
                (filters.currentPage > 1 ? --filters.currentPage : 1);
            filters.offset = (filters.currentPage - 1) * filters.pageSize;
            filters.totalRecords = 0;

            setFilters({ ...filters });
            loadQuestions(filters);
        }
    }

    const onQuestionModalClose = (question?: LibraryQuestionModel) => {

        if (question) {
            filters.totalRecords = 0;
            setFilters({ ...filters });
            loadQuestions(filters);
        }

        setSelectedQuestion(null);
    }

    const addQuestion = () => {
        setSelectedQuestion({
            libraryQuestionId: 0,
            question: '',
            answer: '',
            datasetId: null,
            alternativeQuestion: [],
            libraryDocument: []
        });
    }

    const onPagingChange = (page: number, size: number) => {
        let _filters = { ...filters };
        _filters.currentPage = filters.pageSize !== size ? 1 : page;
        _filters.pageSize = size;
        _filters.offset = (_filters.currentPage - 1) * size;
        loadQuestions(_filters);
        setFilters(_filters);
    }

    const handleFilterOpen = (newOpen: boolean) => {
        setFilterOpen(newOpen);
    }

    const onApplyFilters = (_filters: LibraryBasicFilterModel, closePopup: boolean) => {
        filters.keyword = _filters.keyword || null;
        filters.datasetIds = _filters.datasetIds || null;
        filters.import_id = _filters.import_id || null;
        filters.currentPage = 1;
        filters.offset = 0;
        filters.totalRecords = 0;

        setFilters({ ...filters });
        loadQuestions(filters);
        setFilterOpen(!closePopup);
        setHasImportFilter(_filters.import_id ? true : false);
    }

    const onImportModalClose = (import_id?: number) => {
        if (import_id)
            onApplyFilters({ datasetIds: null, keyword: null, import_id: import_id }, true);
        setImportModalOpen(false);
    }

    return (<>

        {
            hasImportFilter && questions.length > 0 &&
            <Alert className="mb-4 px-4 py-4"
                showIcon={true}
                message={<>
                    The following questions were imported from the file.
                    <Button type="link"
                        className='ps-1'
                        onClick={() => onApplyFilters({}, true)}
                    >
                        See all library questions
                    </Button>
                </>}
            />
        }

        <Card title={<h2>Response Library</h2>}
            extra={<Space>
                <Popover
                    content={
                        <LibraryFilter filters={filters} onApply={onApplyFilters} />
                    }
                    trigger="click"
                    open={filterOpen}
                    onOpenChange={handleFilterOpen}
                    placement='bottomRight'
                >
                    <Button type="default" icon={<FilterOutlined rev={0} />}>
                        {
                            getFiltersText()
                        }
                    </Button>
                </Popover>
                <Button type="primary" onClick={addQuestion}>Add Question</Button>
                <Button type="primary" onClick={() => setImportModalOpen(true)}>Upload File</Button>
            </Space>}
        >
            <List
                loading={getListResult.isLoading || deleteQuestionResult.isLoading}
                pagination={{
                    align: "center",
                    showSizeChanger: true,
                    current: filters.currentPage,
                    pageSize: filters.pageSize,
                    onChange: onPagingChange,
                    total: filters.totalRecords,
                    hideOnSinglePage: filters.totalRecords > 10 ? false : true
                }}
                dataSource={questions}
                renderItem={(item, index) => (
                    <Row>
                        <Col xs={24} sm={4} md={2} lg={1}>
                            <div className='mb-2'>
                                <span className='d-sm-none fw-bold'>Q. No.</span> {(questionStartNo + index)}
                            </div>
                        </Col>
                        <Col xs={24} sm={20} md={22} lg={23}>
                            <Card className='mb-4 flex-grow-1'
                                headStyle={{ padding: '0' }}
                                size='small'
                                bordered={true}
                                title={<div className='py-3 px-4 bg-light d-flex'>
                                    <div className='flex-grow-1 d-linebreak'>
                                        {item.question}
                                        {
                                            _.size(item.alternativeQuestion) > 0 ?
                                                <ul className='fw-normal fs-7 mt-2 border-start border-4 text-gray-700'>
                                                    {_.map(item.alternativeQuestion, x =>
                                                        <li key={x.libraryQuestionId}>{x.question}</li>
                                                    )}
                                                </ul>
                                                : null
                                        }
                                    </div>
                                    <Space className='align-self-start'>
                                        <Button size='small' type='text' icon={<EditOutlined rev={0} />} onClick={() => setSelectedQuestion(item)} />
                                        <Popconfirm
                                            title="Delete"
                                            description="Are you sure to delete this question from library?"
                                            onConfirm={() => onDeleteQuestion(item.libraryQuestionId)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button size='small' type='text' icon={<DeleteOutlined rev={0} />} />
                                        </Popconfirm>

                                    </Space>
                                </div>
                                }
                            >
                                {
                                    !_.isNil(item.datasetId) &&
                                    <Tag color="cyan">
                                        {allDatasetListState.data?.find(x => x.datasetId === item.datasetId)?.datasetName}
                                    </Tag>
                                }

                                <HTMLContent className='mt-3' content={item.answer} lines={5} />

                                {
                                    _.size(item.libraryDocument) > 0 &&
                                    <>
                                        <Divider className='mb-3 mt-2' />
                                        {
                                            _.map(item.libraryDocument, x => (
                                                <AttachmentTag key={x.attachmentId}
                                                    style={{ maxWidth: '250px' }}
                                                    fileName={x.fileDisplayName}
                                                    fileGuid={x.fileName}
                                                />
                                            ))
                                        }
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                )}
            />
        </Card>
        {
            selectedQuestion &&
            <AddEditQuestion onClose={onQuestionModalClose} question={selectedQuestion} />
        }
        {
            <Modal open={importModalOpen}
                footer={null}
                destroyOnClose={true}
                width={800}
                onCancel={() => onImportModalClose()}>
                <ImportQuestions onClose={onImportModalClose} />
            </Modal>
        }
    </>)
}

const LibraryFilter: React.FC<{
    filters: LibraryBasicFilterModel,
    onApply: (filters: LibraryBasicFilterModel, closePopup: boolean) => void
}> = (props) => {
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [filters, setFilters] = useState(_.cloneDeep(props.filters));

    const onKeywordChange = (keyword: string) => {
        setFilters(prev => ({ ...prev, keyword }));
    }

    const onDatasetsChange = (datasetIds: number[]) => {
        setFilters(prev => ({ ...prev, datasetIds }));
    }

    const onImportIdRemove = () => {
        setFilters(prev => ({ ...prev, import_id: null }));
    }

    const onApply = () => {
        let _filters = { ...filters };
        _filters.keyword = _.trim(filters.keyword || '');
        props.onApply(filters, true);
    }

    const onClear = () => {
        filters.keyword = null;
        filters.datasetIds = null;
        filters.import_id = null;
        setFilters({ ...filters });
        props.onApply(filters, false);
    }

    const datasetGroups = useMemo(() => {
        return getDatasetGroups(allDatasetListState.data || [])
    }, [allDatasetListState]);

    const filterOption = useCallback(filterOptions, [allDatasetListState]);

    return (<>
        <div className='mb-4'>
            <label>Keyword</label>
            <Input
                value={filters.keyword || ''}
                onChange={(e) => onKeywordChange(e.target.value)}
            />
        </div>
        <div className='mb-4'>
            <label>Dataset</label>
            <Select showSearch
                className='w-100'
                mode='multiple'
                maxTagCount={1}
                value={filters.datasetIds || []}
                options={datasetGroups}
                placeholder="Select Dataset"
                loading={allDatasetListState.isFetching}
                onChange={onDatasetsChange}
                filterOption={filterOption}
            />
        </div>

        {
            filters.import_id &&
            <Tag closeIcon onClose={onImportIdRemove}>Import ID : {filters.import_id}</Tag>
        }

        <Divider />
        <div className='text-end'>
            <Space>
                <Button onClick={onClear}>Clear</Button>
                <Button type='primary' onClick={onApply}>Apply</Button>
            </Space>
        </div>
    </>)
}