import { Card, DatePicker, Spin } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { selectFilters, setFilters } from "../../../redux/DashboardSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectMasterDataDictByType } from "../../../redux/MasterDataSlice";
import { useAnswerAutomationTrendStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { AntDRangePresets } from "../../common/Constants";
import { AnswerSourceType, DashboardStatsType } from "../../common/Enums";
import { AnswerAutomationTrendStatModel } from "../../models";

const AnswerSourceTypeColors: Record<string, string> = {
  [AnswerSourceType.NinjaGenerated]: '#3d569b',
  [AnswerSourceType.LibraryGenerated]: '#4fc3f7',
  [AnswerSourceType.Composed]: '#b0bec5',
  [`${AnswerSourceType.NinjaGenerated}-1`]: '#757575',
  [`${AnswerSourceType.LibraryGenerated}-1`]: '#a1887f',
}

type SeriesType = {
  name: string,
  data: number[]
}

const AutomationTrendStats: React.FC = () => {
  const [options, setOptions] = useState<any>(null);
  const [series, setSeries] = useState<SeriesType[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<any>(null);
  const [triggerAutomationStats, automationStatsResult] = useAnswerAutomationTrendStatsMutation();
  const answerSourceTypeDict = useAppSelector(state => selectMasterDataDictByType(state, "AnswerSourceType"));
  const dispatch = useAppDispatch();
  const filters = useAppSelector(state => selectFilters(state, DashboardStatsType.AutomationTrend));

  const onRangeChange = (range: any) => {
    if (range) {
      let toDate: dayjs.Dayjs = range[1];
      range[1] = toDate.endOf('M');
    }

    setDateRange(range);
    setMonthCategories(range);

    dispatch(setFilters({
      type: DashboardStatsType.AutomationTrend,
      filters: {
        range: [range[0].format("MM/DD/YYYY"), range[1].format("MM/DD/YYYY")]
      }
    }));
  }

  const setMonthCategories = (dateRange: dayjs.Dayjs[]) => {
    let _categories = [], dateRanges = _.cloneDeep(dateRange);

    while (dateRanges[0].endOf('M') <= dateRanges[1].endOf('M')) {
      _categories.push(dateRanges[0].format('MMM YY'));
      dateRanges[0] = dateRanges[0].add(1, "M");
    };

    setCategories(_categories);
    return _categories;
  }

  const loadStats = (dateRange: dayjs.Dayjs[]) => {
    triggerAutomationStats({
      fromDate: dateRange?.[0].format("MM/DD/YYYY") || null,
      toDate: dateRange?.[1].format("MM/DD/YYYY") || null
    });
  }

  const initOptions = (chartSeries: SeriesType[], chartColors: string[], chartCategories: string[]) => {
    
    setSeries(chartSeries);

    setOptions({
      colors: chartColors,
      chart: {
        type: 'area',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.2,
          opacityTo: 0.5,
        }
      },
      xaxis: {
        type: 'category',
        categories: chartCategories
      },
      yaxis: {
        min: 0,
        max: 100,
        labels: {
          formatter: function (value: any) {
            return `${value}%`;
          }
        }
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function(value:any) {
            return value + '%'
          }
        }
      },
      legend: {
        showForSingleSeries: true,
        position: 'top',
        horizontalAlign: 'left'
      }
    });
  }

  useEffect(() => {
    let dateRange = AntDRangePresets?.[0].value;

    if(filters?.range){
      dateRange = [dayjs(filters.range[0]), dayjs(filters.range[1])];
    }

    onRangeChange(dateRange);
  }, []);

  useEffect(() => {
    loadStats(dateRange);
  }, [categories]);

  useEffect(() => {
    if (automationStatsResult.requestId && !automationStatsResult.isLoading &&
      automationStatsResult.data && automationStatsResult.data.success) {

      const chartSeries: SeriesType[] = [],
        chartColors: string[] = [],
        categoriesDict: Record<string, {totalAnswer: number, data: AnswerAutomationTrendStatModel[]}> = {};

      let chartCategories: string[] = [];

      let statsData = cloneDeep(automationStatsResult.data.data)

      _.forEach(statsData, x => {
        // Set Soure Type name
        x.sourceType = `${answerSourceTypeDict[x.sourceTypeId].name}${x.isModified ? ' Edits' : ''}`;
        // Set Submission Month display label
        x.submissionOn = dayjs(`${x.submissionYear}-${x.submissionMonth}-01`, 'YYYY-M-D').format("MMM YY");
        
        // Set data grouping based on submission month
        if(!categoriesDict[x.submissionOn])
          categoriesDict[x.submissionOn] = { totalAnswer: 0, data: [] };

        categoriesDict[x.submissionOn].totalAnswer += x.totalAnswer;
        categoriesDict[x.submissionOn].data.push(x);
      });

      // Calculate percentage of answers in submission month
      _.forOwn(categoriesDict, (value) => {
        if(value.totalAnswer > 0)
          _.forEach(value.data, x => x.answerPercentage = Math.round((x.totalAnswer / value.totalAnswer) * 100));
      })

      // Keep only categories which data is available
      chartCategories = categories//.filter(x => categoriesDict[x])

      // Group data based on Source Type
      let stats = _.groupBy(statsData, x => x.sourceType);

      _.forOwn(stats, (data, k) => {
        let seriesDataDict = _.transform(data, (r: Record<string, number>, v) => r[v.submissionOn] = v.answerPercentage, {});

        // Set series color
        chartColors.push(AnswerSourceTypeColors[`${data[0].sourceTypeId}${data[0].isModified ? '-1' : ''}`]);

        chartSeries.push({
          name: k,
          data: chartCategories.map(x => seriesDataDict[x] || 0)
        });
      });

      initOptions(chartSeries, chartColors, chartCategories);
    }
  }, [automationStatsResult]);

  return (
    <Card className="h-100">
      <Spin spinning={automationStatsResult.isLoading}>

        <div className='d-flex align-items-center justify-content-between mb-5'>
          <span className='fs-3 fw-bold text-dark me-2 lh-1'>Automation Trends</span>
          <DatePicker.RangePicker
            presets={AntDRangePresets}
            picker="month"
            size="small"
            value={dateRange}
            onChange={onRangeChange}
          />
        </div>

        {
          options !== null &&
          <ReactApexChart options={options}
            series={series}
            type="area" height={'300'} width={'100%'} />
        }
      </Spin>
    </Card>
  )
}

export { AutomationTrendStats };

