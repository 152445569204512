import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { SystemSettingModel } from '../app/models';
import { getDataAsyncFromAPI } from './requests';
import { RootState } from './store';

export const fetchMasterData = createAsyncThunk('master/getmasterdata', async () => {
    const response = await getDataAsyncFromAPI<SystemSettingModel[]>('master/getmasterdata')
    return response.data
})

interface MasterDataType {
    [type: string]: SystemSettingModel[]
}

export interface MasterDataState {
    data?: MasterDataType,
    status: string,
    error: string|undefined
}

const initialState: MasterDataState = {
    status: 'idle',
    error: undefined
}

export const MasterDataSlice = createSlice({
    name: 'masterData',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchMasterData.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchMasterData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const data:MasterDataType = {};
                action.payload?.forEach(x => {
                    !data[x.type] && (data[x.type] = []);
                    data[x.type].push(x)
                });
                state.data = data;
            })
            .addCase(fetchMasterData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const selectMasterData = (state: RootState) => state.masterData.data;
//export const selectMasterDataByType = (state: RootState, type: string) => state.masterData.data?.[type] || [];
export const selectMasterDataByType = createSelector([(state: RootState) => state.masterData.data, (state: RootState, type: string) => type], (data, type)=> data?.[type] || []);
export const selectMasterDataDictByType = createSelector([selectMasterDataByType], (data) => 
    _.transform(data, (r: Record<string, SystemSettingModel>, obj) => r[obj.value] = obj, {}));
export const selectMasterDataByTypes = (state: RootState, types: string[]) =>
    _.transform(types, (r: MasterDataType, type) => r[type] = state.masterData.data?.[type] ?? [], {});

export default MasterDataSlice.reducer;
