import { Button, Popconfirm, Spin, Table } from "antd";
import React from "react";
import { useDeleteMFAFactorMutation, useGetMFAFactorsQuery } from "../../../redux/rtkquery/UserApi";
import { getFormattedDate } from "../../common/CommonFunctions";
import { CONST_DATE_FORMAT } from "../../common/Constants";
import { MFAFactor, UserModel } from "../../models";
const { Column } = Table;

type ComponentProps = {
    onCancel: () => void,
    user: UserModel
}

const ManageUserMFA: React.FC<ComponentProps> = (props) => {
    const allFactorsState = useGetMFAFactorsQuery({userId: props.user.userName });
    const [triggerDeleteMFA, deleteMFAResult] = useDeleteMFAFactorMutation();

    const onDeleteFactor = async (factorId?: string) => {
        let response = await triggerDeleteMFA({ userId: props.user.userName, factorId: factorId || null });

        if ('data' in response && response.data.success) {
            allFactorsState.refetch();
        }
    }

    return (
        <Spin spinning={allFactorsState.isFetching || deleteMFAResult.isLoading}>
            <Table dataSource={allFactorsState.data} pagination={false}>
                <Column title="Type" className="text-uppercase" dataIndex="factor_type" key="1" />
                <Column title="Registered On" dataIndex="created_at" key="2" 
                    render={(_: any, record: MFAFactor) => (getFormattedDate(record.created_at, CONST_DATE_FORMAT))}
                />
                <Column
                    title="Action"
                    key="3"
                    render={(_: any, record: MFAFactor) => (
                        <Popconfirm
                            title="Remove Factor"
                            description="Are you sure to remove this factor?"
                            onConfirm={() => onDeleteFactor(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button size='small' danger ghost>
                                Remove
                            </Button>
                        </Popconfirm>
                    )}
                />
            </Table>
        </Spin>
    )
};

export { ManageUserMFA };

