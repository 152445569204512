import { CheckOutlined, CopyOutlined, DeleteOutlined, EditOutlined, ExceptionOutlined, FilePdfOutlined, FileTextOutlined, FileWordOutlined, HistoryOutlined, InfoCircleOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Card, Input, List, Modal, notification, Popconfirm, Popover, Skeleton, Space, Tooltip, UploadFile } from 'antd';
import _, { cloneDeep } from 'lodash';
import Markdown from 'markdown-to-jsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';
import { RTKBaseApi } from '../../../redux/rtkquery/BaseApi';
import {
    useDeletePricingAttachmentMutation, useDeletePricingResponseMutation,
    useGetPricingInstructionSelectListQuery,
    useLazyGetPricingRequestQuery,
    usePricingStreamMutation, useSavePricingRequestMutation, useSetRequestStatusMutation
} from '../../../redux/rtkquery/PricingApi';
import { useGetUserSelectListQuery } from '../../../redux/rtkquery/UserApi';
import { getFormattedDate, getShortDisplayName, IsPricingAdmin } from '../../common/CommonFunctions';
import AttachmentTag from '../../common/components/AttachmentTag';
import FileUpload from '../../common/components/FileUpload';
import HTMLContent from '../../common/components/HtmlContent';
import useCopyToClipboard from '../../common/components/useCopyToClipboard';
import useWindowResize from '../../common/components/useWindowResize';
import useWordExport from '../../common/components/useWordExport';
import { CONST_DATETIME_UI_FORMAT } from '../../common/Constants';
import { ActionType, ExportTemplateType, PricingRequestStatus, PricingRequestStatusDetails, RTKQueryTags } from '../../common/Enums';
import { ActiveUserModel, APIResponseModel, PricingDocumentModel, PricingRequestModel, PricingResponseModel } from '../../models';
import { useAuth } from '../auth';
import { FinalizePricing } from './FinalizePricing';
import { PDFDownload } from './PDFDownload';
import { PricingRequestEdit } from './PricingRequestEdit';

const { confirm } = Modal;

const PricingRequest: React.FC = () => {
    const navigate = useNavigate();
    const { user_metadata } = useAuth();
    const { id: requestId } = useParams();
    const dispatch = useAppDispatch();
    const parentRef = useRef<null | HTMLDivElement>(null);
    const listRef = useRef<null | any>(null);
    const footerRef = useRef<null | any>(null);
    const allPricingInstructionSelectList = useGetPricingInstructionSelectListQuery();
    const allUserList = useGetUserSelectListQuery();
    const isPricingAdmin = IsPricingAdmin(user_metadata?.roles);
    const [triggerDeleteAttachment] = useDeletePricingAttachmentMutation();
    const [triggerDeleteResponse] = useDeletePricingResponseMutation();
    const [triggerSaveRequest, saveRequestResult] = useSavePricingRequestMutation();
    const [triggerGetPricingRequest, getPricingRequestResult] = useLazyGetPricingRequestQuery();
    const [triggerPricingGenerator, pricingGeneratorResult] = usePricingStreamMutation();
    const [triggerSetRequestStatus, setRequestStatusResult] = useSetRequestStatusMutation();
    const [query, setQuery] = useState("");
    const [showHistory, setShowHistory] = useState(false);
    const [finalResponse, setFinalResponse] = useState<PricingResponseModel | null>(null);
    const [pricingResponses, setPricingResponses] = useState<PricingResponseModel[]>([]);
    const [pricingDocuments, setPricingDocuments] = useState<PricingDocumentModel[]>([]);
    const [pricingDetailModalOpen, setPricingDetailModalOpen] = useState(false);
    const [showPDFDownloadModal, setShowPDFDownloadModal] = useState(false);
    const [allUserListDict, setAllUserListDict] = useState<Record<number, ActiveUserModel>>({});
    const { isCopied, copyToClipboard } = useCopyToClipboard();
    const { isExporting, exportToWord } = useWordExport();
    const [pricingDetail, setPricingDetail] = useState<PricingRequestModel>({
        pricingRequestId: 0,
        requestName: "New Request",
        pricingInstructionId: 0,
        pricingInstructionName: "",
        statusId: PricingRequestStatus.Drafted,
        createdById: user_metadata?.userId || 0,
        team: [user_metadata?.userId || 0],
        approver: []
    });

    const onEditPricingDetailClick = () => {
        setPricingDetailModalOpen(true);
    }

    const onPricingDetailModalClose = async (_pricingRequest?: PricingRequestModel | null) => {

        if (_pricingRequest) {
            await savePricingDetail(_pricingRequest);
        }

        setPricingDetailModalOpen(false);
    }

    const savePricingDetail = async (_pricingRequest: PricingRequestModel) => {
        let response = await triggerSaveRequest(_pricingRequest);

        if ('data' in response && response.data.success) {
            const _request = cloneDeep(response.data?.data);

            if (_request) {
                if (_pricingRequest.customerId === 0)
                    dispatch(RTKBaseApi.util.invalidateTags([RTKQueryTags.CustomerSelectList]));

                if (_pricingRequest.defaultInstruction)
                    dispatch(RTKBaseApi.util.invalidateTags([RTKQueryTags.PricingInstructionSelectList]));

                if (_pricingRequest.pricingRequestId === 0)
                    navigate(`/pricing/request/${_request.pricingRequestId}`, { replace: true });

                setPricingDetail({ ..._request });
                setChatContainerHeight(100);

                return _request;
            }
        }

        return null;
    }

    const onFinalPricingModalClose = (_pricingDetail?: PricingRequestModel | null) => {

        if (_pricingDetail) {
            setPricingDetail({ ..._pricingDetail });

            let finalResponse = getFinalResponseModel(_pricingDetail);

            setPricingResponses([finalResponse, ...pricingResponses]);
            setChatContainerHeight(100);
        }

        setFinalResponse(null);
    }

    const onQueryChange = (e: any) => {
        setQuery(e.target.value)
    }

    const onQueryEnter = async () => {
        let value = _.trim(query);

        if (!value) {
            notification.info({
                message: "Validation!!",
                description: "Enter Query to process",
                placement: "topRight"
            })
            return;
        }

        let _pricingDetail: PricingRequestModel | null = pricingDetail;

        if (pricingDetail.pricingRequestId === 0) {
            _pricingDetail = await savePricingDetail(pricingDetail);

            if (!_pricingDetail) return;
        }

        let newResponse: PricingResponseModel = {
            pricingResponseId: 0,
            pricingRequestId: _pricingDetail.pricingRequestId,
            pricingInstructionId: _pricingDetail.pricingInstructionId,
            query: query,
            response: "",
            createdById: user_metadata?.userId,
            createdBy: `${user_metadata?.firstName} ${user_metadata?.lastName}`
        }

        // Add in the list to show processing
        pricingResponses.push(newResponse);
        setPricingResponses([...pricingResponses]);

        setQuery("");

        //lastRequestRef.current = 
        triggerPricingGenerator({
            data: {
                query: newResponse.query,
                pricingRequestId: newResponse.pricingRequestId,
                pricingInstructionId: newResponse.pricingInstructionId
            },
            onChunk: onPricingGenerateComplete
        })

        scrollToChat(0);
    }

    const onPricingGenerateComplete = (chunk: string, responseId: number) => {
        // Update the last item
        setPricingResponses((responses) => {
            let lastResponse = responses[responses.length - 1];
            lastResponse.pricingResponseId = responseId;
            lastResponse.response += chunk;
            return [...responses]
        });
    }

    const onFileUploadComplete = (file: UploadFile<APIResponseModel<PricingDocumentModel>>) => {
        let _doc = file.response?.data;
        if (_doc) {
            if (pricingDetail.pricingRequestId === 0) {

                pricingDetail.pricingRequestId = _doc.pricingRequestId;
                pricingDetail.createdBy = _doc.createdBy;
                pricingDetail.createdDate = _doc.createdDate;
                navigate(`/pricing/request/${pricingDetail.pricingRequestId}`, { replace: true });
            }

            pricingDetail.modifiedBy = _doc.createdBy;
            pricingDetail.modifiedDate = _doc.createdDate;

            setPricingDetail(pricingDetail);

            pricingDocuments.push(_doc);
            setPricingDocuments([...pricingDocuments]);
        }
    }

    const onFileRemove = async (file: PricingDocumentModel) => {

        let removedFiles = _.remove(pricingDocuments, x => x.attachmentId === file.attachmentId);
        setPricingDocuments([...pricingDocuments]);

        let response = await triggerDeleteAttachment({
            attachmentId: file.attachmentId,
            pricingRequestId: file.pricingRequestId
        }).unwrap();

        if (!response || !response.success) {
            pricingDocuments.push(removedFiles[0]);
            setPricingDocuments([...pricingDocuments]);
        }
    }

    const onDeleteChat = async (pricingResponseId: number) => {

        let response = await triggerDeleteResponse({
            pricingReponseId: pricingResponseId,
            pricingRequestId: pricingDetail.pricingRequestId
        }).unwrap();

        if (response && response.success) {
            let responses = _.filter(pricingResponses, x => x.pricingResponseId < pricingResponseId)
            setPricingResponses([...responses]);
        }
    }

    const onRequestChanges = () => {
        let comment = _.trim(pricingDetail.comment || '');

        confirm({
            title: 'Request Changes',
            icon: null,
            okText: 'Submit',
            okButtonProps: { type: "primary" },
            cancelText: 'Cancel',
            cancelButtonProps: { disabled: setRequestStatusResult.isLoading },
            width: 600,
            content: <>
                <div className='mb-2'>Suggest changes to implement:</div>
                <Input.TextArea rows={4}
                    defaultValue={comment}
                    className='w-100'
                    placeholder='Enter Comments (Optional)'
                    onChange={input => comment = input.target.value}
                />
            </>,
            async onOk() {
                comment = _.trimEnd(comment, '\n');
                await setRequestStatus(PricingRequestStatus.RequestChanges, comment);
            }
        })
    }

    const onApprovedClick = () => {
        if (isFinalizedMode) {
            setRequestStatus(PricingRequestStatus.Approved);
        }
    }

    const setRequestStatus = async (status: PricingRequestStatus, comment = "", finalizedResponse = "") => {
        let response = await triggerSetRequestStatus({
            pricingRequestId: pricingDetail.pricingRequestId,
            statusId: status,
            comment,
            finalizedResponse
        });

        if ('data' in response && response.data.success && response.data.data) {
            setPricingDetail({ ...response.data.data });

            if (status === PricingRequestStatus.Drafted) {
                setShowHistory(false);
                setPricingResponses(_.filter(pricingResponses, x => !x.isFinalResponse))
            }

            setChatContainerHeight(100);
        }
    }

    const onEditClick = () => {
        if (isFinalizedMode) {
            setRequestStatus(PricingRequestStatus.Drafted);
        }
    }

    const onCreateQuoteClick = () => {
        setFinalResponse({
            pricingResponseId: 0,
            pricingRequestId: pricingDetail.pricingRequestId,
            pricingInstructionId: pricingDetail.pricingInstructionId,
            query: "Generate Final Response",
            response: null
        });
    }

    const getFinalResponseModel = (_pricingRequest: PricingRequestModel) => {
        let finalResponse: PricingResponseModel = {
            pricingResponseId: -1,
            isFinalResponse: true,
            pricingRequestId: _pricingRequest.pricingRequestId,
            pricingInstructionId: _pricingRequest.pricingInstructionId,
            query: "Final Response",
            response: _pricingRequest.finalizedResponse || null,
            createdBy: _pricingRequest.finalizedBy,
            createdDate: _pricingRequest.finalizedDate
        };

        return finalResponse;
    }

    const onFinalResponseInWordExport = async () => {
        exportToWord(ExportTemplateType.PricingFinalPrice, pricingDetail.requestName || "", {
            finalPrice: pricingDetail.finalizedResponse || ""
        });
    }

    const onFinalResponseInPdfExport = async () => {
        setShowPDFDownloadModal(true);
    }

    const onFinalResponseInClipboardExport = async () => {
        if (listRef.current) {
            const responseElement: HTMLDivElement = listRef.current.querySelector('[id=chat_response_-1]');
            if (responseElement) {
                copyToClipboard({ copiedText: responseElement.innerText || "", copiedHTML: responseElement.innerHTML || "" });
            }
        }
    }

    const getUserNames = useCallback((ids: number[] | null) => {

        return _.transform(ids || [], (r: string[], v: number) => {
            allUserListDict[v] && r.push(allUserListDict[v].fullName);
        })

    }, [allUserListDict]);

    const pricingStatusClassName = useMemo(() => {
        let colorClass = "text-gray-700";

        switch (pricingDetail.statusId) {
            case PricingRequestStatus.RequestChanges:
                colorClass = "text-warning";
                break;
            case PricingRequestStatus.Submitted:
                colorClass = "text-primary";
                break;
            case PricingRequestStatus.Approved:
                colorClass = "text-success";
                break;
        }

        return colorClass;

    }, [pricingDetail]);

    const pricingHeader = useMemo(() => (
        <>
            <h2 className='m-0'>
                {pricingDetail.requestName}
                <Button type="link" icon={<EditOutlined rev={0} />} onClick={onEditPricingDetailClick} />
            </h2>

            <div className='flex-list'>
                <div className='flex-item'>
                    <div className='fs-8 text-gray-600'>Price Book</div>
                    <div className='fs-6'>
                        <Button type="link" className='p-0 h-auto'
                            onClick={onEditPricingDetailClick}>
                            {pricingDetail.pricingInstructionName}
                        </Button>
                    </div>
                </div>

                <div className='flex-item'>
                    <div className='fs-8 text-gray-600'>Customer</div>
                    <div className='fs-6'>
                        <Button type="link" className='p-0 h-auto'
                            onClick={onEditPricingDetailClick}>
                            {pricingDetail.customerName || "Add Customer"}
                        </Button>
                    </div>
                </div>

                <div className='flex-item'>
                    <div className='fs-8 text-gray-600'>Team</div>
                    <div className='fs-6'>
                        <Button type="link" className='p-0 h-auto'
                            onClick={onEditPricingDetailClick}>
                            {getUserNames(pricingDetail.team).join(", ") || "Add Team"}
                        </Button>
                    </div>
                </div>

                <div className='flex-item'>
                    <div className='fs-8 text-gray-600'>Approver</div>
                    <div className='fs-6'>
                        <Button type="link" className='p-0 h-auto'
                            onClick={onEditPricingDetailClick}>
                            {getUserNames(pricingDetail.approver).join(", ") || "Add Approver"}
                        </Button>
                    </div>
                </div>

                <div className='flex-item'>
                    <div className='fs-8 text-gray-600'>Status</div>
                    <div className={'fs-6'}>
                        <Space>
                            <span className={pricingStatusClassName}>
                                {PricingRequestStatusDetails[pricingDetail.statusId]?.name}
                            </span>
                            {
                                (pricingDetail.comment?.length ?? 0) > 0 &&
                                <Popover trigger="hover"
                                    placement='left'
                                    content={
                                        <>
                                            <div className='mb-3'>{pricingDetail.comment}</div>
                                            <div className='text-end fs-7 text-gray-700'>
                                                <span>By: </span>
                                                <span className="fw-bold">{pricingDetail.commentBy} </span>
                                                on {getFormattedDate(pricingDetail.commentDate || "", CONST_DATETIME_UI_FORMAT)}
                                            </div>
                                        </>
                                    }

                                    title={PricingRequestStatusDetails[pricingDetail.statusId]?.name}>
                                    <InfoCircleOutlined rev={0} className='cursor-pointer' />
                                </Popover>
                            }
                        </Space>
                    </div>
                </div>
            </div>

            {
                pricingDetail.pricingRequestId > 0 &&
                <div className='flex-list mt-2'>
                    {
                        pricingDetail.createdDate &&
                        <div className='flex-item'>
                            <div className='fs-8 text-gray-400'>
                                Created: {getFormattedDate(pricingDetail.createdDate)}
                            </div>
                        </div>
                    }
                    {
                        pricingDetail.modifiedDate &&
                        <div className='flex-item'>
                            <div className='fs-8 text-gray-400'>
                                Last Modified: {getFormattedDate(pricingDetail.modifiedDate)}
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    ), [pricingDetail]);

    const isFinalizedMode = useMemo(() => {
        return pricingDetail.statusId !== PricingRequestStatus.Drafted
    }, [pricingDetail.statusId]);

    const setChatContainerHeight = (timeout: number = 0) => {
        setTimeout(() => {
            if (listRef.current && footerRef.current) {
                // Using Set syntax to get latest updated state of Price Detail
                // as updated state doesn't reflect in setTimeout method
                setPricingDetail(_pricingDetail => {
                    if (_pricingDetail.statusId === PricingRequestStatus.Drafted) {
                        let footerHeight = _.toNumber(footerRef.current.clientHeight) + 255;
                        listRef.current.style.height = `calc(100vh - ${footerHeight}px)`;
                    }
                    else
                        listRef.current.style.height = 'auto';
                    return _pricingDetail;
                })
            }
        }, timeout);
    }

    const scrollToChat = (chatId: number) => {
        if (listRef.current) {
            setTimeout(() => {
                let chatElement = listRef.current.querySelector(`[id=chat_${chatId}]`);
                chatElement && chatElement.scrollIntoView({ behavior: 'smooth' });
            }, 10);
        }
    }

    const initChatRefs = (chatId?: number) => {
        setTimeout(() => {
            footerRef.current = parentRef.current?.getElementsByClassName("ant-list-footer")[0] || null;
            listRef.current = parentRef.current?.getElementsByClassName("ant-spin-container")[0] || null;

            setChatContainerHeight(0);
            chatId && scrollToChat(chatId);
        }, 100);
    }

    useWindowResize(() => setChatContainerHeight(10));

    useEffect(setChatContainerHeight, [footerRef.current?.clientHeight]);

    useEffect(() => {
        let id = Number(requestId);

        if (!_.isNaN(id) && id > 0) {
            triggerGetPricingRequest(id, false)
                .then(response => {

                    let chatId = 0;

                    if (response && 'data' in response) {
                        const _request = response.data?.data;

                        if (_request) {
                            let _pricingRequest = cloneDeep(_request.pricingRequest);
                            let _pricingResponses = cloneDeep(_request.pricingResponse || []);
                            let _pricingDocuments = cloneDeep(_request.pricingDocument || []);

                            setPricingDetail(_pricingRequest);
                            setPricingDocuments(_pricingDocuments);

                            if (_pricingRequest.statusId !== PricingRequestStatus.Drafted) {
                                let finalResponse = getFinalResponseModel(_pricingRequest);
                                _pricingResponses = [finalResponse, ..._pricingResponses];
                            }

                            setPricingResponses(_pricingResponses);

                            if (_pricingResponses.length) {
                                chatId = _pricingResponses[_pricingResponses.length - 1].pricingResponseId;
                            }
                        }
                    }

                    initChatRefs(chatId);
                });
        }
        else {
            initChatRefs();
        }
    }, []);

    useEffect(() => {
        if (!allPricingInstructionSelectList.isFetching && pricingDetail.pricingInstructionId === 0) {
            let instruction = _.first(allPricingInstructionSelectList.data);

            if (instruction) {
                setPricingDetail((detail) => {
                    detail.pricingInstructionId = instruction?.id || 0;
                    detail.pricingInstructionName = instruction?.instructionName || "";
                    return { ...detail }
                })
            }
        }
    }, [allPricingInstructionSelectList, pricingDetail]);

    useEffect(() => {
        if (!allUserList.isFetching) {
            setAllUserListDict(_.transform(allUserList.data || [], (r: Record<number, ActiveUserModel>, v: ActiveUserModel) => r[v.userId] = v));
        }
    }, [allUserList]);

    return (
        <>
            {
                getPricingRequestResult.isFetching ?
                    <Card className='shadow-sm'
                        headStyle={{ paddingTop: '15px' }}
                        title={<Skeleton active paragraph={{ rows: 1 }} />}>
                        <Skeleton active avatar paragraph={{ rows: 5 }} />
                    </Card> :
                    <div ref={parentRef}>
                        <List
                            className='pricing-view'
                            itemLayout="vertical"
                            size="large"
                            dataSource={pricingResponses}
                            header={pricingHeader}
                            renderItem={(item) => (
                                <List.Item key={item.pricingResponseId}
                                    id={`chat_${item.pricingResponseId}`}
                                    className={isFinalizedMode && !item.isFinalResponse ? "history-chat-item" : (item.isFinalResponse ? "final-chat-item" : "")}
                                    hidden={
                                        (showHistory === false && !item.isFinalResponse && isFinalizedMode)
                                    }>
                                    <List.Item.Meta
                                        avatar={
                                            <span className="text-avatar">{getShortDisplayName(item.createdBy || "User")}</span>
                                        }
                                        title={
                                            <div className='d-flex justify-content-between'>
                                                <div className='fs-7 text-gray-600 fw-normal'>{item.createdBy}</div>
                                                {
                                                    item.isFinalResponse && isFinalizedMode &&
                                                    <Space.Compact size='small'>
                                                        <Tooltip title="Copy to Clipboard">
                                                            <Button icon={<CopyOutlined rev={0} />}
                                                                className={isCopied ? "btn-disabled" : ""}
                                                                onClick={onFinalResponseInClipboardExport}>
                                                                {isCopied ? "Copied" : "Copy"}
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Export to Word">
                                                            <Button icon={<FileWordOutlined rev={0} />}
                                                                loading={isExporting}
                                                                onClick={onFinalResponseInWordExport}>
                                                                Word
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Export to PDF">
                                                            <Button icon={<FilePdfOutlined rev={0} />}
                                                                loading={isExporting}
                                                                onClick={onFinalResponseInPdfExport}>
                                                                PDF
                                                            </Button>
                                                        </Tooltip>
                                                    </Space.Compact>
                                                }
                                                {
                                                    !item.isFinalResponse && !isFinalizedMode &&
                                                    <Popconfirm
                                                        title="Remove the conversation"
                                                        description="Are you sure to remove the conversation starting from this point?"
                                                        onConfirm={() => onDeleteChat(item.pricingResponseId)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                        placement='left'
                                                    >
                                                        <Button className='p-0 h-auto' danger type='link' icon={<DeleteOutlined rev={0} />} />
                                                    </Popconfirm>
                                                }
                                            </div>
                                        }
                                        description={<h4>{item.query}</h4>}
                                    />
                                    <div className='ant-item-content'>
                                        <div className='rfpn-meta-avatar'>
                                            <span className="text-avatar rfpn-avatar" />
                                        </div>
                                        <div className='flex-grow-1'>
                                            {
                                                item.pricingResponseId === 0 && item.response === "" && pricingGeneratorResult.isLoading ?
                                                    <Skeleton active /> :
                                                    (
                                                        <>
                                                            <div className='fs-7 text-gray-600 fw-normal'>RFP Ninja</div>
                                                            {
                                                                item.response === "" || item.response === null ? "NO RESPONSE" :
                                                                    <div className='html-box'>
                                                                        <Markdown id={`chat_response_${item.pricingResponseId}`}>
                                                                            {item.response}
                                                                        </Markdown>
                                                                    </div>
                                                            }
                                                        </>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                            footer={
                                <>
                                    {
                                        !isFinalizedMode &&
                                        <Input.TextArea autoSize
                                            value={query}
                                            onChange={onQueryChange}
                                            placeholder='Enter pricing request or follow-up questions'
                                        />
                                    }

                                    <div className='d-flex'>
                                        <div className='flex-grow-1'>
                                            {
                                                isFinalizedMode &&
                                                <Button ghost type='primary' size='large'
                                                    className='bg-white'
                                                    icon={<HistoryOutlined rev={0} />}
                                                    onClick={() => setShowHistory(!showHistory)}>
                                                    {showHistory ? "Hide History" : "Show History"}
                                                </Button>
                                            }

                                            {
                                                !isFinalizedMode &&
                                                <FileUpload size='large'
                                                    compact={true}
                                                    label='Attach Files'
                                                    maxCount={Number.MAX_SAFE_INTEGER}
                                                    keepUploadedFiles={false}
                                                    uploadUrl='pricing/save_attachment'
                                                    allowedFileTypes={[".pdf", ".jpg", ".jpeg", ".png"]}
                                                    disabled={pricingGeneratorResult.isLoading || allPricingInstructionSelectList.isFetching}
                                                    onUploadComplete={(file) => onFileUploadComplete(file)}
                                                    data={{
                                                        pricingRequestId: pricingDetail.pricingRequestId,
                                                        pricingInstructionId: pricingDetail.pricingInstructionId,
                                                        action: ActionType.Insert
                                                    }}
                                                />
                                            }

                                            {
                                                pricingDocuments.length > 0 &&
                                                _.map(pricingDocuments, x => (
                                                    <AttachmentTag key={x.attachmentId}
                                                        closable={!isFinalizedMode}
                                                        fileName={x.fileDisplayName}
                                                        fileGuid={x.fileName || ""}
                                                        onClose={() => onFileRemove(x)}
                                                    />
                                                ))
                                            }
                                        </div>
                                        <Space>
                                            {
                                                pricingResponses.length > 0 && !isFinalizedMode &&
                                                <Button ghost type='primary' size='large'
                                                    className='bg-white'
                                                    icon={<FileTextOutlined rev={0} />}
                                                    onClick={onCreateQuoteClick}
                                                    disabled={pricingGeneratorResult.isLoading || allPricingInstructionSelectList.isFetching}>
                                                    Create Quote
                                                </Button>
                                            }

                                            {
                                                !isFinalizedMode &&
                                                <Button type='primary' size='large'
                                                    icon={<SendOutlined rev={0} />}
                                                    onClick={onQueryEnter}
                                                    disabled={allPricingInstructionSelectList.isFetching ||
                                                        (saveRequestResult.isLoading && saveRequestResult.originalArgs?.pricingRequestId === 0)
                                                    }
                                                    loading={pricingGeneratorResult.isLoading}>
                                                    Send
                                                </Button>
                                            }

                                            {
                                                pricingDetail.statusId === PricingRequestStatus.Submitted &&
                                                pricingDetail.modifiedById !== user_metadata?.userId &&
                                                (isPricingAdmin || _.some(pricingDetail.approver, x => x === user_metadata?.userId)) &&
                                                <>
                                                    <Button type='default' size='large'
                                                        className='btn-warning-outline'
                                                        icon={<ExceptionOutlined rev={0} />}
                                                        onClick={onRequestChanges}>
                                                        Request Changes
                                                    </Button>

                                                    <Button type='default' size='large'
                                                        className='btn-success-outline'
                                                        icon={<CheckOutlined rev={0} />}
                                                        onClick={onApprovedClick}
                                                        loading={setRequestStatusResult.isLoading && setRequestStatusResult.originalArgs?.statusId === PricingRequestStatus.Approved}>
                                                        Approve
                                                    </Button>
                                                </>
                                            }

                                            {
                                                isFinalizedMode &&
                                                <Button type='primary' size='large'
                                                    icon={<EditOutlined rev={0} />}
                                                    onClick={onEditClick}
                                                    loading={setRequestStatusResult.isLoading && setRequestStatusResult.originalArgs?.statusId === PricingRequestStatus.Drafted}>
                                                    Edit
                                                </Button>
                                            }

                                        </Space>
                                    </div>
                                </>
                            }
                        />
                    </div>
            }

            {
                pricingDetailModalOpen && pricingDetail &&
                <PricingRequestEdit
                    onClose={onPricingDetailModalClose}
                    pricingRequest={pricingDetail}
                    enablePricingInstruction={pricingResponses.length === 0}
                />
            }

            {
                finalResponse &&
                <FinalizePricing onClose={onFinalPricingModalClose} response={finalResponse} />
            }

            {
                showPDFDownloadModal &&
                <PDFDownload show={true}
                    fileName={"Final Response"}
                    PdfInstance={<HTMLContent content={pricingDetail.finalizedResponse ?? ""} hideExpanded={true} />}
                    closeModal={() => setShowPDFDownloadModal(false)}
                />
            }
        </>
    )
}

export { PricingRequest };

