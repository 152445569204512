import { AuthResponse, Session, User, createClient } from '@supabase/supabase-js';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { UserMetaDataModel } from '../../../models';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || ''
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY || ''

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const refreshSession = async () => {
    const session = (await supabase.auth.getSession()).data?.session;

    if (session) {
        const updatedSession = (await supabase.auth.refreshSession(session)).data?.session;
        return updatedSession;
    }

    return session;
}

export const decodeUserInfoFromToken = (token: string) => {
    return jwtDecode<User>(token);
}

export const navigate2FA = async (session?: Session | null) => {

    if (!session)
        session = (await supabase.auth.getSession()).data?.session;

    const result = {
        path: '',
        registered: false,
        totp: false,
        sms: false,
        email: false
    }

    if (session) {
        // const { data: assuranceData } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

        // let is2faRequired = assuranceData ? assuranceData.nextLevel === 'aal2' &&
        //     assuranceData.nextLevel !== assuranceData.currentLevel : false;

        const userInfo = decodeUserInfoFromToken(session.access_token);
        let currentLevel = _.get(userInfo, "aal", "aal1");
        let mfa_count = _.toNumber(_.get(userInfo.user_metadata, "mfa_count", 0));
        let is2faEnabled = _.get(userInfo.user_metadata, "is2fa", false) === true || _.get(userInfo.user_metadata, "c_is2fa", false) === true;

        if (currentLevel === "aal1" && is2faEnabled) {
            result.path = mfa_count > 0 ? '/auth/verify-totp' : '/auth/register-mfa';

            const factorData = await supabase.auth.mfa.listFactors();
            
            _.forEach(factorData.data?.all, x => {
                if (x.status === 'verified') {
                    switch (x.factor_type) {
                        case 'sms':
                            result.sms = true;
                            break;
                        case 'email':
                            result.email = true;
                            break;
                        case 'totp':
                            result.totp = true;
                            break;
                    }
                }
            });

            result.registered = result.totp || result.sms || result.email;
        }
    }

    return result;

}

export const saveProfile = async (profile: UserMetaDataModel) => {
    let response = await supabase.auth.updateUser({
        data: {
            firstName: profile.firstName,
            lastName: profile.lastName,
            phoneNo: profile.phoneNo || ''
        }
    });

    return response;
}

export const getSession = async () => {
    return (await supabase.auth.getSession())?.data.session;
}

export const loginWithToken = async (token: string, type: 'email' | 'recovery' | ''): Promise<AuthResponse> => {
    const response =  await supabase.auth.verifyOtp({token_hash: token, type: type || 'email'});
    return response;
}