import axios from 'axios'
import { supabase } from './supabaseClient'

const API_URL = process.env.REACT_APP_API_URL
const APP_URL = process.env.REACT_APP_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
//export const LOGIN_URL = `${API_URL}/login`
export const LOGIN_URL = `https://api.escuelajs.co/api/v1/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export async function login(email: string, password: string) {
    return await supabase.auth.signInWithPassword({
        email,
        password,
    })
}

// Server should return AuthModel
export function register(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    password_confirmation: string
) {
    return axios.post(REGISTER_URL, {
        email,
        firstName,
        lastName,
        password,
        password_confirmation,
    })
}

export async function requestPassword(email: string) {
    return await supabase.auth.resetPasswordForEmail(email,
        { redirectTo: `${APP_URL}/dashboard` }
    )
}

export async function resetPassword(password: string) {
    return await supabase.auth.updateUser({ password })
}