import axios from 'axios';
import { APIResponseModel } from '../app/models';
import { supabase } from '../app/modules/auth/core/supabaseClient';

const API_URL = process.env.REACT_APP_API_URL;

export function getAccessToken() {
    let token = supabase.auth.getSession().then(response => {
        return response.data?.session?.access_token;
    });
    return token
}

const axiosInstance = (_token: string = "") => {

    if (_token)
        return Promise.resolve(createAxiosInstance(_token))

    let instance = getAccessToken().then(token => {
        return createAxiosInstance(token)
    });
    return instance
}

const createAxiosInstance = (token: string | undefined) => axios.create({
    baseURL: API_URL,
    headers: {
        Accept: 'application/json',
        Authorization: token ? `Bearer ${token}` : ""
    }
})

export function getAPIUrl(actionName: string = '') {
    return `${API_URL}/${actionName}`
}

export function getDataFromAPI<T>(url: string, token: string = ""): Promise<APIResponseModel<T>> {
    return axiosInstance(token).then(instance => instance.get<APIResponseModel<T>>(url))
        .then(response => response.data)
        .catch(error => {
            console.error('Error:', error);
            if(error.response) return error.response;
        });
}

export async function getDataAsyncFromAPI<T>(url: string, token: string = ""): Promise<APIResponseModel<T>> {
    const response = await axiosInstance(token)
        .then(instance => instance.get<APIResponseModel<T>>(url))
        .catch(error => {
            console.error('Error:', error);
            if (error.response) return error.response;
        });
    return response.data
}

export function postDataOnAPI<T>(url: string,
    params: { [key: string]: any } | undefined,
    token: string = ""): Promise<APIResponseModel<T>> {
    return axiosInstance(token).then(instance => instance.post<APIResponseModel<T>>(url, params))
        .then(response => response.data)
        .catch(error => {
            console.error('Error:', error);
            if(error.response) return error.response;
        });
}

export async function postDataAsyncOnAPI<T>(url: string,
    params: { [key: string]: any } | undefined,
    token: string = ""): Promise<APIResponseModel<T>> {
    const response = await axiosInstance(token)
        .then(instance => instance.post<APIResponseModel<T>>(url, params))
        .catch(error => {
            console.error('Error:', error);
            if (error.response) return error.response;
        });
    return response.data
}

export async function deleteDataAsyncOnAPI<T>(url: string, params?: { [key: string]: any } | undefined) {
    let data = params ? { data: params } : undefined;
    const response = await axiosInstance()
    .then(instance => instance.delete<APIResponseModel<T>>(url, data))
    .catch(error => {
        console.error('Error:', error);
        if(error.response) return error.response;
    });
    return response.data
}