import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { notification } from "antd";

export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
        if (isRejectedWithValue(action)) {

            const unauthorized = action.payload?.status === 401,
                forbidden = action.payload?.status === 403,
                fetch_error = action.payload?.status === "FETCH_ERROR",
                payload = action.payload?.data ?? {};

            if (unauthorized) {
                notification.warning({
                    message: 'Authentication failed!!',
                    description: payload.message || "Seems your session has expired. Please log in again.",
                    duration: 30
                })
            }
            else if (forbidden) {
                notification.warning({
                    message: 'Unauthorized access!!',
                    description: payload.message || "You do not have permission to access this resource. If the problem persists, contact our support team.",
                    duration: 30
                })
            }
            else if(fetch_error){
                notification.warning({
                    message: 'Data fetch error!!',
                    description: payload.message || "An error occurred while fetching the data. Please try again later. If the problem persists, contact our support team.",
                    duration: 20
                })
            }
            else {
                notification.error({
                    message: 'Server Error!!',
                    description: payload.message || action.error.message,
                    duration: 15
                })
            }
        }

        return next(action)
    }