import { FilterOutlined } from '@ant-design/icons';
import { App, Button, Card, Col, Divider, Input, List, Popover, Row, Select, Space, Tag, Tooltip } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetDatasetSelectListQuery } from '../../../redux/rtkquery/DatasetApi';
import { useLazyGetQuestionQuery, useListRecommendationMutation, useUpdateRecommendationMutation } from "../../../redux/rtkquery/LibraryApi";
import { ActionType, AddToLibraryStatus } from '../../common/Enums';
import HTMLContent from '../../common/components/HtmlContent';
import { LibraryBasicFilterModel, LibraryFilterModel, LibraryQuestionModel, LibraryQuestionRecommendationModel } from "../../models";
import { AddEditQuestion } from "./AddEditQuestion";
import { filterOptions, getDatasetGroups } from '../../common/CommonFunctions';
import AttachmentTag from '../../common/components/AttachmentTag';

export const LibraryRecommendations: React.FC = () => {
    const [triggerGetList, getListResult] = useListRecommendationMutation();
    const [triggerUpdateQuestion, updateQuestionResult] = useUpdateRecommendationMutation();
    const [triggerGetQuestion, getQuestionResult] = useLazyGetQuestionQuery();
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [filterOpen, setFilterOpen] = useState(false);
    const [questionStartNo, setQuestionStartNo] = useState(1);
    const [selectedQuestion, setSelectedQuestion] = useState<LibraryQuestionRecommendationModel | null>(null);
    const [questions, setQuestions] = useState<LibraryQuestionRecommendationModel[]>([]);
    const [filters, setFilters] = useState<LibraryFilterModel>({
        datasetIds: null,
        keyword: null,
        action: null,
        offset: 0,
        currentPage: 1,
        pageSize: 10,
        totalRecords: 0
    });

    const getFiltersText = () => {
        let count = 0;

        filters.keyword && (count++);
        filters.action && (count++);
        _.size(filters.datasetIds) && (count++);

        return `Filters (${count})`;
    }

    const loadQuestions = (filters: LibraryFilterModel) => {
        triggerGetList(filters).then(response => {
            if ('data' in response) {
                let listingModel = response.data.data;
                setQuestions(listingModel?.libraryQuestionList || []);

                if (filters.totalRecords === 0) {
                    filters.totalRecords = listingModel?.totalRecords || 0;
                    setFilters({ ...filters });
                }
                setQuestionStartNo(filters.offset + 1);
            }
        })
    }

    const onUpdateQuestionStatus = async (question: LibraryQuestionRecommendationModel, status: AddToLibraryStatus) => {
        let _question = cloneDeep(question);
        _question.statusId = status;

        if (status === AddToLibraryStatus.Accepted && question.referenceParentQuestionId) {
            let existing = await fetchAndUpdateQuestion(question, false);
            if (existing) {
                triggerUpdateQuestion(existing);
            }
            return;
        }

        triggerUpdateQuestion(_question);
    }

    const onQuestionEditAndAcceptModalClose = async (question?: LibraryQuestionModel) => {

        if (question) {
            question.statusId = AddToLibraryStatus.Accepted;
            triggerUpdateQuestion(question);
        }

        setSelectedQuestion(null);
    }

    const onPagingChange = (page: number, size: number) => {
        let _filters = { ...filters };
        _filters.currentPage = filters.pageSize !== size ? 1 : page;
        _filters.pageSize = size;
        _filters.offset = (_filters.currentPage - 1) * size;
        loadQuestions(_filters);
        setFilters(_filters);
    }

    const handleFilterOpen = (newOpen: boolean) => {
        setFilterOpen(newOpen);
    }

    const onApplyFilters = (_filters: LibraryBasicFilterModel, closePopup: boolean) => {
        filters.keyword = _filters.keyword;
        filters.datasetIds = _filters.datasetIds;
        filters.action = _filters.action;
        filters.currentPage = 1;
        filters.offset = 0;
        filters.totalRecords = 0;

        setFilters({ ...filters });
        loadQuestions(filters);
        setFilterOpen(!closePopup);
    }

    const onModify = async (question: LibraryQuestionRecommendationModel) => {
        // Update Question case
        if (question.referenceParentQuestionId) {
            let _question = await fetchAndUpdateQuestion(question, true);
            if (_question) {
                setSelectedQuestion(_question);
            }
        }
        else {
            // Add Question case
            setSelectedQuestion(cloneDeep(question));
        }
    }

    const fetchAndUpdateQuestion = async (question: LibraryQuestionRecommendationModel, forceFetch: boolean = false) => {
        let _question: LibraryQuestionRecommendationModel | null = cloneDeep(question);

        // Fetch Question if alternative question was updated instead of main question
        if (question.referenceParentQuestionId && (forceFetch || question.referenceParentQuestionId !== question.referenceLibraryId)) {
            let response = await triggerGetQuestion({ libraryQuestionId: question.referenceParentQuestionId }, false).unwrap();

            if (response.success && response.data && response.data.length) {
                _question = cloneDeep(response.data[0]);

                _question.answer = question.answer;
                _question.datasetId = question.datasetId;
                _question.libraryQuestionId = question.libraryQuestionId;
                _question.referenceLibraryId = question.referenceLibraryId;
                _question.referenceQuestionId = question.referenceQuestionId;
                _question.referenceParentQuestionId = question.referenceParentQuestionId;
                _question.libraryDocument ??= [];

                // Append additional attachments
                _.forEach(question.libraryDocument, q => {
                    _question?.libraryDocument?.push({
                        action: ActionType.Insert,
                        attachmentId: 0,
                        fileName: q.fileName,
                        fileDisplayName: q.fileDisplayName,
                        libraryQuestionId: _question?.libraryQuestionId || 0,
                        includeForAttachment: q.includeForAttachment,
                        includeForTraining: q.includeForTraining
                    })
                })
                
                let updatedAlternative = _.find(_question.alternativeQuestion, x => x.libraryQuestionId === question.referenceLibraryId);

                if (updatedAlternative) {
                    updatedAlternative.question = question.question;
                    updatedAlternative.action = ActionType.Update;
                }
            }
            else
                _question = null;
        }

        if (_question)
            _question.statusId = AddToLibraryStatus.Accepted;

        return _question;
    }

    useEffect(() => {
        loadQuestions(filters);
    }, []);

    useEffect(() => {
        if (updateQuestionResult.requestId && !updateQuestionResult.isLoading) {
            if (updateQuestionResult.data?.success) {
                filters.totalRecords = 0;
                setFilters({ ...filters });
                loadQuestions(filters);
                return;
            }
        }
    }, [updateQuestionResult]);

    return (<>
        <Card title={<h2>Library Edit Requests</h2>}
            extra={
                <Popover
                    content={
                        <LibraryFilter filters={filters} onApply={onApplyFilters} />
                    }
                    trigger="click"
                    open={filterOpen}
                    onOpenChange={handleFilterOpen}
                    placement='bottomRight'
                >
                    <Button type="default" icon={<FilterOutlined rev={0} />}>
                        {
                            getFiltersText()
                        }
                    </Button>
                </Popover>
            }
        >
            <List
                loading={getListResult.isLoading}
                pagination={{
                    align: "center",
                    showSizeChanger: true,
                    current: filters.currentPage,
                    pageSize: filters.pageSize,
                    onChange: onPagingChange,
                    total: filters.totalRecords,
                    hideOnSinglePage: filters.totalRecords > 10 ? false : true
                }}
                dataSource={questions}
                renderItem={(item, index) => (
                    <Row>
                        <Col xs={24} sm={4} md={2} lg={1}>
                            <div className='mb-2'>
                                <span className='d-sm-none fw-bold'>Q. No.</span> {(questionStartNo + index)}
                            </div>
                        </Col>
                        <Col xs={24} sm={20} md={22} lg={23}>
                            <Card className='mb-4'
                                headStyle={{ padding: '0' }}
                                size='small'
                                bordered={true}
                                title={<div className='py-3 px-4 bg-light d-flex'>
                                    <div className='flex-grow-1 d-linebreak'>
                                        {item.question}
                                        {
                                            item.referenceQuestion !== null &&
                                            <div className='fw-normal fs-7 mt-2 ps-3 border-start border-4 text-gray-700'>
                                                <div className='fw-bold text-warning'>Existing Question</div>
                                                {item.referenceQuestion}
                                            </div>
                                        }
                                    </div>
                                </div>
                                }
                            >
                                <Tooltip title="Request Type">
                                    <Tag color={item.referenceLibraryId ? 'green' : 'blue'}>
                                        {
                                            item.referenceLibraryId ? 'UPDATE' : 'ADD'
                                        }
                                    </Tag>
                                </Tooltip>
                                {
                                    !_.isNil(item.datasetId) &&
                                    <Tooltip title="Dataset">
                                        <Tag color="cyan">
                                            {allDatasetListState.data?.find(x => x.datasetId === item.datasetId)?.datasetName}
                                        </Tag>
                                    </Tooltip>
                                }

                                <HTMLContent className='mt-3 mb-3' content={item.answer} lines={2} />

                                {
                                    item.referenceAnswer !== null &&
                                    <div className='fw-normal fs-7 ps-3 border-start border-4 text-gray-700 mb-3'>
                                        <div className='fw-bold text-warning'>Existing Answer</div>
                                        <HTMLContent className='mt-3' content={item.referenceAnswer} lines={2} />
                                    </div>
                                }

                                {
                                    _.size(item.libraryDocument) > 0 &&
                                    _.map(item.libraryDocument, x => (
                                        <AttachmentTag key={x.attachmentId}
                                            style={{ maxWidth: '250px' }}
                                            fileName={x.fileDisplayName}
                                            fileGuid={x.fileName}
                                        />
                                    ))
                                }

                                <Divider className='my-3' />
                                <div className='d-flex align-items-center'>
                                    <div className='fs-7 flex-grow-1 text-gray-700'>
                                        <div>
                                            <span className="fw-bold">Submitted by: </span><span className="fw-bold">{item.createdBy} </span>
                                            on <span className="fw-bold">{dayjs(item.createdDate).format("MMM D, YYYY HH:mm")}</span>
                                        </div>
                                        {
                                            _.trim(item.comment) !== '' &&
                                            <div className='mt-1'><span className="fw-bold">Comment: </span>{item.comment}</div>
                                        }
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <Button size='small' type='primary' ghost
                                            onClick={() => onModify(item)}
                                            loading={
                                                getQuestionResult.isFetching &&
                                                getQuestionResult.originalArgs?.libraryQuestionId === item.referenceParentQuestionId
                                            }
                                            disabled={
                                                updateQuestionResult.isLoading
                                            }
                                        >Modify</Button>
                                        <Button size='small' type='primary'
                                            onClick={() => onUpdateQuestionStatus(item, AddToLibraryStatus.Accepted)}
                                            disabled={
                                                getQuestionResult.isFetching || updateQuestionResult.isLoading
                                            }
                                            loading={
                                                updateQuestionResult.isLoading &&
                                                updateQuestionResult.originalArgs?.statusId === AddToLibraryStatus.Accepted &&
                                                updateQuestionResult.originalArgs?.libraryQuestionId === item.libraryQuestionId
                                            }
                                        >Accept</Button>
                                        <Button size='small' danger ghost
                                            onClick={() => onUpdateQuestionStatus(item, AddToLibraryStatus.Rejected)}
                                            disabled={
                                                getQuestionResult.isFetching || updateQuestionResult.isLoading
                                            }
                                            loading={
                                                updateQuestionResult.isLoading &&
                                                updateQuestionResult.originalArgs?.statusId === AddToLibraryStatus.Rejected &&
                                                updateQuestionResult.originalArgs?.libraryQuestionId === item.libraryQuestionId
                                            }>Reject</Button>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                )}
            />
        </Card>
        {
            selectedQuestion &&
            <AddEditQuestion
                onClose={onQuestionEditAndAcceptModalClose}
                question={selectedQuestion}
                referenceLibraryId={selectedQuestion.referenceLibraryId || 0}
            />
        }
    </>)
}

const LibraryFilter: React.FC<{
    filters: LibraryBasicFilterModel,
    onApply: (filters: LibraryBasicFilterModel, closePopup: boolean) => void
}> = (props) => {
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [filters, setFilters] = useState(_.cloneDeep(props.filters));

    const onKeywordChange = (keyword: string) => {
        setFilters(prev => ({ ...prev, keyword }));
    }

    const onDatasetsChange = (datasetIds: number[]) => {
        setFilters(prev => ({ ...prev, datasetIds }));
    }

    const onActionTypeChange = (action: ActionType | null) => {
        setFilters(prev => ({ ...prev, action: action || null }));
    }

    const onApply = () => {
        let _filters = { ...filters };
        _filters.keyword = _.trim(filters.keyword || '');
        props.onApply(filters, true);
    }

    const onClear = () => {
        filters.keyword = null;
        filters.datasetIds = null;
        filters.action = null;
        setFilters({ ...filters });
        props.onApply(filters, false);
    }

    const datasetGroups = useMemo(() => {
        return getDatasetGroups(allDatasetListState.data || [])
    }, [allDatasetListState]);

    const filterOption = useCallback(filterOptions, [allDatasetListState]);

    return (<>
        <div className='mb-4'>
            <label>Keyword</label>
            <Input
                value={filters.keyword || ''}
                onChange={(e) => onKeywordChange(e.target.value)}
            />
        </div>
        <div className='mb-4'>
            <label>Dataset</label>
            <Select showSearch
                className='w-100'
                mode='multiple'
                maxTagCount={1}
                value={filters.datasetIds || []}
                options={datasetGroups}
                placeholder="Select Dataset"
                loading={allDatasetListState.isFetching}
                onChange={onDatasetsChange}
                filterOption={filterOption}
            />
        </div>

        <div className='mb-4'>
            <label>Request Type</label>
            <Select className='w-100'
                allowClear
                value={filters.action ?? null}
                options={[{ label: "Add", value: ActionType.Insert },
                { label: "Update", value: ActionType.Update }]}
                placeholder="Select Dataset"
                onChange={onActionTypeChange}
            />
        </div>

        <Divider />
        <div className='text-end'>
            <Space>
                <Button onClick={onClear}>Clear</Button>
                <Button type='primary' onClick={onApply}>Apply</Button>
            </Space>
        </div>
    </>)
}