import { Button, Checkbox, Form, Modal, Popover, Select, Skeleton, Space, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import _, { cloneDeep } from "lodash";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { selectMasterDataDictByType } from "../../../redux/MasterDataSlice";
import { assignQuestions, selectProjectSettings, selectQuestion, updateQuestion } from "../../../redux/ProjectsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useAssignQuestionsMutation, useUpdateResponseFormatMutation } from "../../../redux/rtkquery/QuestionApi";
import { QuestionStatus, QuestionUserRole, ResponseFormat } from "../../common/Enums";
import { ActiveUserModel, DataSetSelectModel, QuestionModel } from "../../models";
import { AddEditQuestionCommonControls } from "./AddQuestions";

type QuestionProps = {
  sectionId: number,
  questionId: number,
  userDict: UserDictType,
  userList: ActiveUserModel[],
  datasetList: DataSetSelectModel[],
  team: number[],
  approvers: number[],
  selected: boolean,
  onSelectChange: (questionId: number, selected: boolean) => void,
  extra?: ReactNode
}

type AssignQuestionProps = {
  userList: ActiveUserModel[],
  selectedUserIds?: number[],
  showReplaceExisting?: boolean,
  loading: boolean,
  onSave: (userIds: number[], replaceExisting?: boolean) => void
}

type UserDictType = {
  [type: string]: ActiveUserModel
}

export const QuestionRenderer: React.FC<QuestionProps> = (props) => {
  const question = useAppSelector(state => selectQuestion(state, props.sectionId, props.questionId));
  const responseFormatsDict = useAppSelector(state => selectMasterDataDictByType(state, "ResponseFormat"));
  const projectSettings = useAppSelector(state => selectProjectSettings(state));
  const [triggerAssignQuestions, assignQuestionsResult] = useAssignQuestionsMutation();
  const [isApproverOpen, setIsApproverOpen] = useState(false);
  const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
  const [isEditQuestionOpen, setIsEditQuestionOpen] = useState(false);
  const dispatch = useAppDispatch();

  const hasAccess = useCallback((role?: QuestionUserRole) => {
    return projectSettings?.isProjectOpen && (projectSettings.hasFullAccess ||
      (role ? question.userAccessRole === role :
        (question.userAccessRole === QuestionUserRole.Approver || question.userAccessRole === QuestionUserRole.Assignee)))
  }, [projectSettings, question])

  const onSaveApprovers = (userIds: number[]) => {

    triggerAssignQuestions({
      assignType: 1,
      projectId: question.projectId,
      sectionId: question.sectionId,
      questionIds: [question.questionId],
      userIds,
      replaceExisting: true
    }).then(response => {

      setIsApproverOpen(false);

      if ("error" in response) {
        // Log error
        return;
      }

      dispatch(assignQuestions({
        assignType: 1,
        projectId: question.projectId,
        sectionId: question.sectionId,
        questionIds: [question.questionId],
        userIds,
        replaceExisting: true
      }));
    });
  }

  const onSaveAssignees = (userIds: number[]) => {

    triggerAssignQuestions({
      assignType: 0,
      projectId: question.projectId,
      sectionId: question.sectionId,
      questionIds: [question.questionId],
      userIds,
      replaceExisting: true
    }).then(response => {
      setIsAssigneeOpen(false);

      if ("error" in response) {
        // Log error
        return;
      }

      dispatch(assignQuestions({
        assignType: 0,
        projectId: question.projectId,
        sectionId: question.sectionId,
        questionIds: [question.questionId],
        userIds,
        replaceExisting: true
      }));

    });
  }

  const onCheckChange = (e: CheckboxChangeEvent) => {
    props.onSelectChange(question.questionId, e.target.checked);
  }

  const onEditQuestionClose = () => {
    setIsEditQuestionOpen(false);
  }

  const showUserFullNames = (userIds?: number[]) => {
    return userIds?.length ?
      userIds.map(x => props.userDict[x]?.fullName || "Unknown").join(", ") : "Unassigned"
  }

  const showDatasetName = () => {
    return props.datasetList.find(x => x.datasetId === question.datasetId)?.datasetName || "--"
  }

  const showResponseFormatNames = () => {
    return question.responseFormat?.map(v => responseFormatsDict[v.responseFormatId].name).join(", ") || "--"
  }

  return (
    <>
      <div className="question-block">
        <div className="d-flex align-items-start justify-content-between gap-1">
          <Checkbox checked={props.selected}
            onChange={onCheckChange}
            disabled={!hasAccess() || question.statusId === QuestionStatus.Accepted}
          >
            {question.questionNumber}
          </Checkbox>
          <div className="flex-grow-1 fw-bold d-none d-lg-block d-xl-block">{question.question}</div>
          {
            props.extra &&
            <div>{props.extra}</div>
          }
        </div>
        <div className="fw-bold d-none d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">{question.question}</div>
      </div>
      <div className="assigned-block">
        <Row>
          <Col md={12} lg={5} xl={6}>
            <Space align="start">
              <span className="fw-bold d-inline-block" style={{ width: '65px' }}>Assigned To:</span>
              <span className="fw-100">
                {
                  (hasAccess(QuestionUserRole.Assignee) && question.statusId !== QuestionStatus.Accepted) ?
                    <Popover
                      destroyTooltipOnHide
                      placement="topLeft"
                      open={isAssigneeOpen}
                      content={
                        <AssignQuestionRenderer
                          userList={props.userList.filter(x => props.team.indexOf(x.userId) > -1)}
                          selectedUserIds={question.assignees}
                          onSave={onSaveAssignees}
                          loading={assignQuestionsResult.isLoading}
                        />
                      }
                      title="Assign Question"
                      trigger="click"
                      onOpenChange={setIsAssigneeOpen}
                    >
                      <Button type="link" size="small" className="fs-7 text-primary lh-1 h-auto">
                        {
                          showUserFullNames(question.assignees)
                        }
                      </Button>
                    </Popover> : showUserFullNames(question.assignees)
                }
              </span>
            </Space>
          </Col>
          <Col md={12} lg={7} xl={6}>
            <Space align="start">
              <span className="fw-bold d-inline-block" style={{ width: '92px' }}>Approvers:</span>
              <span className="fw-100">
                {
                  (hasAccess(QuestionUserRole.Approver) && question.statusId !== QuestionStatus.Accepted) ?
                    <Popover
                      destroyTooltipOnHide
                      placement="topRight"
                      open={isApproverOpen}
                      content={
                        <AssignQuestionRenderer
                          userList={props.userList.filter(x => props.approvers.indexOf(x.userId) >= 0)}
                          selectedUserIds={question.approvers}
                          onSave={onSaveApprovers}
                          loading={assignQuestionsResult.isLoading}
                        />
                      }
                      title="Assign Approvers"
                      trigger="click"
                      onOpenChange={setIsApproverOpen}
                    >
                      <Button type="link" size="small" className="fs-7 text-primary lh-1 h-auto">
                        {
                          showUserFullNames(question.approvers)
                        }
                      </Button>
                    </Popover> : showUserFullNames(question.approvers)
                }
              </span>
            </Space>
          </Col>
          <Col md={12} lg={5} xl={6}>
            <Space align="start">
              <span className="fw-bold d-inline-block" style={{ width: '65px' }}>Dataset:</span>
              {
                !props.datasetList.length ? <Skeleton.Input active size="small" /> :

                  (hasAccess() && question.statusId !== QuestionStatus.Accepted) ?
                    <Button type="link" size="small" className="fs-7 text-primary lh-1 h-auto" onClick={() => setIsEditQuestionOpen(true)}>
                      {
                        showDatasetName()
                      }
                    </Button> : <span>{showDatasetName()}</span>
              }
            </Space>
          </Col>
          <Col md={12} lg={7} xl={6}>
            <Space align="start">
              <span className="fw-bold d-inline-block" style={{ width: '92px' }}>Response Format:</span>
              {
                (hasAccess() && question.statusId !== QuestionStatus.Accepted) ?
                  <Button type="link" size="small" className="fs-7 text-primary lh-1 h-auto" onClick={() => setIsEditQuestionOpen(true)}>
                    {
                      showResponseFormatNames()
                    }
                  </Button> : <span>{showResponseFormatNames()}</span>
              }

            </Space>
          </Col>
        </Row>
      </div>
      {
        hasAccess() && isEditQuestionOpen &&
        <QuestionEditRenderer question={question} onClose={onEditQuestionClose} />
      }
    </>
  )
}

export const QuestionEditRenderer: React.FC<{
  question: QuestionModel,
  onClose: () => void
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<QuestionModel>();
  const dispatch = useAppDispatch();
  const [triggerUpdateResponseFormat, updateResponseFormatResult] = useUpdateResponseFormatMutation();

  const onFinish = (values: QuestionModel) => {
    const _model = {
      ...props.question,
      ...values,
    }

    let regenerateResponse: number[] = [];

    _model.responseFormat.forEach((x, i) => {
      if (x.responseFormatId !== ResponseFormat.Custom) {
        x.customOptions = null;
      }

      if (!_.isEqual(x, props.question.responseFormat[i])) {
        regenerateResponse.push(i + 1);
      }
    });

    triggerUpdateResponseFormat({
      question: _model,
      regenerateResponse: regenerateResponse.length ? regenerateResponse : null
    });

  }

  useEffect(() => {
    let responseFormat = cloneDeep(props.question.responseFormat);
    responseFormat.forEach(x => {
      if (_.isNil(x.customOptions) || !x.customOptions.length)
        x.customOptions = [''];
    });
    form.setFieldValue("responseFormat", responseFormat);
  }, [])

  useEffect(() => {
    setLoading(updateResponseFormatResult.isLoading);

    if (updateResponseFormatResult.requestId && !updateResponseFormatResult.isLoading) {

      if (updateResponseFormatResult.data?.success && updateResponseFormatResult.data.data) {
        dispatch(updateQuestion(cloneDeep(updateResponseFormatResult.data.data)));
        props.onClose();
      }
    }

  }, [updateResponseFormatResult]);

  return (

    <Modal title="Edit Dataset & Response format"
      centered
      open={true}
      footer={null}
      maskClosable={false}
      keyboard={false}
      //width={600}
      destroyOnClose={true}
      onCancel={(e) => props.onClose()}
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          name="EditQuestion"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={props.question}
        >
          <Row>
            <AddEditQuestionCommonControls form={form} hideSections={true} singleColumn={true} allowRemoveResponses={false} />
          </Row>
          <Form.Item className="text-end mb-0 mt-5">
            <Space>
              <Button onClick={() => props.onClose()}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save and Regenerate Response
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export const AssignQuestionRenderer: React.FC<AssignQuestionProps> = (props) => {

  const [userIds, setUserIds] = useState(props.selectedUserIds || []);
  const [replaceExisting, setReplaceExisting] = useState(false);

  const onCheckChange = (e: CheckboxChangeEvent) => {
    setReplaceExisting(e.target.checked);
  }

  return (
    <>
      <Select
        allowClear
        maxTagCount={2}
        className="w-100"
        placeholder="Select team member(s)"
        mode="multiple"
        value={userIds}
        fieldNames={{ label: "fullName", value: "userId" }}
        options={props.userList}
        onChange={(value) => setUserIds(value)}
      />
      {props.showReplaceExisting &&
        <Checkbox className="mt-5" checked={replaceExisting} onChange={onCheckChange}>
          Replace existing assigned users?
        </Checkbox>}
      <div className="text-end mt-5">
        <Button type="primary"
          loading={props.loading}
          onClick={() => props.onSave(userIds, replaceExisting)}>
          Save
        </Button>
      </div>
    </>)
}