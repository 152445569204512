import { useState, useEffect } from 'react';

const useWindowResize = (callback: (params: any) => void) => {
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });

            // Call the provided callback function with the new size
            callback && callback({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, [callback]); // Ensure the effect runs again if the callback changes

    return size;
};

export default useWindowResize;