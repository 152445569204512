import { FC, Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { useGetSetupConfigQuery } from '../../redux/rtkquery/UserApi'
import { IsCompanyAdmin, IsPricingAdmin, IsPricingMember, IsProjectOwner, IsSuperAdmin } from '../common/CommonFunctions'
import { Companies } from '../modules/admin/Companies'
import { Users } from '../modules/admin/Users'
import { useAuth } from '../modules/auth'
import { Company } from '../modules/company/Company'
import { CompanySetup } from '../modules/company/CompanySetup'
import { Dashboard } from '../modules/dashboard/Dashboard'
import { Library } from '../modules/library/Library'
import { LibraryRecommendations } from '../modules/library/LibraryRecommendations'
import { AddProject } from '../modules/project/AddProject'
import { Project } from '../modules/project/Project'
import { Projects } from '../modules/project/Projects'
import { Profile } from '../modules/user/Profile'
import { Pricings } from '../modules/pricing/Pricings'
import { Pricing } from '../modules/pricing/Pricing'
import { PricingRequest } from '../modules/pricing/PricingRequest'
import { PricingRequests } from '../modules/pricing/PricingRequests'

const PrivateRoutes = () => {
    const { user_metadata } = useAuth();
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
    const companySetupConfigQuery = useGetSetupConfigQuery();

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {
                    companySetupConfigQuery.data?.isSetupComplete === false &&
                        IsCompanyAdmin(user_metadata?.roles) ?
                        <>
                            <Route path='auth/*' element={<Navigate to='/settings/setup' />} />
                            <Route path="settings/*">
                                <Route path="setup"
                                    element={<SuspensedView><CompanySetup /></SuspensedView>}
                                />
                            </Route>
                        </> :
                        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                }

                {
                    companySetupConfigQuery.data?.isSetupComplete === true &&
                    <>
                        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                        <Route path='dashboard' element={<Dashboard />} />
                        <Route path='profile' element={<Profile />} />
                        <Route path="projects/*">
                            <Route index element={<Navigate to="list" replace />} />
                            {
                                (IsCompanyAdmin(user_metadata?.roles) || IsProjectOwner(user_metadata?.roles)) &&
                                <Route path="add-project"
                                    element={<SuspensedView><AddProject /></SuspensedView>} />
                            }

                            <Route path="list"
                                element={<SuspensedView><Projects /></SuspensedView>} />
                            <Route path=":projectId/*">
                                <Route index element={<Navigate to="questions" replace />} />
                                <Route path=':source'
                                    element={<SuspensedView><Project /></SuspensedView>} />
                            </Route>
                        </Route>

                        {
                            (IsPricingAdmin(user_metadata?.roles) || IsPricingMember(user_metadata?.roles)) &&
                            <Route path='pricing/*'>
                                <Route index element={<Navigate to="list" replace />} />
                                <Route path="list"
                                    element={<SuspensedView><PricingRequests /></SuspensedView>} />
                                <Route path="request/:id"
                                    element={<SuspensedView><PricingRequest /></SuspensedView>} />
                            </Route>
                        }

                        {
                            IsSuperAdmin(user_metadata?.roles) &&
                            <Route path="admin/*">
                                <Route path="companies"
                                    element={<SuspensedView><Companies /></SuspensedView>} />
                                <Route path='users/:companyId'
                                    element={<SuspensedView><Users /></SuspensedView>} />
                            </Route>
                        }

                        {
                            (IsPricingAdmin(user_metadata?.roles) || IsCompanyAdmin(user_metadata?.roles)) &&
                            <Route path="settings/*">
                                {
                                    IsCompanyAdmin(user_metadata?.roles) &&
                                    <>
                                        <Route path="setup">
                                            <Route index element={<Navigate to="about" replace />} />
                                            <Route path=":source"
                                                element={<SuspensedView><Company /></SuspensedView>} />
                                        </Route>

                                        <Route path='users'
                                            element={<SuspensedView><Users /></SuspensedView>} />

                                        <Route path="library/*">
                                            <Route index element={<Navigate to="list" replace />} />
                                            <Route path="list"
                                                element={<SuspensedView><Library /></SuspensedView>} />
                                            <Route path="edit-requests"
                                                element={<SuspensedView><LibraryRecommendations /></SuspensedView>} />
                                        </Route>
                                    </>
                                }
                                {
                                    IsPricingAdmin(user_metadata?.roles) &&
                                    <Route path='price-book/*'>
                                        <Route index element={<Navigate to="list" replace />} />
                                        <Route path="list"
                                            element={<SuspensedView><Pricings /></SuspensedView>} />
                                        <Route path=":id"
                                            element={<SuspensedView><Pricing /></SuspensedView>} />
                                    </Route>
                                }
                            </Route>
                        }
                    </>
                }

                {/* 
                <Route path='crafted/pages/profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage />
                        </SuspensedView>
                    }
                />
                <Route path='crafted/pages/wizards/*'
                    element={
                        <SuspensedView>
                            <WizardsPage />
                        </SuspensedView>
                    }
                />
                <Route path='crafted/widgets/*'
                    element={
                        <SuspensedView>
                            <WidgetsPage />
                        </SuspensedView>
                    }
                />
                <Route path='crafted/account/*'
                    element={
                        <SuspensedView>
                            <AccountPage />
                        </SuspensedView>
                    }
                />
                <Route path='apps/chat/*'
                    element={
                        <SuspensedView>
                            <ChatPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='apps/user-management/*'
                    element={
                        <SuspensedView>
                            <UsersPage />
                        </SuspensedView>
                    }
                /> */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

