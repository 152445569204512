import { App, Button, Checkbox, Form, Input, Select, Space, Spin } from 'antd';
import _, { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppSelector } from '../../../redux/hooks';
import { selectMasterDataByType } from '../../../redux/MasterDataSlice';
import { useLazyGetProfileQuery, useSaveProfileMutation } from '../../../redux/rtkquery/CompanyApi';
import { AntdSelectSearchFn } from '../../common/CommonFunctions';
import { CompanyProfileModel } from '../../models';

type ComponentProps = {
    nextStep?: () => void,
    prevStep?: () => void,
    source: string,
    isIndexing?: boolean
}

const CompanyProfile: React.FC<ComponentProps> = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const formData = React.useRef<CompanyProfileModel | null>(null);
    const countries = useAppSelector(state => selectMasterDataByType(state, "Countries"));
    const [triggerGetProfile] = useLazyGetProfileQuery();
    const [triggerSaveProfile] = useSaveProfileMutation();
    const { notification } = App.useApp();

    useEffect(() => {
        triggerGetProfile().then(response => {
            if (response.data) {
                formData.current = cloneDeep(response.data);
                form.setFieldsValue(formData.current);
                setLoading(false);
            }
        })
    }, []);

    const onReset = () => {
        form.resetFields();
    }

    const onFinishFailed = (errorInfo: any) => {
        notification.error({
            message: "Validation Error",
            description: "Please fix highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = async (values: CompanyProfileModel) => {
        setLoading(true);
        const finalData = _.merge(formData.current, values);
        const response = await triggerSaveProfile(finalData);
        setLoading(false);

        if ('data' in response) {
            props.nextStep?.();
        }
    }

    return (
        <Spin spinning={loading}>
            <Form
                layout='vertical'
                form={form}
                name="CompanyProfile"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                disabled={props.isIndexing}
            >
                <Form.Item name="name" label="Company Name"
                    rules={[{ required: true, message: "'${label}' is required" }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="dbaName"
                    label="Do you have any preferred name (such as abbreviation or d/b/a) in your responses?"
                    rules={[{ type: 'string', max: 200 }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="webUrl" label="Website" className="mb-0"
                    rules={[
                        { type: 'url', warningOnly: true },
                        { type: 'string', min: 6, max: 450 }
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item name="learnFromWebsite" valuePropName="checked">
                    <Checkbox>
                        Use the company website to learn about my company, products and services.
                    </Checkbox>
                </Form.Item>
                <Form.Item name="shortDescription"
                    label="How would you introduce your company to a prospective customer in one sentence?"
                    rules={[{ type: 'string', max: 450 }]}>
                    <Input />
                </Form.Item>

                <Row>
                    <Col lg={6}>
                        <Form.Item name="address1" label="Address 1"
                            rules={[{ type: 'string', max: 450 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="address2" label="Address 2"
                            rules={[{ type: 'string', max: 450 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="city" label="City"
                            rules={[{ type: 'string', max: 90 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="county" label="State"
                            rules={[{ type: 'string', max: 90 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="countryId" label="Country">
                            <Select
                                allowClear
                                showSearch
                                placeholder="Select Country"
                                fieldNames={{ label: "name" }}
                                optionFilterProp="children"
                                options={countries}
                                filterOption={AntdSelectSearchFn}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="zipcode" label="Zip Code"
                            rules={[{ type: 'string', max: 45 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="phoneNo" label="Phone Number"
                            rules={[{ type: 'string', max: 45 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item className="text-end mb-0">
                    {
                        (props.source === "InitialSetup") ?
                            <Button type="primary" htmlType="submit" size="large">
                                Next Step
                            </Button> :
                            <Space>
                                <Button size="large" onClick={onReset}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit" size="large">
                                    Save Changes
                                </Button>
                            </Space>
                    }
                </Form.Item>
            </Form>
        </Spin>
    );
}

export { CompanyProfile };

