import { useEffect } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
        </div>
      </div>

      <div className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover order-1 order-lg-2 bg-white'
        style={{height: '100vh'}}
      >
        <div className='d-flex flex-column flex-center py-5 px-5 px-md-15 w-100'>
          
          <img
            className='mx-auto w-275px w-md-200 w-xl-400px mb-10'
            src={toAbsoluteUrl('/media/illustrations/rfpninja-image1.jpg')}
            alt=''
          />

          <h1 className='text-primary fs-1qx fw-bolder text-center mb-7'>
            Slash your RFP submissions time by 80%
          </h1>

          <div className='text-primary fs-base text-center'>
            RFP Ninja - the ultimate solution for sales teams looking to win more business and respond to RFPs faster. Magically complete RFPs with advanced AI-powered automation and increase your response rate, all while impressing clients with your lightning-fast turnaround. Upgrade your team abilities and start winning more business.
          </div>
        </div>
      </div>
    </div>
  )
}

export { AuthLayout }
