import { Button, Input, Skeleton, Spin } from 'antd'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { refreshSession, supabase } from '../core/supabaseClient'

export function EnrollTOTP(props: {
    onEnrolled: () => void
    onCancelled: (enrollId?: string) => void
    layout?: 'horizonal' | 'vertical'
}) {
    const [factorId, setFactorId] = useState('')
    const [qr, setQR] = useState('') // holds the QR code image SVG
    const [verifyCode, setVerifyCode] = useState('') // contains the code entered by the user
    //const [friendlyName, setFriendlyName] = useState('') // contains the friendly name entered by the user
    const [error, setError] = useState('') // holds an error message
    const componentWillUnmount = useRef(false);
    const [loading, setLoading] = useState(false);
    const [registered, setRegistered] = useState(false);
    let imageSize = props.layout === 'vertical' ? '150px' : '183px';

    const onRegisterClick = async () => {
        setLoading(true);
        setError('');
        const verify = await supabase.auth.mfa.challengeAndVerify({
            factorId,
            code: verifyCode,
        });

        if (verify.error) {
            setError(verify.error.message);
            setLoading(false);
        }
        else {
            setRegistered(true);
            await refreshSession();
            props.onEnrolled?.();
        }
    }

    const onCancelled = () => {
        props.onCancelled?.();
    }

    useEffect(() => {
        (async () => {
            const { data, error } = await supabase.auth.mfa.enroll({
                factorType: 'totp',
            });

            if (error) {
                setError(error.message);
                return;
            }

            setFactorId(data.id);
            setQR(data.totp.qr_code);
        })();

        return () => {
            componentWillUnmount.current = true
        }
    }, []);

    useEffect(() => {
        // on unmount
        return () => {
            if (componentWillUnmount.current && !registered)
                props.onCancelled?.(factorId);
        }
    }, [factorId, registered]);

    return (
        <Spin spinning={loading}>
            {error && <div className="alert alert-danger mb-5">{error}</div>}

            <div className={"d-flex align-items-center " + (props.layout === 'vertical' ? 'flex-column' : '')}>
                <div className="flex-shrink-0">
                    {
                        qr === '' ?
                            <Skeleton.Image className='mb-5' active={true} style={{ height: imageSize, width: imageSize }} /> :
                            <img src={qr} className='mb-5' style={{ height: imageSize, width: imageSize }} />
                    }
                </div>
                <div className="flex-grow-1 ms-3">
                    <h3>Scan the QR Code</h3>
                    <p className='text-muted mb-5'>
                        You can use any authenticator apps such as 1Password, Authy, Microsoft, Google Authenticator or Apple's Keychain to scan the QR Code. This will connect your RFPNinja account with that app and you need to enter TOTP when login to the system.
                    </p>
                    <div className='d-flex'>
                        <div className='flex-grow-1 me-2'>
                            <Input
                                value={verifyCode}
                                onChange={(e) => setVerifyCode(e.target.value.trim())}
                                placeholder='Enter Code from Authenticator App'
                                disabled={qr === ''}
                            />
                        </div>
                        <div>
                            <Button type='primary' className='me-1' onClick={onRegisterClick} disabled={qr === '' || _.trim(verifyCode) === ''}>Register</Button>
                            <Button onClick={onCancelled}>Cancel</Button>
                        </div>
                    </div>


                </div>
            </div>
        </Spin>
    )
}