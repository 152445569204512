import {
  APIResponseModel,
  AnswerAutomationStatModel,
  AnswerAutomationTrendStatModel,
  DashboardStatsFilterModel,
  MyTaskStatsModel,
  ProjectCompletedStatsModel,
  ProjectListItemModel,
  ProjectListStatsModel,
  ProjectStatusStatModel
} from '../../app/models';
import { RTKBaseApi } from './BaseApi';

const DashboardAPI = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    AnswerAutomationStats: builder.mutation<APIResponseModel<AnswerAutomationStatModel[]>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/question_automation_stats',
        method: "POST",
        body: params
      })
    }),
    AnswerAutomationTrendStats: builder.mutation<APIResponseModel<AnswerAutomationTrendStatModel[]>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/question_automation_trend_stats',
        method: "POST",
        body: params
      })
    }),
    ProjectStatusStats: builder.mutation<APIResponseModel<ProjectStatusStatModel[]>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/project_status_stats',
        method: "POST",
        body: params
      })
    }),
    ProjectCompletedStats: builder.mutation<APIResponseModel<ProjectCompletedStatsModel[]>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/project_completed_stats',
        method: "POST",
        body: params
      })
    }),
    MyTaskStats: builder.mutation<APIResponseModel<MyTaskStatsModel>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/my_task_stats',
        method: "POST",
        body: params
      })
    }),
    recentProjects: builder.mutation<APIResponseModel<ProjectListItemModel[]>, {limit: number}>({
      query: (params) => ({
        url: '/dashboard/recent_projects',
        method: "POST",
        body: params
      })
    }),
    projectStats: builder.mutation<APIResponseModel<ProjectListStatsModel[]>, {limit: number, dueDate: string}>({
      query: (params) => ({
        url: '/dashboard/project_list_stats',
        method: "POST",
        body: params
      })
    }),
  })
})

export const {
  useAnswerAutomationStatsMutation,
  useAnswerAutomationTrendStatsMutation,
  useProjectStatusStatsMutation,
  useProjectCompletedStatsMutation,
  useMyTaskStatsMutation,
  useRecentProjectsMutation,
  useProjectStatsMutation
} = DashboardAPI