import { BarsOutlined, EditOutlined, FileExcelOutlined, FileTextOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { CSVBoxButton } from '@csvbox/react';
import { App, Button, Divider, Form, FormInstance, Input, Select, Spin, Switch, Tabs, UploadFile } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { selectMasterDataByType } from "../../../redux/MasterDataSlice";
import { addQuestionFile, addSectionQuestions, selectSectionsSelectList, setParams } from '../../../redux/ProjectsSlice';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useGetDatasetSelectListQuery } from "../../../redux/rtkquery/DatasetApi";
import { useAddQuestionFileMutation, useAddSingleQuestionMutation, useLazyGetQuestionFilesQuery } from "../../../redux/rtkquery/QuestionApi";
import { filterOptions, getDatasetGroups, useStateWithCallback } from '../../common/CommonFunctions';
import { ResponseFormat } from "../../common/Enums";
import FileUpload from '../../common/components/FileUpload';
import { QuestionFileUploadModel, QuestionModel, SectionSelectModel } from "../../models";
import { useAuth } from '../auth';

type ComponentProps = {
    projectId: number,
    sections?: SectionSelectModel[],
    onQuestionAdded?: (navigatePath: string) => void
}

interface PageModel extends QuestionFileUploadModel {
    fileList: UploadFile[]
}

const AddQuestions: React.FC<ComponentProps> = (props) => {
    const sections = useAppSelector(state => selectSectionsSelectList(state, props.projectId));

    return (
        <Tabs
            defaultActiveKey="SingleQuestion"
            items={[{
                label: <span><EditOutlined rev={0} /> Single Question</span>,
                key: 'SingleQuestion',
                children: <SingleQuestion {...props} sections={sections} />
            },
            {
                label: <span><BarsOutlined rev={0} /> Multiple Questions</span>,
                key: 'MultiQuestions',
                children: <MultiQuestions {...props} sections={sections} />
            },
            {
                label: <span><FileTextOutlined rev={0} /> Import Document</span>,
                key: 'QuestionsFromFile',
                children: <QuestionsFromFile {...props} sections={sections} />
            },
            {
                label: <span><FileExcelOutlined rev={0} /> Import Excel</span>,
                key: 'QuestionsFromExcel',
                children: <QuestionsFromExcel {...props} sections={sections} />
            }
            ]}
        />)
}

export { AddQuestions };

const SingleQuestion: React.FC<ComponentProps> = (props) => {
    const [form] = Form.useForm<QuestionModel>();
    const [loading, setLoading] = useState(false);
    const { notification } = App.useApp();
    const dispatch = useAppDispatch();
    const [section, setSection] = useState<SectionSelectModel | null>(null);
    const [triggerAddSingleQuestion, addSingleQuestionResult] = useAddSingleQuestionMutation();

    const initialQuestion: any = {
        datasetId: -1,
        responseFormat: [{ responseFormatId: ResponseFormat.Short, customOptions: [''] }]
    }

    const onFinishFailed = (errorInfo: any) => {
        notification.error({
            message: "Validation Error",
            description: "Please fix highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = (values: QuestionModel) => {
        const model = cloneDeep(values);
        model.projectId = props.projectId;
        model.sectionName = section?.sectionName;

        model.responseFormat.forEach(x => {
            if (x.responseFormatId !== ResponseFormat.Custom) {
                x.customOptions = null;
            }
        });

        triggerAddSingleQuestion(model);
    }

    const onSectionSelect = (value?: SectionSelectModel | null) => {
        setSection(value || null);
        form.setFieldValue("sectionId", value?.sectionId);
    }

    useEffect(() => {
        setLoading(addSingleQuestionResult.isLoading);

        if (addSingleQuestionResult.requestId && !addSingleQuestionResult.isLoading) {

            if (addSingleQuestionResult.data?.data) {
                let question = cloneDeep(addSingleQuestionResult.data.data);
                dispatch(addSectionQuestions({ projectId: props.projectId, questions: [question] }));
                form.resetFields();

                props.onQuestionAdded?.('../questions');
            }
        }

    }, [addSingleQuestionResult])

    return (
        <Spin spinning={loading}>
            <p className='px-3 py-2 border-start border-5 border-secondary bg-gray-100'>
                Add one question in to the project.
            </p>
            <Form
                layout="vertical"
                form={form}
                name="SingleQuestion"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={initialQuestion}
            >
                <Form.Item name="question" label="Question"
                    rules={[{ required: true, message: "'${label}' is required" }]}>
                    <Input.TextArea autoSize
                        placeholder="Enter a single question. (If you have multiple questions, use one of the other options)"
                        style={{ minHeight: '55px' }}
                    />
                </Form.Item>
                <Row>
                    <AddEditQuestionCommonControls form={form}
                        onSelect={onSectionSelect}
                        sections={props.sections}
                    />
                </Row>
                <Divider />
                <Form.Item className="text-end mb-0">
                    <Button type="primary" htmlType="submit">
                        Add Question
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    )
}

const MultiQuestions: React.FC<ComponentProps> = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { notification } = App.useApp();
    const dispatch = useAppDispatch();
    const [section, setSection] = useState<SectionSelectModel | null>(null);
    const [triggerCreateQuestionQueue, createQuestionQueueResult] = useAddQuestionFileMutation();

    const initialQuestion: any = {
        datasetId: -1,
        responseFormat: [{ responseFormatId: ResponseFormat.Short, customOptions: [''] }]
    }

    const onFinishFailed = (errorInfo: any) => {
        notification.error({
            message: "Validation Error",
            description: "Please fix highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = (values: QuestionFileUploadModel) => {
        const model = cloneDeep(values);
        model.projectId = props.projectId;
        model.sectionName = section?.sectionName;
        model.fileDisplayName = `Questions from Text ${dayjs().format("HHmm_YYYYMMDD")}.txt`;

        model.responseFormat.forEach(x => {
            if (x.responseFormatId !== ResponseFormat.Custom) {
                x.customOptions = null;
            }
        });

        triggerCreateQuestionQueue(model);
    }

    useEffect(() => {
        setLoading(createQuestionQueueResult.isLoading);

        if (createQuestionQueueResult.requestId && !createQuestionQueueResult.isLoading) {

            if (createQuestionQueueResult.data?.data) {
                let questionFile = cloneDeep(createQuestionQueueResult.data.data);
                dispatch(addQuestionFile(questionFile));
                form.resetFields();

                props.onQuestionAdded?.('../review-questions');
            }
        }

    }, [createQuestionQueueResult])

    const onSectionSelect = (value?: SectionSelectModel | null) => {
        setSection(value || null);
        form.setFieldValue("sectionId", value?.sectionId);
    }

    return (
        <Spin spinning={loading}>
            <p className='px-3 py-2 border-start border-5 border-secondary bg-gray-100'>
                Add multiple questions at once.  You can also paste text, for example, questions from a webpage, or an Excel file.
            </p>
            <Form
                layout="vertical"
                form={form}
                name="MultiQuestions"
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={initialQuestion}
            >
                <Form.Item name="questionText" label="Questions"
                    rules={[{ required: true, message: "'${label}' are required" }]}>
                    <Input.TextArea rows={8}
                        placeholder="Type or paste in topics.&#10;Create each topic on a new line.&#10;You may also create the topics as instructions, such as &quot;Create an executive summary for the RFP and describe and list the benefits of products a, b, and c.&quot;" />
                </Form.Item>
                <Row>
                    <AddEditQuestionCommonControls form={form} onSelect={onSectionSelect} sections={props.sections} />
                </Row>
                <Divider />
                <Form.Item className="text-end mb-0">
                    <Button type="primary" htmlType="submit">
                        Identify Questions
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    )
}

const QuestionsFromFile: React.FC<ComponentProps> = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { notification } = App.useApp();
    const dispatch = useAppDispatch();
    const [section, setSection] = useState<SectionSelectModel | null>(null);
    const [triggerCreateQuestionQueue, createQuestionQueueResult] = useAddQuestionFileMutation();

    const initialQuestion: any = {
        datasetId: -1,
        responseFormat: [{ responseFormatId: ResponseFormat.Short, customOptions: [''] }]
    }

    const onSectionSelect = (value?: SectionSelectModel | null) => {
        setSection(value || null);
        form.setFieldValue("sectionId", value?.sectionId);
    }

    const onFinishFailed = (errorInfo: any) => {
        notification.error({
            message: "Validation Error",
            description: "Please fix highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = (values: PageModel) => {

        const file = values.fileList.find(x => x.status === "done");

        if (!file) {
            notification.error({
                message: "Validation Error",
                description: "Please upload a valid questions file",
                placement: "topRight"
            });
            return;
        }

        const model = cloneDeep(values);
        model.projectId = props.projectId;
        model.sectionName = section?.sectionName;
        model.fileName = file.fileName;
        model.fileDisplayName = file.name;
        _.unset(model, "fileList");

        model.responseFormat.forEach(x => {
            if (x.responseFormatId !== ResponseFormat.Custom) {
                x.customOptions = null;
            }
        });

        triggerCreateQuestionQueue(model);
    }

    useEffect(() => {
        setLoading(createQuestionQueueResult.isLoading);

        if (createQuestionQueueResult.requestId && !createQuestionQueueResult.isLoading) {

            if (createQuestionQueueResult.data?.data) {
                let questionFile = cloneDeep(createQuestionQueueResult.data.data);
                dispatch(addQuestionFile(questionFile));
                form.resetFields();

                props.onQuestionAdded?.('../review-questions');
            }
        }

    }, [createQuestionQueueResult]);

    return (
        <Spin spinning={loading}>
            <p className='px-3 py-2 border-start border-5 border-secondary bg-gray-100'>
                Upload a Word or PDF document, and let the system extract the questions.
            </p>
            <Form
                layout="vertical"
                form={form}
                name="QuestionsFromFile"
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={initialQuestion}
            >
                <FileUpload label='Upload Question File' form={form}
                    multiple={false} maxCount={1} showDownloadIcon={false}
                    rules={[{ required: true, message: "'Question File is required" }]} 
                />

                <Row>
                    <AddEditQuestionCommonControls form={form} onSelect={onSectionSelect} sections={props.sections} />
                </Row>
                <Divider />
                <Form.Item className="text-end mb-0">
                    <Button type="primary" htmlType="submit">
                        Upload &amp; Identify Questions
                    </Button>
                </Form.Item>

            </Form>
        </Spin>
    )
}

const QuestionsFromExcel: React.FC<ComponentProps> = (props) => {
    const [form] = Form.useForm();
    const { user_metadata } = useAuth();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [exportInOriginalFormat, setExportInOriginalFormat] = useState(false);
    const { notification } = App.useApp();
    const [section, setSection] = useState<SectionSelectModel | null>(null);
    const formValues = Form.useWatch<QuestionFileUploadModel>([], form);
    const [formData, setFormData] = useStateWithCallback<QuestionFileUploadModel | null>(null);
    const [submittable, setSubmittable] = useState(false);
    const [fileUploadOpen, setFileUploadOpen] = useState(false);
    const [dynamicColumns, setDynamicColumns] = useState<any[]>([]);
    const [triggerGetQuestionFiles] = useLazyGetQuestionFilesQuery();

    const initialQuestion: any = {
        datasetId: -1,
        exportInOriginalFormat: false,
        responseFormat: [{ responseFormatId: ResponseFormat.Short, customOptions: [''] }]
    }

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittable(true);
            },
            () => {
                setSubmittable(false);
            }
        );
    }, [formValues]);

    const onFinish = (values: QuestionFileUploadModel) => {
        const formData = cloneDeep(values);
        formData.projectId = props.projectId;

        if (!formData.sectionId)
            formData.sectionName = section?.sectionName;

        formData.responseFormat.forEach(x => {
            if (x.responseFormatId !== ResponseFormat.Custom) {
                _.unset(x, 'customOptions');
            }
        });

        setColumns(formData.responseFormat.length, formData.exportInOriginalFormat || false);
        setFormData(formData, () => setFileUploadOpen(true));
    }

    const onSectionSelect = (value?: SectionSelectModel | null) => {
        setSection(value || null);
        form.setFieldValue("sectionId", value?.sectionId);
    }

    const setColumns = (count: number, exportInOriginalFormat: boolean) => {
        let columns = [];
        if (exportInOriginalFormat) {
            let initialColumnsCount = 2;
            let i = 1;
            while (count >= i) {
                columns.push({
                    "column_name": `answer${i}`,
                    "display_label": count > 1 ? `Answer ${i}` : 'Answer',
                    "matching_keywords": count > 1 ? `answer${i}, answer ${i}, Answer${i}, Answer ${i}` : '',
                    "position": (initialColumnsCount + i)
                });
                i++;
            }
        }

        setDynamicColumns(columns);
    }

    const onExportFormatChange = (checked: boolean) => {
        setExportInOriginalFormat(checked);
    }

    const onImportComplete = async (result: boolean, importData: any) => {

        if (result) {
            setLoading(true);

            // Wait for few seconds to complete process on server
            setTimeout(() => {
                triggerGetQuestionFiles({ projectId: props.projectId })
                    .then(response => {

                        setLoading(false);

                        if (response.data?.data?.length) {
                            let importedFile = _.find(response.data.data, x => x.csvboxImportId === importData.import_id);

                            if (importedFile) {
                                let fileUploadModel = cloneDeep(importedFile) as QuestionFileUploadModel;
                                fileUploadModel.exportInOriginalFormat = formData?.exportInOriginalFormat;
                                fileUploadModel.fileDisplayName = fileUploadModel.fileDisplayName || importData.original_filename;

                                dispatch(addQuestionFile(fileUploadModel));
                                dispatch(setParams({
                                    params: { revewFileImportId: importedFile.importId }
                                }));
                                form.resetFields();
                            }

                            notification.success({
                                message: "Imported",
                                description: `Total ${importData.row_success} row(s) imported. Please review and add to project.`,
                                placement: "topRight"
                            });

                            props.onQuestionAdded?.('../review-questions');
                        }
                    })
            }, 2500);

        } else {
            notification.error({
                message: "Import failed",
                description: 'Unable to import the file.',
                placement: "topRight"
            });
        }
    }

    return (
        <Spin spinning={loading}>
            <p className='px-3 py-2 border-start border-5 border-secondary bg-gray-100'>
                Upload an Excel file with questions. Note: some formats may not be supported.
            </p>
            <Form
                layout="vertical"
                form={form}
                name="MultiQuestions"
                autoComplete="off"
                onFinish={onFinish}
                initialValues={initialQuestion}
            >
                <Row>
                    <Col sm={6}>
                        <Form.Item name="exportInOriginalFormat" valuePropName='checked'
                            label="Do these responses need to be exported in the original format?">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={onExportFormatChange} />
                        </Form.Item>
                    </Col>
                    <AddEditQuestionCommonControls form={form} onSelect={onSectionSelect}
                        sections={props.sections}
                        newSectionRequired={exportInOriginalFormat}
                    />
                </Row>
            </Form>
            <Divider />
            <div className="text-end mb-0">
                <Button type="primary"
                    hidden={fileUploadOpen}
                    disabled={!submittable}
                    onClick={form.submit}>
                    Upload &amp; Identify Questions
                </Button>
                {
                    submittable && fileUploadOpen &&
                    <CSVBoxButton
                        licenseKey={process.env.REACT_APP_CSVBOX_PROJECT_QUESTION_LICENSE_KEY || ''}
                        dynamicColumns={dynamicColumns}
                        user={{
                            user_id: user_metadata?.userId,
                            company_id: user_metadata?.companyId,
                            import_info: JSON.stringify(formData)
                        }}
                        onSubmit={(metadata) => console.log(metadata)}
                        onClose={() => setFileUploadOpen(false)}
                        onImport={onImportComplete}
                        render={(launch, isLoading) => {
                            if (!isLoading)
                                launch();
                            return <Button type="primary"
                                htmlType="submit"
                                loading={isLoading}>
                                Upload &amp; Identify Questions
                            </Button>
                        }}
                    />
                }
            </div>

        </Spin>
    )
}

const AddEditQuestionCommonControls: React.FC<{
    value?: string | null,
    form: FormInstance,
    hideSections?: boolean,
    sections?: SectionSelectModel[],
    onSelect?: (value?: SectionSelectModel | null) => void,
    singleColumn?: boolean,
    newSectionRequired?: boolean,
    allowRemoveResponses?: boolean
}> = (props) => {

    const responseFormats = useAppSelector(state => selectMasterDataByType(state, "ResponseFormat"));
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [sectionList, setSectionList] = useState<SectionSelectModel[]>(cloneDeep(props.sections || []));
    let timeout: ReturnType<typeof setTimeout> | null;

    const fetchSection = (value: string, callback: Function) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        const filterSection = () => {
            let list = props.newSectionRequired ? [] : cloneDeep(props.sections?.filter(x =>
                _.trim(x.sectionName).toLowerCase().includes(_.trim(value).toLowerCase())) || []);

            if (!list.length)
                list.push({ sectionId: 0, sectionName: _.trim(value), exportInOriginalFormat: false });
            callback(list);
        }

        if (_.isNumber(value))
            return;

        if (value)
            timeout = setTimeout(filterSection, 200);
        else
            callback(props.newSectionRequired ? [] : cloneDeep(props.sections));
    }

    const onSectionSearch = (newValue: string) => {
        fetchSection(newValue, setSectionList);
    }

    const onSelect = (value?: string, option?: any) => {
        props.onSelect?.(option ? {
            sectionId: Number(value),
            sectionName: option.children.toString(),
            exportInOriginalFormat: false
        } : null);

        if (!option) {
            fetchSection("", setSectionList);
        }
    }

    useEffect(() => {
        if (props.newSectionRequired) {
            props.form.setFieldValue("sectionId", null);
            onSectionSearch("");
        }
    }, [props.newSectionRequired]);

    const datasetGroups = useMemo(() => {
        return getDatasetGroups(allDatasetListState.data || [])
    }, [allDatasetListState]);

    const filterOption = useCallback(filterOptions, [allDatasetListState]);

    return (
        <>
            <Col sm={props.singleColumn ? 12 : 6}>
                <Form.Item name="datasetId" label="Dataset"
                    rules={[{ required: true, message: "'${label}' is required" }]}>
                    <Select
                        showSearch
                        options={datasetGroups}
                        placeholder="Select Dataset"
                        loading={allDatasetListState.isFetching}
                        filterOption={filterOption}
                    />
                </Form.Item>
            </Col>
            {
                !props.hideSections === true &&
                <Col sm={props.singleColumn ? 12 : 6}>
                    <Form.Item name="sectionId"
                        label={props.newSectionRequired ? <span>Section <span className='fs-7 text-muted d-block'>A new section is required when responses must be exported in the original file. You will not be able to change this later and no additional questions can be uploaded into this section.</span></span> : "Section"}
                        rules={[{ required: true, message: "'Section' is required" }]}>
                        <Select
                            showSearch
                            allowClear
                            placeholder="Select Section from the list or type name to add new"
                            filterOption={false}
                            onSearch={onSectionSearch}
                            //onChange={onSectionSearch}
                            notFoundContent={null}
                            value={props.value}
                            onSelect={onSelect}
                            onClear={onSelect}
                        >
                            {
                                _.map(sectionList, x => (
                                    <Select.Option
                                        key={x.sectionId}
                                        value={x.sectionId}
                                        disabled={x.exportInOriginalFormat}>
                                        {x.sectionName}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Col>
            }

            <Col sm={props.singleColumn ? 12 : 6}>
                <Form.List name="responseFormat">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <div className='response-formats' key={field.key}>
                                    <div className="d-flex" key={field.key}>
                                        <Form.Item name={[field.name, 'responseFormatId']}
                                            key={field.key}
                                            className="flex-grow-1 mb-0"
                                            label={"Response Format " + (index + 1)}
                                            rules={[{ required: true, message: "'Response format' is required" }]}>
                                            <Select
                                                options={responseFormats}
                                                fieldNames={{ label: "name" }}
                                                placeholder="Select Format"
                                            />
                                        </Form.Item>
                                        {
                                            (_.isNil(props.allowRemoveResponses) || props.allowRemoveResponses) ?
                                                (index === 0 ?
                                                    <Button onClick={() => add({ customOptions: [''] })}
                                                        icon={<PlusCircleOutlined rev={undefined} />}
                                                        style={{ marginTop: '31px' }}
                                                    /> :
                                                    <Button onClick={() => remove(field.name)}
                                                        icon={<MinusCircleOutlined rev={undefined} />}
                                                        style={{ marginTop: '31px' }}
                                                    />) : null
                                        }
                                    </div>

                                    <Form.Item className="mb-0 mh-0" shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.responseFormat[index]?.responseFormatId !== currentValues.responseFormat[index]?.responseFormatId
                                    }>
                                        {({ getFieldValue }) => (
                                            getFieldValue('responseFormat')[index]?.responseFormatId === ResponseFormat.Custom ? (
                                                <div className="ps-5 border-start border-5 border-secondary mt-3">
                                                    <div className="ant-col ant-form-item-label">
                                                        <label title="Custom Options">Custom Options</label>
                                                    </div>
                                                    <Form.List name={[field.name, 'customOptions']}>
                                                        {(fields, { add, remove }) => (
                                                            <>
                                                                {fields.map((field, i) => (
                                                                    <div className="d-flex" key={field.key}>
                                                                        <Form.Item {...field}
                                                                            key={field.key}
                                                                            className="flex-grow-1 mb-1"
                                                                            rules={[{ required: true, message: "'Custom answer' is required" }]}
                                                                            tooltip="Add your cusom answer option">
                                                                            <Input placeholder="Enter option" />
                                                                        </Form.Item>
                                                                        {
                                                                            (i == 0 ?
                                                                                <Button onClick={() => add()}
                                                                                    icon={<PlusCircleOutlined rev={undefined} />}
                                                                                    disabled={fields.length >= 5} /> :
                                                                                <Button onClick={() => remove(field.name)}
                                                                                    icon={<MinusCircleOutlined rev={undefined} />} />)
                                                                        }
                                                                    </div>
                                                                ))}
                                                            </>
                                                        )}
                                                    </Form.List></div>) : null
                                        )}
                                    </Form.Item>
                                </div>
                            ))}
                        </>
                    )}
                </Form.List>
            </Col>
        </>
    )
}

export { AddEditQuestionCommonControls };

