import { downloadBlobSilently } from '../../app/common/CommonFunctions';
import { APIResponseModel } from '../../app/models';
import { RTKBaseApi } from './BaseApi';

type MergeParams = { 
  projectId: number, 
  oldSectionId: number, 
  newSectionId: number 
}

type UpdateParams = { 
  projectId: number, 
  sectionId: number, 
  sectionName: string,
  exportInOriginalFormat: boolean
}

const SectionApi = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    reorderSections: builder.mutation<APIResponseModel<void>, { projectId: number, sectionIds: number[] }>({
      query: (params) => ({
        url: '/section/reorder',
        method: "POST",
        body: params
      })
    }),
    mergeSections: builder.mutation<APIResponseModel<MergeParams>, MergeParams>({
      query: (params) => ({
        url: '/section/merge',
        method: "POST",
        body: params
      }),
      transformResponse: (response: APIResponseModel<MergeParams>, meta, args) => {
        response.data = args;
        return response;
      }
    }),
    deleteSection: builder.mutation<APIResponseModel<void>, { projectId: number, sectionId: number }>({
      query: (params) => ({
        url: '/section/delete',
        method: "DELETE",
        body: params
      })
    }),
    updateSection: builder.mutation<APIResponseModel<void>, UpdateParams>({
      query: (params) => ({
        url: '/section/update',
        method: "POST",
        body: params
      })
    }),
    exportSectionInExcel: builder.mutation({
      queryFn: async ({ projectId, sectionId, exportInDefaultFormat, fileName }, api, extraOptions, baseQuery) => {
        const result: any = await baseQuery({
          url: '/section/export',
          method: "POST",
          body: { projectId, sectionId, exportInDefaultFormat },
          responseHandler: ((response) => response.status === 200 ? response.blob() : Promise.reject())
        });

        if (result.data) {
          downloadBlobSilently(result.data, fileName);
          return { data: true };
        }
        
        console.error(result);
        return { data: false };
      }
    })
  }),
})

export const { 
  useReorderSectionsMutation,
  useMergeSectionsMutation,
  useDeleteSectionMutation,
  useUpdateSectionMutation,
  useExportSectionInExcelMutation
} = SectionApi