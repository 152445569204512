import { App, Button, Result } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { useCompleteSetupMutation } from '../../../redux/rtkquery/CompanyApi';

const CompleteSetup: React.FC = () => {
    const [triggerCompleteSetup, setupResult] = useCompleteSetupMutation();
    const { notification } = App.useApp();

    const onCompleteSetup = ()=> {
        triggerCompleteSetup();
    }

    useEffect(()=> {
        if(setupResult.requestId && !setupResult.isLoading){
            if(setupResult.data?.success){
                window.location.href = '/dashboard';
                return;
            }

            if(setupResult.isError || !setupResult.data?.success){
                notification.error({
                    message: "Save Error",
                    description: _.get(setupResult.error, "error"),
                    placement: "topRight"
                });
            }
        }
    }, [setupResult]);
    
    return <Result
        icon={<KTSVG path="/media/icons/duotune/general/gen043.svg" className="svg-icon-5hx svg-icon-primary" />}
        title="Great, you have completed setup..!!"
        extra={<Button type="primary" onClick={onCompleteSetup} loading={setupResult.isLoading}>Generate Indexes</Button>}
    />
};

export { CompleteSetup };

