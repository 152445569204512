import { Card, Empty, Input, Select, Space, Switch } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectFilters, selectProjectSettings, selectSections, selectTeamAndApprovers, setFilters, setParams } from "../../../redux/ProjectsSlice";
import { useGetUserSelectListQuery } from "../../../redux/rtkquery/UserApi";
import { QuestionStatusDetails } from "../../common/Enums";
import { SortableTabs } from "../../common/components/SortableTabs";
import { ActiveUserModel } from "../../models";
import { QuestionTabContent } from "./QuestionTabContent";
import { SectionRenderer } from './SectionRenderer';
import { Col, Row } from "react-bootstrap";

const { Search } = Input;

type ComponentProps = {
  projectId: number
}

const QuestionsTab: React.FC<ComponentProps> = (props) => {
  const dispatch = useAppDispatch();
  const sections = useAppSelector(state => selectSections(state));
  const [selectedSectionId, setSelectedSectionId] = useState<string | undefined>();

  const onSectionChange = (sectionId: string) => {
    setSelectedSectionId(sectionId);

    dispatch(setParams({
      params: { selectedSectionId: Number(sectionId) }
    }))
  }

  const onSectionClick = (sectionId: string) => {
    if(!selectedSectionId)
      onSectionChange(sectionId);
  }

  useEffect(() => {
    if(sections.length && !selectedSectionId)
      onSectionChange(sections[0].sectionId.toString());
  }, [sections, selectedSectionId])

  return (
    <>
      <QuestionFiltersRenderer projectId={props.projectId} />
      <SortableTabs
        id="section-tabs"
        tabPosition="left"
        activeKey={selectedSectionId}
        useDragHandle
        pressDelay={200}
        shouldCancelStart={() => sections.length === 1}
        onChange={onSectionChange}
        onTabClick={onSectionClick}
        tabBarExtraContent={{
          left: <>
            <div className="section-head"><h2>Sections</h2></div>
            {
              sections.length === 0 &&
              <Empty description="No section added" />
            }
          </>
        }}
        tabPanes={
          _.map(sections, (section, i) => ({
            key: section.sectionId,
            tabKey: section.sectionId.toString(),
            tab: <SectionRenderer section={section}
              selected={section.sectionId.toString() === selectedSectionId}
            />,
            children: <QuestionTabContent section={section} />
          }))
        }
      />
    </>
  )
}

export { QuestionsTab }

const QuestionFiltersRenderer: React.FC<{ projectId: number }> = (props) => {
  const filters = useAppSelector(state => selectFilters(state));
  const dispatch = useAppDispatch();
  const allUserListState = useGetUserSelectListQuery();
  const teamAndApprovers = useAppSelector(state => selectTeamAndApprovers(state));
  const [team, setTeam] = useState<ActiveUserModel[]>([]);
  const [approvers, setApprovers] = useState<ActiveUserModel[]>([]);
  const projectSettings = useAppSelector(state => selectProjectSettings(state));

  useEffect(() => {
    if (!allUserListState.isFetching) {
      let unAssigned = { userId: 0, fullName: 'Unassigned' } as ActiveUserModel;
      let noApprover = { userId: 0, fullName: 'No Approvers' } as ActiveUserModel;

      team.push(unAssigned);
      approvers.push(noApprover);

      if (allUserListState.data) {
        team.push(...allUserListState.data.filter(x => teamAndApprovers.team && teamAndApprovers.team.indexOf(x.userId) > -1));
        approvers.push(...allUserListState.data.filter(x => teamAndApprovers.approvers && teamAndApprovers.approvers.indexOf(x.userId) > -1))
      }

      setTeam([...team]);
      setApprovers([...approvers]);

    }
  }, [allUserListState.isFetching]);

  const onMyQuestionsChange = (checked: boolean) => {
    onChange("myQuestions", checked);
  }

  const onStatusChange = (values: number[]) => {
    onChange("statuses", values);
  }

  const onTeamChange = (values: number[]) => {
    onChange("assignees", values);
  }

  const onApproversChange = (values: number[]) => {
    onChange("approvers", values);
  }

  const onSearch = (values: string) => {
    onChange("searchText", _.trim(values));
  }

  const onChange = (field: string, value: any) => {
    dispatch(setFilters({
      filters: {
        ...filters,
        [field]: value
      }
    }));
  }

  return (
    <Card size="small" className="mb-5 border">
      <Row className="gx-4">
        <Col>
          <label className="fs-7">Search Questions</label><br />
          <Search allowClear placeholder="Enter Search Term" style={{ width: '100%' }} onSearch={onSearch} />
        </Col>
        <Col sm={3} md={2} className="text-center">
          <label className="fs-7">My Questions</label><br />
          <Switch checked={filters?.myQuestions} onChange={onMyQuestionsChange} />
        </Col>

        <Col>
          <label className="fs-7">Status</label><br />
          <Select
            allowClear
            maxTagCount={1}
            mode="multiple"
            options={QuestionStatusDetails}
            fieldNames={{ label: "name" }}
            style={{ width: '100%' }}
            placeholder="Filter by Status"
            onChange={onStatusChange}
          />
        </Col>
        {
          projectSettings?.hasFullAccess &&
          <>
            <Col>
              <label className="fs-7">Team Members</label><br />
              <Select
                allowClear
                maxTagCount={1}
                mode="multiple"
                options={team}
                fieldNames={{ label: "fullName", value: "userId" }}
                style={{ width: '100%' }}
                loading={allUserListState.isFetching}
                placeholder="Filter by Team member"
                onChange={onTeamChange}
              />
            </Col>

            <Col>
              <label className="fs-7">Approvers</label><br />
              <Select
                allowClear
                maxTagCount={1}
                mode="multiple"
                options={approvers}
                fieldNames={{ label: "fullName", value: "userId" }}
                style={{ width: '100%' }}
                loading={allUserListState.isFetching}
                placeholder="Filter by Approver"
                onChange={onApproversChange}
              />
            </Col>
          </>
        }
      </Row>
    </Card>
  )
}