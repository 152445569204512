/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash'
import { FC } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { useAppSelector } from '../../../../redux/hooks'
import { selectMasterDataDictByType } from '../../../../redux/MasterDataSlice'
import { Link } from 'react-router-dom'

const HeaderUserMenu: FC = () => {
    const { user, logout, user_metadata } = useAuth();
    const roles = useAppSelector(state => selectMasterDataDictByType(state, "Roles"));

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='d-flex flex-column'>
                        <div className='fw-bolder d-flex align-items-center fs-5'>
                            {user?.user_metadata.firstName} {user?.user_metadata.lastName}
                        </div>
                        <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                            {user?.email}
                        </a>
                        <div className='fs-7 mt-2'>
                            {
                                _.map(user_metadata?.roles, id => roles[id].name).join(", ")
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='separator my-2'></div>
            
            <div className='menu-item px-5'>
                <Link to={'/profile'} className='menu-link px-5'>
                    My Profile
                </Link>
            </div>

            <div className='menu-item px-5'>
                <a onClick={logout} className='menu-link px-5'>
                    Sign Out
                </a>
            </div>
        </div>
    )
}

export { HeaderUserMenu }

