import { App, Button, Card } from 'antd'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { resetPassword } from '../core/_requests'
import { getSession, loginWithToken } from '../core/supabaseClient'


const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
})

const commonErrorMsg = 'Sorry, looks like there are some errors detected, please try again.'

export function ResetPassword() {
  const [errors, setErrors] = useState<string | undefined | null>(null);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const [initialValues, setInitialValues] = useState<{ email: string, password: string }>({
    email: '',
    password: ''
  })

  useEffect(() => {
    setErrors(undefined);

    (async () => {
      let { search: searchString } = window.location;
      const searchParams = searchString ? new URLSearchParams(searchString) : null;

      if (searchParams && searchParams.get("token") && searchParams.get("type")) {
        let response = await loginWithToken(
          searchParams.get("token") ?? '',
          searchParams.get("type") === 'recovery' ? 'recovery' : 'email'
        );

        if (response.error) {
          navigate('/error/common', {
            state: {
              error_description: response.error.message,
              redirect_url: '/auth/login'
            }
          });
          return;
        }

        if (response.data && response.data.session?.user.email) {
          initialValues.email = response.data.session.user.email || '';
          setInitialValues({ ...initialValues });
          return;
        }
      }

      await redirectToLogin();

    })();
  }, [])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: resetPasswordSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      setErrors(undefined)

      resetPassword(values.password)
        .then(async ({ data, error }) => {
          if (error)
            throw error

          setErrors('');

          notification.success({
            message: "Successfull",
            description: 'Password reset successfully',
            placement: "topRight",
            duration: 10
          });

          await redirectToLogin(2000);
        })
        .catch((error) => {
          setErrors(error?.message || commonErrorMsg)
          setStatus('Unable to process request')
        })
        .finally(() => {
          setSubmitting(false)
        })
    },

  })

  const redirectToLogin = async (delay: number = 0) => {
    await logout();
    setTimeout(() => {
      navigate('/auth/login');
    }, delay);
  }

  const onCancel = () => {
    redirectToLogin();
  }

  return (
    <>
      <div className='text-center mb-11'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/rfp-ninja-logo-transparent.png')} className='h-75px mb-10' />
        <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
      </div>

      {
        initialValues.email === '' ?
          <Card loading={true} /> :
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            {errors === undefined && (
              <div className='mb-lg-15 alert alert-info'>
                <div className='alert-text font-weight-bold'>
                  After successfully changing your password, you will be redirected to the login page.
                </div>
              </div>
            )}

            {errors && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  {errors}
                </div>
              </div>
            )}

            {errors === '' && <>
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>Password reset successfully. Please login with your new password.</div>
              </div>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-light'
                >
                  Redirect to Login
                </button>
              </Link>
            </>}

            {errors === undefined && <>

              <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
                <input
                  type='email'
                  placeholder=''
                  disabled
                  autoComplete='off'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                <input
                  type='password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='d-flex' style={{ gap: '8px' }}>
                <Button type='default'
                  htmlType='button'
                  size='large'
                  className='w-50'
                  disabled={formik.isSubmitting}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button block type='primary'
                  htmlType='submit'
                  size='large'
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting || !formik.isValid}
                  className='w-50'>
                  Submit
                </Button>
              </div>
            </>}
          </form>
      }

    </>
  )
}
