import { RTKQueryTags } from '../../app/common/Enums';
import { APIResponseModel, DataSetSelectModel } from '../../app/models';
import { RTKBaseApi } from './BaseApi';

const DatasetApi = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDatasetSelectList: builder.query<DataSetSelectModel[], void>({
      query: () => ({
        url: '/company/active_dataset_list_select',
      }),
      transformResponse: (response: APIResponseModel<DataSetSelectModel[]>) => {
        let datasets:DataSetSelectModel[] = [{
          datasetId: -1,
          datasetTypeId: 0,
          datasetName: "Default",
          active: true
        }];

        datasets.push(...(response.data || []));
        return datasets;
      },
      providesTags: (_) => [RTKQueryTags.DatasetSelectList]
    }),
  })
})

export const { useGetDatasetSelectListQuery } = DatasetApi