import { FileExcelOutlined, FileImageOutlined, FilePdfOutlined, FilePptOutlined, FileTextOutlined, FileWordOutlined, LoadingOutlined } from '@ant-design/icons';
import { Tag, TagProps } from "antd";
import { useEffect, useMemo } from 'react';
import { useGetFileDownloadUrlMutation } from '../../../redux/rtkquery/CommonApi';
import { downloadFileSilently } from '../CommonFunctions';

interface TagExtendedProps extends TagProps {
    fileName: string,
    fileGuid: string,
    disabled?: boolean,
    visible?: boolean
}

const AttachmentTag: React.FC<TagExtendedProps> = (props) => {
    const [triggerFileDownloadUrl, fileDownloadUrlResult] = useGetFileDownloadUrlMutation();
    const { fileName, fileGuid, ...rest } = props;
    const disabled = props.disabled === true;
    const visible = props.visible === false ? false : true;

    const fileIcon = useMemo(() => {

        if (fileDownloadUrlResult.isLoading)
            return <LoadingOutlined rev={0} />

        if (props.children)
            return null;

        let ext = fileName.split(".").pop();
        let icon = <FileTextOutlined rev={0} />;

        switch (ext) {
            case "pdf":
                icon = <FilePdfOutlined rev={0} style={{ color: '#f40f02' }} />;
                break;
            case "pptx":
                icon = <FilePptOutlined rev={0} style={{ color: '#d24726' }} />;
                break;
            case "docx":
                icon = <FileWordOutlined rev={0} style={{ color: '#2b579a' }} />;
                break;
            case "xlsx":
                icon = <FileExcelOutlined rev={0} style={{ color: '#1D6F42' }} />;
                break;
            case "jpg":
            case "jpeg":
            case "png":
                icon = <FileImageOutlined rev={0} style={{ color: '#5f9be7' }} />;
                break;
        }

        return icon;

    }, [fileName, fileDownloadUrlResult])

    const onTagClick = () => {
        if (!fileDownloadUrlResult.isLoading) {
            triggerFileDownloadUrl({ fileGuid, fileName })
        }
    }

    useEffect(() => {
        if (!fileDownloadUrlResult.isLoading && fileDownloadUrlResult.data?.data)
            downloadFileSilently(fileDownloadUrlResult.data.data, fileName);
    }, [fileDownloadUrlResult])

    return <>
        {
            visible &&
            <Tag icon={fileIcon} title={fileName}
                color={fileDownloadUrlResult.isLoading ? 'processing' : 'default'}
                onClick={!disabled ? onTagClick : undefined}
                {...rest}
                className={`${props.className || ''} ${!disabled ? 'cursor-pointer text-hover-primary text-truncate' : ''}`}
            >
                {props.children || fileName}
            </Tag>
        }
    </>
}

export default AttachmentTag;