import { App, Button, Card, Divider, Input, List, Radio, Select, Space, Tag } from "antd";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useGetDatasetSelectListQuery } from "../../../redux/rtkquery/DatasetApi";
import { useLibrarySearchMutation } from "../../../redux/rtkquery/LibraryApi";
import HTMLContent from "../../common/components/HtmlContent";
import { LibrarySearchFilterModel, LibrarySearchQuestionModel } from "../../models";
import { filterOptions, getDatasetGroups } from "../../common/CommonFunctions";
import AttachmentTag from "../../common/components/AttachmentTag";

type ComponentProps = {
    searchLabel?: string,
    totalRecords?: number,
    onSelect: (question: LibrarySearchQuestionModel) => void
}

const SearchQuestionMatch: React.FC<ComponentProps> = (props) => {
    const { notification } = App.useApp();
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [triggerSearch, searchResult] = useLibrarySearchMutation();
    const [selectedQuestion, setSelectedQuestion] = useState<LibrarySearchQuestionModel | null>(null);
    const [questions, setQuestions] = useState<LibrarySearchQuestionModel[]>([]);
    const [filters, setFilters] = useState<LibrarySearchFilterModel>({
        datasetId: null,
        keyword: null,
        offset: 0,
        currentPage: 1,
        pageSize: 5,
        totalRecords: props.totalRecords || 50
    });

    const onSelectQuestion = (question: LibrarySearchQuestionModel) => {
        setSelectedQuestion(question);
        props.onSelect(question);
    }

    const searchQuestions = (filters: LibrarySearchFilterModel) => {
        triggerSearch(filters).then(response => {
            if ('data' in response) {
                let listingModel = response.data.data;
                setFilters({ ...filters, currentPage: 1 })
                setQuestions(listingModel?.questions || []);
            }
        })
    }

    const onSearch = () => {
        if (filters.keyword || filters.datasetId) {
            setSelectedQuestion(null);
            searchQuestions(filters);
        }
        else
            notification.warning({
                message: "Validation failed",
                description: "Please enter a Keyword or choose a Dataset to search"
            })
    }

    const onPagingChange = (page: number, size: number) => {
        let _filters = { ...filters };
        _filters.currentPage = filters.pageSize !== size ? 1 : page;
        _filters.pageSize = size;
        setFilters(_filters);
    }

    const onKeywordChange = (value: string) => {
        setFilters(prev => ({ ...prev, keyword: value }));
    }

    const onDatasetsChange = (datasetId: number) => {
        setFilters(prev => ({ ...prev, datasetId: datasetId || null }));
    }

    const datasetGroups = useMemo(() => {
        return getDatasetGroups(allDatasetListState.data || [])
    }, [allDatasetListState]);

    const filterOption = useCallback(filterOptions, [allDatasetListState]);

    return (
        <>
            <label className="ant-form-item-label pb-2">{props.searchLabel || "Search library for a matching answer."}</label>
            <Space.Compact className="w-100">
                <Input allowClear
                    placeholder="Keywords"
                    size="large"
                    onChange={(e) => onKeywordChange(e.target.value)}
                />
                <Select
                    showSearch
                    allowClear
                    size="large"
                    options={datasetGroups}
                    placeholder="Select Dataset"
                    loading={allDatasetListState.isFetching}
                    style={{ width: '250px' }}
                    onChange={onDatasetsChange}
                    filterOption={filterOption}
                />
                <Button type="primary"
                    size="large"
                    onClick={onSearch}
                    loading={searchResult.isLoading}
                >Search</Button>
            </Space.Compact>

            <Divider />

            <List
                loading={searchResult.isLoading}
                pagination={{
                    align: "center",
                    showSizeChanger: true,
                    pageSizeOptions: [5, 10, 15, 20],
                    current: filters.currentPage,
                    pageSize: filters.pageSize,
                    onChange: onPagingChange,
                    total: questions.length,
                    hideOnSinglePage: questions.length > 5 ? false : true
                }}
                dataSource={questions}
                renderItem={(item, index) => (
                    <div className='d-flex'>
                        <div className='pt-3 pe-2'>
                            <Radio name="librarySearch"
                                value={item.library_question_id}
                                checked={selectedQuestion?.library_question_id === item.library_question_id}
                                onChange={(e) => e.target.checked && onSelectQuestion(item)} />
                        </div>
                        <Card className='mb-3 flex-grow-1'
                            headStyle={{ padding: '0' }}
                            size='small'
                            bordered={true}
                            title={<div className='py-3 px-4 bg-light d-linebreak'>
                                {item.question}
                            </div>
                            }
                        >
                            {
                                !_.isNil(item.dataset_id) &&
                                <Tag color="cyan">
                                    {allDatasetListState.data?.find(x => x.datasetId === item.dataset_id)?.datasetName}
                                </Tag>
                            }

                            <HTMLContent className='mt-3' content={item.answer} lines={5} />
                            {
                                _.size(item.libraryDocument) > 0 ?
                                    <>
                                        <Divider className='mb-3 mt-2' />
                                        {
                                            _.map(item.libraryDocument, x => (
                                                <AttachmentTag key={x.attachmentId} style={{ maxWidth: '200px' }}
                                                    fileName={x.fileDisplayName} fileGuid={x.fileName} />
                                            ))
                                        }
                                    </>
                                    : null
                            }
                        </Card>
                    </div>
                )}
            />
        </>
    )
}

export { SearchQuestionMatch };

