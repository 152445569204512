import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { AuthInit } from './modules/auth'
import { App as AntdApp } from 'antd';

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <AntdApp>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
              <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      </AntdApp>
    </Suspense>
  )
}

export { App }

