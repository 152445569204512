import { ArrowLeftOutlined, DownOutlined, FileDoneOutlined, FileExcelOutlined, FileWordOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Drawer, Dropdown, MenuProps, Skeleton, Space } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { selectMasterDataDictByType } from '../../../redux/MasterDataSlice';
import {
    addFetchedAnswers, addGeneratedAnswers, addProject, addProjectQuestions, removeProject,
    selectProjectById, selectProjectSettings, selectQuestionAnswersJobStatus, selectQuestionIdsToFetchAnswers,
    selectSections, selectSelectedSection
} from "../../../redux/ProjectsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useExportProjectInExcelMutation, useExportProjectQuestionsMutation, useLazyGetProjectQuery } from "../../../redux/rtkquery/ProjectApi";
import { useGetAnswersMutation, useLazyGetQuestionsQuery } from "../../../redux/rtkquery/QuestionApi";
import { useExportSectionInExcelMutation } from '../../../redux/rtkquery/SectionApi';
import { sanitizeAndLimitFileName } from '../../common/CommonFunctions';
import { ExportTemplateType } from '../../common/Enums';
import { RoutedTabs } from "../../common/components/RoutedTabs";
import useWordExport from '../../common/components/useWordExport';
import { ProjectModel, QuestionExportFilterModel } from '../../models';
import { useAuth } from '../auth';
import { AddQuestions } from "./AddQuestions";
import { EditProject } from "./EditProject";
import { QuestionsTab } from "./QuestionsTab";
import { ReviewQuestions } from './ReviewQuestions';

const Project: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const REVIEW_QUESTIONS = 'review-questions';
    const { projectId, source } = useParams();
    const { user_metadata } = useAuth();
    const [addQuestionsModalOpen, setAddQuestionsModalOpen] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [exportMenuItems, setExportMenuItems] = useState<MenuProps['items']>();
    const projectStatusDict = useAppSelector(state => selectMasterDataDictByType(state, "ProjectStatus"));
    const project = useAppSelector(state => selectProjectById(state, projectId || 0)) as ProjectModel;
    const projectSettings = useAppSelector(state => selectProjectSettings(state));
    const selectedSection = useAppSelector(state => selectSelectedSection(state));
    const questionAnswersJobStatusIds = useAppSelector(state => selectQuestionAnswersJobStatus(state));
    const questionIdsToFetchAnswers = useAppSelector(state => selectQuestionIdsToFetchAnswers(state));
    const generatingAnswerQuestionIds = useRef<number[]>([]);
    const isGeneratingAnswerStatusFetching = useRef(false);
    const responseFormatsDict = useAppSelector(state => selectMasterDataDictByType(state, "ResponseFormat"));

    const [triggerGetProject, getProjectResult] = useLazyGetProjectQuery();
    const [triggerGetQuestions, getQuestionsResult] = useLazyGetQuestionsQuery();
    const [triggerExportSection, exportSectionResult] = useExportSectionInExcelMutation();
    const [triggerExportProject, exportProjectResult] = useExportProjectInExcelMutation();
    const [triggerExportQuestions] = useExportProjectQuestionsMutation();
    const [triggerGetAnswers] = useGetAnswersMutation();
    const { exportToWord } = useWordExport();

    const Tabs = [{
        label: 'Details',
        key: 'details',
        children: <EditProject projectId={Number(projectId)} />
    },
    {
        label: 'Questions',
        key: 'questions',
        children: <QuestionsTab projectId={Number(projectId)} />
    },
    {
        label: 'Review Questions',
        key: 'review-questions',
        children: <ReviewQuestions projectId={Number(projectId)} />
    }];

    useEffect(() => {
        if (projectId) {
            triggerGetProject(projectId, false);

            return () => {
                dispatch(removeProject());
            }
        }
    }, []);

    // Polling to get status of generating answers.  
    useEffect(() => {

        let intervalId = setInterval(() => {
            if (generatingAnswerQuestionIds.current.length && !isGeneratingAnswerStatusFetching.current) {

                // Set polling status
                isGeneratingAnswerStatusFetching.current = true;

                triggerGetAnswers({
                    projectId: Number(projectId),
                    sectionId: null,
                    questionIds: generatingAnswerQuestionIds.current,
                    timestamp: new Date().valueOf()
                })
                    .then(response => {
                        if ('data' in response && response.data.data?.answers?.length) {
                            dispatch(addGeneratedAnswers({
                                answers: response.data.data.answers,
                                documents: response.data.data.documents || []
                            }));
                        }
                    })
                    .finally(() => {
                        isGeneratingAnswerStatusFetching.current = false;
                    });
            }
        }, Number(process.env.REACT_APP_ANSWER_JOB_RETRY_MILISECONDS || 5000));

        return () => clearInterval(intervalId); // Remove subscription on unmount
    }, []);

    useEffect(() => {
        if (getProjectResult.requestId && !getProjectResult.isFetching) {
            let _project = getProjectResult.data;
            if (_project) {
                dispatch(addProject({
                    project: _project,
                    user: user_metadata || null
                }));

                if (_project.sections?.length) {
                    triggerGetQuestions({
                        projectId: _project.projectId,
                        sectionId: null
                    });
                }
                else {
                    onToggleAddQuestions();
                }
            }

            if (getProjectResult.isError) {
                navigate('/projects')
            }
        }
    }, [getProjectResult]);

    useEffect(() => {
        if (getQuestionsResult.requestId && !getQuestionsResult.isFetching) {

            const questions = getQuestionsResult.data || [];

            dispatch(addProjectQuestions({
                questions,
                projectId: project.projectId
            }));

            if (questions.length === 0) {
                onToggleAddQuestions();
            }
        }
    }, [getQuestionsResult]);

    useEffect(() => {
        const exportItems: MenuProps['items'] = [];

        if (project) {
            if (project.sections?.length) {
                exportItems.push(
                    {
                        label: 'Export Project to Word',
                        key: '1',
                        icon: <FileWordOutlined rev={0} style={{ color: '#2b579a' }} />
                    },
                    {
                        label: 'Export Project to Excel',
                        key: '2',
                        icon: <FileExcelOutlined rev={0} style={{ color: '#217346' }} />
                    }
                )
            }

            if (selectedSection) {
                exportItems.push(
                    {
                        type: 'divider'
                    },
                    {
                        label: 'Export Section to Word',
                        key: '5',
                        icon: <FileWordOutlined rev={0} style={{ color: '#2b579a' }} />
                    },
                    {
                        label: 'Export Section to Excel',
                        key: '3',
                        icon: <FileExcelOutlined rev={0} style={{ color: '#217346' }} />
                    }
                );

                if (selectedSection.exportInOriginalFormat) {
                    exportItems.push({
                        label: 'Export Section to Original Format',
                        key: '4',
                        icon: <FileExcelOutlined rev={0} style={{ color: '#217346' }} />
                    });
                }
            }
        }
        setExportMenuItems(exportItems);

    }, [selectedSection, project]);

    useEffect(() => {
        generatingAnswerQuestionIds.current = questionAnswersJobStatusIds;
    }, [questionAnswersJobStatusIds]);

    useEffect(() => {
        let questionIds = cloneDeep(questionIdsToFetchAnswers);
        if (questionIds.length) {
            triggerGetAnswers({
                projectId: Number(projectId),
                sectionId: null,
                questionIds,
                timestamp: new Date().valueOf()
            })
                .then(response => {
                    if ('data' in response && response.data.data?.answers) {
                        dispatch(addFetchedAnswers({
                            answers: response.data.data.answers,
                            documents: response.data.data.documents || [],
                            questionIds
                        }));
                    }
                });
        }
    }, [questionIdsToFetchAnswers]);

    // useEffect(() => {
    //     if(_.sumBy(sections, x => x.questionIds?.length || 0) || 0)
    //     {
    //         onToggleAddQuestions();
    //     }
    // }, [sections]);

    const onWordExport = async (sectionExport?: boolean) => {
        setExporting(true);
        try {
            let totalRecords = 0, currentPage = 1, pageSize = 25, questions = [], sections: any[] = [];
            let exportData = {
                project: project.projectName,
                customer: project.customerName || '',
                sections
            }

            do {
                let response = await getExportQuestionList({
                    projectId: project.projectId,
                    sectionId: sectionExport ? selectedSection?.sectionId || 0 : null,
                    currentPage,
                    offset: pageSize * (currentPage - 1),
                    pageSize,
                    totalRecords
                });

                if (response) {
                    totalRecords = totalRecords === 0 ? response.totalRecords : totalRecords;
                    currentPage++;

                    questions.push(...response.questions);
                }

            } while (currentPage < ((totalRecords / pageSize) + (totalRecords % pageSize ? 1 : 0)));

            if (questions.length) {
                _.forOwn(_.groupBy(questions, x => x.sectionId), (questions, sectionId) => {
                    exportData.sections.push({
                        sectionId,
                        section: questions[0].sectionName,
                        questions: _.map(questions, q => {
                            q.answers = _.transform(q.answers, (r: any[], v) => {
                                v && v.answer && r.push({
                                    answer: v.answer,
                                    responseFormat: responseFormatsDict[v.responseFormatId]?.name || "No Format"
                                })
                            }, []);
                            q.answerCount = q.answers.length;
                            return q;
                        })
                    })
                });
            }

            let fileName = sanitizeAndLimitFileName(sectionExport ? selectedSection?.sectionName || '' : project.projectName);
            await exportToWord(ExportTemplateType.Project, fileName, exportData);
        }
        catch (e: any) { console.log(e) }
        finally {
            setExporting(false);
        }
    }

    const getExportQuestionList = async (filters: QuestionExportFilterModel) => {
        let response = await triggerExportQuestions(filters);

        if ('data' in response) {
            return cloneDeep(response.data.data);
        }
        return null;
    }

    const onExportClick: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case '1':
                onWordExport();
                break;
            case '2':
                triggerExportProject({
                    projectId: project?.projectId,
                    fileName: sanitizeAndLimitFileName(project?.projectName || '') + '.xlsx'
                });
                break;
            case '3':
                triggerExportSection({
                    projectId: project?.projectId,
                    sectionId: selectedSection?.sectionId,
                    exportInDefaultFormat: true,
                    fileName: sanitizeAndLimitFileName(selectedSection?.sectionName || '') + '.xlsx'
                });
                break;
            case '4':
                triggerExportSection({
                    projectId: project?.projectId,
                    sectionId: selectedSection?.sectionId,
                    exportInDefaultFormat: false,
                    fileName: sanitizeAndLimitFileName(selectedSection?.sectionName || '') + '.xlsx'
                });
                break;
            case '5':
                onWordExport(true);
                break;
        }
    }

    const onToggleAddQuestions = (navigatePath?: string) => {
        setAddQuestionsModalOpen(!addQuestionsModalOpen);

        navigatePath && navigate(navigatePath)
    }

    const onProjectActionsClick: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case 'add-questions':
                onToggleAddQuestions();
                break;
            case 'review-questions':
                navigate('../review-questions')
                break;
        }
    }

    const ProjectActions = useMemo(() => {
        let menus = [{
            label: 'Add Questions',
            key: 'add-questions',
            icon: <PlusOutlined rev={0} />
        }];

        if ((project?.questionFiles?.length ?? 0) > 0) {
            menus.push({
                label: 'Review Extracted Questions',
                key: 'review-questions',
                icon: <FileDoneOutlined rev={0} />
            })
        }

        return menus;

    }, [project]);

    const exportMenuProps = {
        items: exportMenuItems,
        onClick: onExportClick
    }

    return (
        <div id="project">
            {
                !project ?
                    <>
                        <Card className="mb-5">
                            <Skeleton active />
                        </Card>
                        <Card>
                            <Skeleton active />
                            <Divider />
                            <Skeleton active />
                        </Card>
                    </>
                    :
                    <>
                        <Card className="top-card"
                            title={
                                <>
                                    <h2 className="text-gray-800 fs-2 fw-bolder me-1">{project.projectName}</h2>
                                    <div className="text-muted fw-bold">
                                        {
                                            dayjs(project.dueDate).format('MM/DD/YYYY')
                                        }
                                        <span className="mx-3">|</span>
                                        {
                                            projectStatusDict[project.statusId].name
                                        }
                                    </div>
                                </>
                            }
                            extra={
                                <>
                                    <div className={source === REVIEW_QUESTIONS ? 'd-none' : ''}>
                                        <Space>
                                            <Link to={'/projects'}>
                                                <Button type='default' icon={<ArrowLeftOutlined rev={0} />}>All Projects</Button>
                                            </Link>
                                            {
                                                projectSettings?.isProjectOpen && projectSettings.hasFullAccess &&
                                                <Dropdown arrow placement="bottomRight"
                                                    menu={{
                                                        items: ProjectActions,
                                                        onClick: onProjectActionsClick
                                                    }}>
                                                    <Button type='primary' ghost>
                                                        <Space>
                                                            Actions
                                                            <DownOutlined rev={0} />
                                                        </Space>
                                                    </Button>
                                                </Dropdown>
                                            }
                                            {
                                                (project.sections?.length || 0) > 0 &&
                                                <Dropdown arrow menu={exportMenuProps}
                                                    disabled={exporting || exportSectionResult.isLoading || exportProjectResult.isLoading}>
                                                    <Button type="primary" ghost
                                                        loading={exporting || exportSectionResult.isLoading || exportProjectResult.isLoading}>
                                                        <Space>
                                                            <span>{exporting || exportSectionResult.isLoading || exportProjectResult.isLoading ? 'Exporting' : 'Export'}</span>
                                                            <DownOutlined rev={0} />
                                                        </Space>
                                                    </Button>
                                                </Dropdown>
                                            }
                                        </Space>
                                    </div>
                                    {
                                        source === REVIEW_QUESTIONS &&
                                        <Link to={'../questions'}>
                                            <Button type='primary' ghost icon={<ArrowLeftOutlined rev={0} />}>
                                                Back to Project
                                            </Button>
                                        </Link>
                                    }
                                </>
                            }
                        >
                            <div className={source === REVIEW_QUESTIONS ? 'd-none' : ''}>
                                <QuestionStatsRenderer projectId={project.projectId} loading={getQuestionsResult.isFetching} />
                            </div>
                        </Card>

                        <RoutedTabs className={"top-tabs " + (source === REVIEW_QUESTIONS ? 'hide-tabs' : '')}
                            items={Tabs}
                        />

                        <Drawer title="Add Questions"
                            maskClosable={false}
                            open={addQuestionsModalOpen}
                            onClose={() => onToggleAddQuestions()}
                            placement='right'
                            width={750}
                            destroyOnClose
                        >
                            <AddQuestions projectId={project.projectId} onQuestionAdded={onToggleAddQuestions} />
                        </Drawer>
                    </>
            }
        </div>
    )
}

export { Project };

const QuestionStatsRenderer: React.FC<{ projectId: number, loading: boolean }> = (props) => {
    const sections = useAppSelector(state => selectSections(state));

    return (
        <div className="d-flex flex-wrap flex-stack">
            <div className="d-flex flex-column flex-grow-1 pe-8">
                <div className="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-3 me-6 text-center">
                        <div className="fs-2 fw-bolder">{sections.length}</div>
                        <div className="fw-bold fs-6 text-gray-500">Sections</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-3 me-6 text-center">
                        <div className="fs-2 fw-bolder">{props.loading ?
                            <Skeleton.Avatar active={true} size="small" shape="circle" /> :
                            _.sumBy(sections, x => x.questionIds?.length) || 0}</div>
                        <div className="fw-bold fs-6 text-gray-500">Questions</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-3 me-6 text-center">
                        <div className="fs-2 fw-bolder">{props.loading ?
                            <Skeleton.Avatar active={true} size="small" shape="circle" /> :
                            _.sumBy(sections, x => x.totalUnAnswered) || 0}</div>
                        <div className="fw-bold fs-6 text-gray-500">Unanswered</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-3 me-6 text-center">
                        <div className="fs-2 fw-bolder">{props.loading ?
                            <Skeleton.Avatar active={true} size="small" shape="circle" /> :
                            _.sumBy(sections, x => x.totalSubmitted) || 0}</div>
                        <div className="fw-bold fs-6 text-gray-500">Submitted</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-3 me-6 text-center">
                        <div className="fs-2 fw-bolder">{props.loading ?
                            <Skeleton.Avatar active={true} size="small" shape="circle" /> :
                            _.sumBy(sections, x => x.totalAccepted) || 0}</div>
                        <div className="fw-bold fs-6 text-gray-500">Accepted</div>
                    </div>
                </div>
            </div>
            {/* <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                    <span className="fw-bold fs-6 text-gray-500">Completion</span>
                    <span className="fw-bolder fs-6">50%</span></div>
                <div className="h-5px mx-3 w-100 bg-light mb-3">
                    <div className="bg-success rounded h-5px" role="progressbar" style={{ width: '50%' }}></div>
                </div>
            </div> */}
        </div>
    )
}