import {
  APIResponseModel, LibraryFilterModel, LibraryListingModel,
  LibraryQuestionModel, LibraryQuestionRecommendationModel, LibraryRecommendationListingModel, LibrarySearchFilterModel, LibrarySearchQuestionListingModel
}
  from '../../app/models';
import { RTKBaseApi } from './BaseApi';

const LibraryApi = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    saveQuestion: builder.mutation<APIResponseModel<void>, LibraryQuestionModel>({
      query: (params) => ({
        url: params.libraryQuestionId > 0 ? '/library/update' : '/library/create',
        method: "POST",
        body: params
      })
    }),
    getQuestion: builder.query<APIResponseModel<LibraryQuestionModel[]>, {libraryQuestionId:number}>({
      query: (params) => ({
        url: `/library/question/${params.libraryQuestionId}`,
      }),
    }),
    list: builder.mutation<APIResponseModel<LibraryListingModel>, LibraryFilterModel>({
      query: (params) => ({
        url: '/library/list',
        method: "POST",
        body: params
      })
    }),
    deleteQuestion: builder.mutation<APIResponseModel<void>, {libraryQuestionId:number}>({
      query: (params) => ({
        url: `/library/delete/${params.libraryQuestionId}`,
        method: "DELETE"
      })
    }),
    librarySearch: builder.mutation<APIResponseModel<LibrarySearchQuestionListingModel>, LibrarySearchFilterModel>({
      query: (params) => ({
        url: '/library/search',
        method: "POST",
        body: params
      })
    }),
    saveRecommendation: builder.mutation<APIResponseModel<void>, LibraryQuestionRecommendationModel>({
      query: (params) => ({
        url: '/library/add_recommendation',
        method: "POST",
        body: params
      })
    }),
    updateRecommendation: builder.mutation<APIResponseModel<void>, LibraryQuestionRecommendationModel>({
      query: (params) => ({
        url: '/library/update_recommendation',
        method: "POST",
        body: params
      })
    }),
    listRecommendation: builder.mutation<APIResponseModel<LibraryRecommendationListingModel>, LibraryFilterModel>({
      query: (params) => ({
        url: '/library/unreviewed',
        method: "POST",
        body: params
      })
    }),
  }),
})

export const {
  useSaveQuestionMutation,
  useLazyGetQuestionQuery,
  useSaveRecommendationMutation,
  useUpdateRecommendationMutation,
  useListMutation,
  useListRecommendationMutation,
  useDeleteQuestionMutation,
  useLibrarySearchMutation
} = LibraryApi