import { APIResponseModel, CompanyProfileModel, DataSetModel } from '../../app/models';
import { RTKBaseApi } from './BaseApi';

const CompanyApi = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<CompanyProfileModel | null, void>({
      query: (params) => ({
        url: '/company/profile',
        method: "GET"
      }),
      transformResponse: (response: APIResponseModel<CompanyProfileModel>) => response.data,
      keepUnusedDataFor: 0,
    }),
    saveProfile: builder.mutation<APIResponseModel<CompanyProfileModel>, CompanyProfileModel>({
      query: (params) => ({
        url: '/company/profile',
        method: "POST",
        body: params
      })
    }),
    getDatasets: builder.query<DataSetModel[] | null, number[]>({
      query: (params) => ({
        url: `company/datasets?datasetTypeIds=${params.join(',')}`,
        method: "GET"
      }),
      transformResponse: (response: APIResponseModel<DataSetModel[]>) => response.data || [],
      keepUnusedDataFor: 0,
    }),
    saveDataset: builder.mutation<APIResponseModel<DataSetModel>, DataSetModel>({
      query: (params) => ({
        url: '/company/datasets',
        method: "POST",
        body: params
      })
    }),
    deleteDataset: builder.mutation<APIResponseModel<void>, number>({
      query: (params) => ({
        url: `/company/datasets/${params}`,
        method: "DELETE"
      })
    }),
    completeSetup: builder.mutation<APIResponseModel<void>, void>({
      query: () => ({
        url: `/company/completesetup`,
        method: "POST"
      })
    }),
    RebuildIndex: builder.mutation<APIResponseModel<void>, void>({
      query: () => ({
        url: `/company/rebuildindex`,
        method: "POST"
      })
    }),
  })
})

export const { 
  useLazyGetProfileQuery, 
  useSaveProfileMutation,
  useLazyGetDatasetsQuery,
  useSaveDatasetMutation,
  useDeleteDatasetMutation,
  useCompleteSetupMutation,
  useRebuildIndexMutation
  } = CompanyApi