import { PricingRequestStatus, RTKQueryTags } from '../../app/common/Enums';
import {
    APIResponseModel, PricingInstructionListItemModel, PricingInstructionModel, PricingInstructionSelectItemModel, PricingModel,
    PricingRequestDetail, PricingRequestModel, PricingResponseModel, PricingStreamHandler, PricingSurchargeModel, ProductPricingModel
} from '../../app/models';
import { RTKBaseApi } from './BaseApi';

type StatusChangesParams = {
    pricingRequestId: number,
    statusId: PricingRequestStatus,
    comment: string | null,
    finalizedResponse: string | null
}

const PricingApi = RTKBaseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPricingInstructionSelectList: builder.query<PricingInstructionSelectItemModel[], void>({
            query: () => ({
                url: '/pricing/active_list_select',
            }),
            transformResponse: (response: APIResponseModel<PricingInstructionSelectItemModel[]>) => response.data || [],
            providesTags: (_) => [RTKQueryTags.PricingInstructionSelectList]
        }),
        getPricingInstructionList: builder.query<PricingInstructionListItemModel[], void>({
            query: () => ({
                url: '/pricing/list',
            }),
            transformResponse: (response: APIResponseModel<PricingInstructionListItemModel[]>) => response.data || []
        }),
        getPricingInstruction: builder.query<PricingModel | null, number | string>({
            query: (id) => ({
                url: `/pricing/${id}`,
            }),
            transformResponse: (response: APIResponseModel<PricingModel>) => response.data
        }),
        savePricingInstruction: builder.mutation<APIResponseModel<PricingInstructionModel>, PricingInstructionModel>({
            query: (params) => ({
                url: `/pricing/${params.id > 0 ? 'update' : 'create'}`,
                method: "POST",
                body: params
            })
        }),
        deletePricingInstruction: builder.mutation<APIResponseModel<void>, { id: number }>({
            query: (params) => ({
                url: `/pricing/delete/${params.id}`,
                method: "DELETE"
            })
        }),
        saveProductPricing: builder.mutation<APIResponseModel<ProductPricingModel>, ProductPricingModel>({
            query: (params) => ({
                url: `/pricing/${params.id > 0 ? 'update_product_pricing' : 'create_product_pricing'}`,
                method: "POST",
                body: params
            })
        }),
        updateProductPricingStatus: builder.mutation<APIResponseModel<void>, { id: number, status: boolean }>({
            query: (params) => ({
                url: '/pricing/update_product_pricing_status',
                method: "POST",
                body: params
            })
        }),
        copyProductPricing: builder.mutation<APIResponseModel<PricingModel>, { id: number }>({
            query: (params) => ({
                url: `/pricing/copy_product_pricing/${params.id}`,
                method: "POST",
                body: params
            })
        }),
        deleteProductPricing: builder.mutation<APIResponseModel<void>, { id: number }>({
            query: (params) => ({
                url: `/pricing/delete_product_pricing/${params.id}`,
                method: "DELETE"
            })
        }),
        savePricingSurcharge: builder.mutation<APIResponseModel<PricingSurchargeModel>, PricingSurchargeModel>({
            query: (params) => ({
                url: `/pricing/${params.id > 0 ? 'update_product_surcharge' : 'create_product_surcharge'}`,
                method: "POST",
                body: params
            })
        }),
        updatePricingSurchargeStatus: builder.mutation<APIResponseModel<void>, { id: number, status: boolean, productPricingId: number }>({
            query: (params) => ({
                url: '/pricing/update_product_surcharge_status',
                method: "POST",
                body: params
            })
        }),
        deletePricingSurcharge: builder.mutation<APIResponseModel<void>, { id: number, productPricingId: number }>({
            query: (params) => ({
                url: `/pricing/delete_product_surcharge/${params.id}`,
                method: "DELETE"
            })
        }),
        getPricingRequestList: builder.query<APIResponseModel<PricingRequestModel[]>, void>({
            query: () => ({
                url: '/pricing/request_list',
            })
        }),
        getPricingRequest: builder.query<APIResponseModel<PricingRequestDetail | null>, number>({
            query: (id) => ({
                url: `/pricing/pricing_request/${id}`,
            })
        }),
        savePricingRequest: builder.mutation<APIResponseModel<PricingRequestModel>, PricingRequestModel>({
            query: (params) => ({
                url: '/pricing/save_request',
                method: "POST",
                body: params
            })
        }),
        pricingGenerator: builder.mutation<APIResponseModel<PricingResponseModel>, PricingResponseModel>({
            query: (params) => ({
                url: '/pricing/pricing_generator',
                method: "POST",
                body: params
            })
        }),
        pricingStream: builder.mutation<void, PricingStreamHandler>({
            query: (params) => ({
                url: '/pricing/pricing_generator',
                method: "POST",
                body: params.data,
                responseHandler: async (response) => {

                    if (response.ok && response.body) {
                        const reader = response.body.getReader();
                        let done, value;
                        const decoder = new TextDecoder();

                        let responseId = Number(response.headers.get("response-Data"));

                        while (!done) {
                            ({ value, done } = await reader.read());

                            const decodedChunk = decoder.decode(value, { stream: true });
                            params.onChunk(decodedChunk, responseId);
                        }
                    }
                }
            })
        }),
        finalPricingGenerator: builder.mutation<APIResponseModel<string>, { pricingRequestId: number, pricingInstructionId: number }>({
            query: (params) => ({
                url: '/pricing/final_pricing',
                method: "POST",
                body: params
            })
        }),
        deletePricingResponse: builder.mutation<APIResponseModel<void>, { pricingRequestId: number, pricingReponseId: number }>({
            query: (params) => ({
                url: `/pricing/delete_pricing_response/${params.pricingRequestId}/${params.pricingReponseId}`,
                method: "DELETE"
            })
        }),
        deletePricingAttachment: builder.mutation<APIResponseModel<void>, { pricingRequestId: number, attachmentId: number }>({
            query: (params) => ({
                url: `/pricing/delete_pricing_attachment/${params.pricingRequestId}/${params.attachmentId}`,
                method: "DELETE"
            })
        }),
        setRequestStatus: builder.mutation<APIResponseModel<PricingRequestModel>, StatusChangesParams>({
            query: (params) => ({
                url: '/pricing/update_request_status',
                method: "POST",
                body: params
            })
        }),
    }),
})

export const {
    useGetPricingInstructionSelectListQuery,
    useLazyGetPricingInstructionListQuery,
    useGetPricingInstructionListQuery,
    useLazyGetPricingInstructionQuery,
    useSavePricingInstructionMutation,
    useDeletePricingInstructionMutation,

    useSaveProductPricingMutation,
    useUpdateProductPricingStatusMutation,
    useCopyProductPricingMutation,
    useDeleteProductPricingMutation,

    useSavePricingSurchargeMutation,
    useUpdatePricingSurchargeStatusMutation,
    useDeletePricingSurchargeMutation,

    useLazyGetPricingRequestListQuery,
    useLazyGetPricingRequestQuery,
    useSavePricingRequestMutation,
    usePricingGeneratorMutation,
    usePricingStreamMutation,
    useDeletePricingResponseMutation,
    useDeletePricingAttachmentMutation,

    useFinalPricingGeneratorMutation,
    useSetRequestStatusMutation

} = PricingApi