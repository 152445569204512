import { App, Form, Input, Spin, Switch } from "antd";
import _, { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useGetDatasetSelectListQuery } from "../../../redux/rtkquery/DatasetApi";
import { useSavePricingSurchargeMutation } from "../../../redux/rtkquery/PricingApi";
import FullScreenModal from "../../common/components/FullScreenModal";
import HtmlEditor from "../../common/components/HtmlEditor";
import { PricingSurchargeModel } from "../../models";
import { Col, Row } from "react-bootstrap";

type ComponentProps = {
    onModalClose: (pricingSurcharge?: PricingSurchargeModel | null) => void,
    pricingSurcharge: PricingSurchargeModel
}

const AddEditSurcharge: React.FC<ComponentProps> = (props) => {
    const pricingSurcharge = _.cloneDeep(props.pricingSurcharge);
    const [datasetName, setDatasetName] = useState("");
    const [form] = Form.useForm<PricingSurchargeModel>();
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [triggerSaveSurcharge, saveSurchargeResult] = useSavePricingSurchargeMutation();
    const { notification } = App.useApp();
    const instructionsEditorRef = useRef<any>(null),
        descriptionEditorRef = useRef<any>(null);

    const onFinishFailed = () => {
        notification.error({
            message: "Validation Error",
            description: "Please fix highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = async (values: PricingSurchargeModel) => {

        if (!_.trim(descriptionEditorRef.current.getContent({ format: "text" }))) {
            form.setFieldValue("description", '');
            form.validateFields();
            return;
        }

        if (!_.trim(instructionsEditorRef.current.getContent({ format: "text" }))) {
            form.setFieldValue("instructions", '');
            form.validateFields();
            return;
        }

        values.surchargeName = _.trim(values.surchargeName);
        values.instructions = _.trim(values.instructions);
        values.description = _.trim(values.description);

        const _pricingSurcharge = {
            ...pricingSurcharge,
            ...values
        }

        let response = await triggerSaveSurcharge(_pricingSurcharge);

        if (response && 'data' in response && response.data.success) {
            props.onModalClose(cloneDeep(response.data.data));
        }
    }

    useEffect(() => {
        // Set selected Dataset name
        if (allDatasetListState.data?.length) {
            setDatasetName(_.find(allDatasetListState.data, x => x.datasetId === pricingSurcharge._datasetId)?.datasetName || "")
        }
    }, [allDatasetListState])

    return (
        <FullScreenModal title={
            <h3>{pricingSurcharge.id ? 'Edit' : 'Add'} Surcharge Price {datasetName ? `for ${datasetName}` : ""}</h3>
        }
            centered
            open={true}
            closable={false}
            maskClosable={false}
            keyboard={false}
            width={800}
            destroyOnClose={true}
            onCancel={() => props.onModalClose()}
            okText="Save"
            onOk={form.submit}
            confirmLoading={saveSurchargeResult.isLoading}
        >
            <Spin spinning={saveSurchargeResult.isLoading}>
                <Form
                    layout="vertical"
                    form={form}
                    name="AddEditProductPricing"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={pricingSurcharge}
                >
                    <div className="text-gray-700 fs-6 fw-normal text-wrap">
                        <p className="mb-1">Provide Price instructions for the surcharge.</p>
                        <p>The pricing can be provided in any format. Simple to understand formats, such as a table or step by step directions work best.</p>
                    </div>

                    <Row>
                        <Col sm={10}>
                            <Form.Item name="surchargeName" label="Name"
                                rules={[{ required: true, message: "'${label}' is required" }]}>
                                <Input placeholder="Surcharge Name" />
                            </Form.Item>
                        </Col>
                        <Col sm={2}>
                            <Form.Item name="isActive" label="Active" valuePropName="checked">
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item name="description" label="Description"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <HtmlEditor setEditorRef={(editor: any) => descriptionEditorRef.current = editor}
                            placeholder={`Provide any general directions for calculating prices for your products.\nFor example, assumptions to make, format for the desired output, etc. Note, these instructions will be applied across all prices.`}
                        />
                    </Form.Item>

                    <Form.Item name="instructions" label="Pricing Instructions"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <HtmlEditor setEditorRef={(editor: any) => instructionsEditorRef.current = editor} />
                    </Form.Item>

                </Form>
            </Spin>
        </FullScreenModal>
    )
};

export { AddEditSurcharge };

