import { GridOptions, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Card, DatePicker } from "antd";
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectFilters, setFilters } from "../../../redux/DashboardSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useProjectStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { CONST_MAX_DATE } from "../../common/Constants";
import { DashboardStatsType } from "../../common/Enums";
import { ProjectListStatsModel } from "../../models";

const MyTaskStats: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [triggerProjectStats] = useProjectStatsMutation();
  const [dueDate, setDueDate] = useState<string | null>(null);
  const filters = useAppSelector(state => selectFilters(state, DashboardStatsType.MyTasks));
  const gridRef = useRef<AgGridReact<ProjectListStatsModel>>(null);

  const projectStatsGridOptions: GridOptions<ProjectListStatsModel> = {
    defaultColDef: {
      sortable: true,
      filter: false,
      //resizable: false,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      autoHeight: true,
      suppressMovable: true,
      suppressMenu: true,
      //suppressAutoSize: true,
      //flex: 1,
      onCellClicked: (event) => {
        navigate(`/projects/${event.data?.projectId}`)
      }
    },
    columnDefs:
      [
        {
          field: 'projectName',
          headerName: 'Project',
          flex: 1,
          cellRenderer: (params: ICellRendererParams<ProjectListStatsModel>) =>
            <div className="lh-1 py-2">
              <div className="fw-bold pb-2">
                {params.value}
              </div>
              <div className="fs-8 text-muted">Due on {dayjs(params.data?.dueDate).format("DD MMM YY")}</div>
            </div>
        },
        {
          field: 'pendingForAnswer',
          headerName: 'Responses',
          width: 110,
          cellClass: "text-center d-flex-v-center",
          valueFormatter: (params) => params.value || "--"
        },
        {
          field: 'pendingForReview',
          headerName: 'Approvals',
          width: 120,
          cellClass: "text-center d-flex-v-center",
          valueFormatter: (params) => params.value || "--"
        }
      ],
    rowData: null,
    rowHeight: 25,
    headerHeight: 25,
    onGridReady: (params) => {
      setTimeout(() => { loadStats(dueDate) }, 1000);
    }
  }

  const AntDPresets = [
    { label: 'Next 7 Days', value: dayjs().add(7, 'd') },
    { label: 'Next 15 Days', value: dayjs().add(15, 'd') },
    { label: 'Next 30 Days', value: dayjs().add(30, 'd') },
    { label: 'This Week', value: dayjs().endOf('w') },
    { label: 'Next Week', value: dayjs().add(1, 'w').endOf('w') },
    { label: 'This Month', value: dayjs().endOf('M') },
    { label: 'Next Month', value: dayjs().add(1, 'M').endOf('M') },
  ];

  const onDueDateChange = (date: any, dateString: string | null) => {

    setDueDate(dateString);
    loadStats(dateString);

    dispatch(setFilters({
      type: DashboardStatsType.MyTasks,
      filters: { dueDate: dateString }
    }));
  }

  const loadStats = (dueDate: string | null) => {

    gridRef.current?.api.setRowData([]);
    gridRef.current?.api.showLoadingOverlay();

    let limit = dueDate ? 1000 : 20;
    dueDate = dueDate || CONST_MAX_DATE;

    triggerProjectStats({ limit, dueDate })
      .then((response) => {
        if ('data' in response) {
          gridRef.current?.api.setRowData(response.data.data || []);
        }
      })
      .finally(() => {
        gridRef.current?.api.hideOverlay();
      })
  }

  useEffect(() => {
    setDueDate(filters?.dueDate || null);
  }, []);

  return (
    <Card size="small" bordered={false} title={<span className="fs-4">My Open Tasks</span>}
      extra={
        <DatePicker allowClear
          placeholder="Due Date"
          format="MM/DD/YYYY"
          presets={AntDPresets}
          size="small"
          value={dueDate ? dayjs(dueDate) : null}
          onChange={onDueDateChange}
        />
      }
    >
      <div className="ag-theme-alpine ag-theme-alpine-custom w-100" style={{ height: '200px' }}>
        <AgGridReact ref={gridRef} gridOptions={projectStatsGridOptions} />
      </div>
    </Card>
  )
}

export { MyTaskStats };

