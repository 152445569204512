import { RTKQueryTags } from '../../app/common/Enums';
import { APIResponseModel, CustomerSelectModel } from '../../app/models';
import { RTKBaseApi } from './BaseApi';

const CustomerApi = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerSelectList: builder.query<CustomerSelectModel[], void>({
      query: () => ({
        url: '/customer/list_select',
      }),
      transformResponse: (response: APIResponseModel<CustomerSelectModel[]>) => response.data || [],
      providesTags: (_) => [RTKQueryTags.CustomerSelectList],
      keepUnusedDataFor: 60 * 60
    }),
  }),
})

export const { useGetCustomerSelectListQuery } = CustomerApi