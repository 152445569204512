import { CSVBoxButton } from '@csvbox/react';
import { App, Button, Card, Form, Radio, Select, Space } from "antd";
import { useCallback, useMemo, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useGetDatasetSelectListQuery } from "../../../redux/rtkquery/DatasetApi";
import { useAuth } from '../auth';
import { filterOptions, getDatasetGroups } from '../../common/CommonFunctions';

type ComponentProps = {
    onClose: (import_id?: number) => void
}

enum QuestionImportType {
    ExcelUpload = 1,
    NinjaGenerate = 2
}

const ImportQuestions: React.FC<ComponentProps> = (props) => {
    const [form] = Form.useForm();
    const { user_metadata } = useAuth();
    const { notification } = App.useApp();
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [importType, setImportType] = useState<QuestionImportType | null>(null);
    const [currentStep, setCurrentStep] = useState<number>(1);

    const onImportComplete = async (result: boolean, importData: any) => {
        console.log(importData)

        if (result) {
            props.onClose(importData.import_id);
        } else {
            notification.error({
                message: "Import failed",
                description: 'Unable to import the file.',
                placement: "topRight"
            });
        }
    }

    const onSaveAndUploadExcel = (values: any) => {
        if (importType === QuestionImportType.ExcelUpload && currentStep === 2 && values.datasetId) {
            setCurrentStep(3);
        }
    }

    const  datasetGroups = useMemo(() => {
        return getDatasetGroups(allDatasetListState.data || [])
    }, [allDatasetListState]);

    const  filterOption = useCallback(filterOptions, [allDatasetListState]);

    return (<>
        {
            currentStep === 1 &&
            <div id="step_1">
                <h2 className='mb-10'>How would you like to add questions?</h2>

                <Card onClick={() => setImportType(QuestionImportType.ExcelUpload)}
                    className={'border mb-7 cursor-pointer ' + (importType === QuestionImportType.ExcelUpload ? 'border-primary bg-gray-100' : '')}>
                    <div className='d-flex'>
                        <div className='pr-2 flex-grow-1'>
                            <h4>Load questions using Excel</h4>
                            <div className='text-muted'>Load questions using the RFP Ninja Excel template.</div>
                        </div>
                        <div className='text-center'>
                            <Radio checked={importType === QuestionImportType.ExcelUpload} />
                        </div>
                    </div>
                </Card>

                <Card onClick={() => setImportType(QuestionImportType.NinjaGenerate)}
                    className={'border mb-10 cursor-pointer ' + (importType === QuestionImportType.NinjaGenerate ? 'border-primary bg-gray-100' : '')}>
                    <div className='d-flex'>
                        <div className='pr-2 flex-grow-1'>
                            <h4>Ninja Generate Questions from text</h4>
                            <div className='text-muted'>RFP Ninja determines questions that may be asked based on the provided text.</div>
                        </div>
                        <div className='text-center'>
                            <Radio checked={importType === QuestionImportType.NinjaGenerate} />
                        </div>
                    </div>
                </Card>

                <div className='text-end'>
                    <Space>
                        <Button onClick={() => props.onClose()}>
                            Cancel
                        </Button>
                        <Button type='primary' onClick={() => setCurrentStep(2)}
                            disabled={!importType || importType === QuestionImportType.NinjaGenerate}>
                            Next Step
                        </Button>
                    </Space>
                </div>
            </div>
        }

        {
            importType === QuestionImportType.ExcelUpload &&
            (currentStep === 2 || currentStep === 3) &&
            <div id="step_2">
                <Form
                    layout="vertical"
                    form={form}
                    name="MultiQuestionsLibrary"
                    autoComplete="off"
                    onFinish={onSaveAndUploadExcel}
                >
                    <h2 className='mb-10'>Upload File</h2>
                    <Row className='mb-5 h-150px'>
                        <Col md={5}>
                            <Form.Item name="datasetId" label="Dataset"
                                rules={[{ required: true, message: "'${label}' is required" }]}>
                                <Select
                                    showSearch
                                    options={datasetGroups}
                                    placeholder="Select Dataset"
                                    loading={allDatasetListState.isFetching}
                                    filterOption={filterOption}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <div className='text-end'>
                    <Space>
                    <Button
                        onClick={() => setCurrentStep(1)}>
                        Back
                    </Button>
                    <Button type='primary' hidden={currentStep === 3}
                        onClick={()=> form.submit()}>
                        Save and Upload File
                    </Button>
                    {
                        currentStep === 3 &&
                        <CSVBoxButton
                            licenseKey={process.env.REACT_APP_CSVBOX_LIBRARY_QUESTION_LICENSE_KEY || ''}
                            user={{
                                user_id: user_metadata?.userId,
                                company_id: user_metadata?.companyId,
                                import_info: JSON.stringify(form.getFieldsValue())
                            }}
                            onSubmit={(metadata) => console.log(metadata)}
                            onClose={() => props.onClose()}
                            onImport={onImportComplete}
                            render={(launch, isLoading) => {
                                if (!isLoading)
                                    launch();
                                return <Button type="primary"
                                    loading={isLoading}>
                                    Save and Upload File
                                </Button>
                            }}
                        />
                    }
                    </Space>
                </div>
            </div>
        }
    </>
    )
}

export { ImportQuestions };
