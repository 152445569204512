import { HolderOutlined } from '@ant-design/icons';
import { Collapse, CollapsePanelProps, CollapseProps } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const { Panel } = Collapse;

export const SortableDragHandle = SortableHandle(() => <HolderOutlined rev={0} />);

export const SortablePanel = SortableElement<CollapsePanelProps>((props: CollapsePanelProps) =>{
    return <Panel {...props}>{props.children}</Panel>
});

interface CustomSortableCollapseProps extends CollapseProps {
    panels: CollapsePanelProps[]
}

export const SortableCollapse = SortableContainer<CustomSortableCollapseProps>((props: CustomSortableCollapseProps) => {
    return <Collapse {...props}>
        {props.panels.map((item, index) => (
            <SortablePanel {...item} index={index}>
                {item.children}
            </SortablePanel>
        ))}
    </Collapse>
});