import { Button, Input } from 'antd';
import { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAuth } from '../core/Auth';
import { navigate2FA, supabase } from '../core/supabaseClient';
import { useNavigate } from 'react-router-dom';

export function VerifyTOTP() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [verifyCode, setVerifyCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const result2fa = await navigate2FA();
      if (!result2fa.path || !result2fa.totp)
        navigate('/auth/login');
    })();
  }, []);

  const onSubmit = async () => {
    setError('');
    setLoading(true);

    try {
      const factors = await supabase.auth.mfa.listFactors();

      if (factors.error) {
        setError(factors.error.message);
        return;
      }

      const totpFactor = factors.data.totp[0]

      if (!totpFactor) {
        setError('No TOTP factors found!');
        return;
      }

      const factorId = totpFactor.id

      const challenge = await supabase.auth.mfa.challenge({ factorId });
      if (challenge.error) {
        setError(challenge.error.message)
        return;
      }

      const challengeId = challenge.data.id

      const verify = await supabase.auth.mfa.verify({
        factorId,
        challengeId,
        code: verifyCode,
      })
      if (verify.error) {
        setError(verify.error.message);
      }
      else {
        await supabase.auth.refreshSession();
        window.location.href = '/dashboard';
      }
    }
    catch (e) {
      setError(e as string);
    }
    finally {
      setLoading(false);
    }
  }

  const redirectToLogin = async (delay: number = 0) => {
    await logout();
    setTimeout(() => {
      console.log("TOTP Logout")
      navigate('/auth/login');
    }, delay);
  }

  const onCancel = () => {
    redirectToLogin();
  }

  return (
    <>
      <div className='text-center mb-11'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/rfp-ninja-logo-transparent.png')} className='h-75px mb-10' />
        <h1 className='text-dark fw-bolder mb-3'>2 Factor Authentication</h1>
      </div>

      {error && <div className="alert alert-danger mb-5">{error}</div>}

      <label className="form-label fs-6 text-dark">Enter the code from your authenticator app</label>
      <Input value={verifyCode}
        className='mb-5'
        size='large'
        onChange={(e) => setVerifyCode(e.target.value.trim())}
        onPressEnter={onSubmit}
      />
      <div className='d-flex' style={{ gap: '8px' }}>
        <Button type='default'
          size='large'
          disabled={loading}
          onClick={onCancel}
          className='w-50'>
          Cancel
        </Button>
        <Button block type='primary'
          size='large'
          loading={loading}
          onClick={onSubmit}
          className='w-50'>
          Submit
        </Button>
      </div>

    </>
  )
}