import type { FormListOperation, UploadFile } from "antd";
import { App, Button, Form, Input, Spin, message } from "antd";
import _, { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { KTSVG } from '../../../_metronic/helpers';
import { useSaveDatasetMutation } from "../../../redux/rtkquery/CompanyApi";
import { ActionType, CompanySetupSteps, DataSetContentType } from "../../common/Enums";
import FileUpload from "../../common/components/FileUpload";
import FullScreenModal from "../../common/components/FullScreenModal";
import HtmlEditor from "../../common/components/HtmlEditor";
import { DataSetContentModel, DataSetModel } from "../../models";

type ComponentProps = {
    dataSet: DataSetModel,
    onModalClose: (dataSet?: DataSetModel | null) => void,
    dataSetType: number
};

interface DataSetPageModel {
    contentTitle: string | undefined
    contentDescription: string
    fileList: UploadFile[],
    urlList: DataSetContentModel[]
}

const AddEditDataSet: React.FC<ComponentProps> = (props) => {
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(true);
    const setupStep = _.find(CompanySetupSteps, x => x.key === props.dataSet.datasetTypeId);
    const [triggerSaveDataset] = useSaveDatasetMutation();
    const { notification } = App.useApp();
    const editorRef = useRef<any>(null);
    let datasetContentId = 0;

    const onAddUrlListItem = (params: FormListOperation) => {
        let dataContent: DataSetContentModel = {
            datasetContentId: --datasetContentId,
            datasetId: props.dataSet.datasetId,
            contentTypeId: DataSetContentType.WebUrl,
            contentDescription: '',
            action: ActionType.Insert
        };
        params.add(dataContent);
    }

    const onFinishFailed = (errorInfo: any) => {
        message.error("Please fix highlighted errors");
    }

    const onFinish = async (values: DataSetPageModel) => {
        setLoading(true);

        if (!_.trim(editorRef.current.getContent({ format: "text" }))) {
            form.setFieldValue("contentDescription", '');
            values.contentDescription = '';
        }

        let dataset = _.cloneDeep(props.dataSet);
        dataset.datasetContent.forEach(x => x.action = ActionType.Delete);

        values.contentTitle = _.trim(values.contentTitle);
        dataset.datasetName = values.contentTitle;
        values.contentDescription = _.replace(values.contentDescription, /<p><br><\/p>+/g, "");
        values.contentDescription = _.replace(values.contentDescription, /[\n\r]/g, "");
        values.contentDescription = _.trim(values.contentDescription);

        let existingContent = dataset.datasetId > 0 ?
            dataset.datasetContent.find(d => d.contentTypeId === DataSetContentType.RichText) : undefined;

        if (existingContent) {
            existingContent.action = !values.contentDescription ? ActionType.Delete :
                (_.toLower(_.trim(existingContent.contentDescription)) !== _.toLower(values.contentDescription) ? ActionType.Update : null);
            existingContent.contentDescription = values.contentDescription;

            if (!existingContent.action)
                existingContent.action = _.toLower(_.trim(existingContent.contentTitle)) !== _.toLower(values.contentTitle) ? ActionType.Update : null;
            existingContent.contentTitle = values.contentTitle;
        }
        else if (values.contentDescription) {
            dataset.datasetContent.push({
                datasetContentId: --datasetContentId,
                datasetId: dataset.datasetId,
                contentTypeId: DataSetContentType.RichText,
                contentTitle: values.contentTitle,
                contentDescription: values.contentDescription,
                action: ActionType.Insert
            });
        }

        _.forEach(values.fileList, x => {
            if (x.status === "done") {
                let datasetContentId = Number(x.uid);
                let existingFile = datasetContentId > 0 ?
                    dataset.datasetContent.find(d => d.datasetContentId === datasetContentId) : undefined;

                if (existingFile) {
                    existingFile.action = null;
                    let includeForAttachment = x.linkProps?.includeForAttachment ?? false;
                    let includeForTraining = x.linkProps?.includeForTraining ?? false;

                    if ((existingFile.includeForAttachment ?? false) !== includeForAttachment ||
                        (existingFile.includeForTraining ?? false) !== includeForTraining)
                        existingFile.action = ActionType.Update;

                    existingFile.includeForAttachment = includeForAttachment;
                    existingFile.includeForTraining = includeForTraining;
                    return;
                }

                dataset.datasetContent.push({
                    datasetContentId: datasetContentId,
                    datasetId: dataset.datasetId,
                    contentTypeId: DataSetContentType.File,
                    contentTitle: x.name,
                    contentDescription: x.fileName || '',
                    action: ActionType.Insert,
                    includeForAttachment: x.linkProps?.includeForAttachment ?? false,
                    includeForTraining: x.linkProps?.includeForTraining ?? false
                })
            }
        });

        _.forEach(values.urlList, x => {
            let existing = x.datasetContentId > 0 ?
                dataset.datasetContent.find(d => d.datasetContentId === x.datasetContentId) : undefined;
            x.contentDescription = _.toLower(_.trim(x.contentDescription));

            if (existing) {
                existing.action = _.toLower(_.trim(existing.contentDescription)) !== x.contentDescription ? ActionType.Update : null;
                existing.contentDescription = x.contentDescription;
                return;
            }

            dataset.datasetContent.push(x)
        });

        let response = await triggerSaveDataset(dataset);
        setLoading(false);

        if ('data' in response && response.data.success) {
            setIsModalOpen(false);
            props.onModalClose(cloneDeep(response.data.data));
        }

        if ('error' in response) {
            notification.error({
                message: "Save Error",
                description: _.get(response.error, "error"),
                placement: "topRight"
            });
        }
    }

    const onFileUploadComplete = (file: UploadFile, validFileForActions?: boolean) => {
        if (validFileForActions)
            file.linkProps = { includeForTraining: true };
    }

    const onCancel = () => {
        setIsModalOpen(false);
        props.onModalClose();
    }

    useEffect(() => {
        let initialDataSet = _.cloneDeep(props.dataSet);

        let _dataSet: DataSetPageModel = {
            contentTitle: initialDataSet.datasetName || '',
            contentDescription: '',
            fileList: [],
            urlList: []
        }

        _.forEach(initialDataSet.datasetContent, x => {

            switch (x.contentTypeId) {
                case DataSetContentType.RichText:
                    _dataSet.contentDescription = x.contentDescription;
                    break;
                case DataSetContentType.File:
                    _dataSet.fileList.push({
                        uid: x.datasetContentId.toString(),
                        name: x.contentTitle || "no-name",
                        status: 'done',
                        fileName: x.contentDescription,
                        linkProps: {
                            includeForAttachment: x.includeForAttachment,
                            includeForTraining: x.includeForTraining
                        }
                    })
                    break;
                case DataSetContentType.WebUrl:
                    _dataSet.urlList.push(x)
                    break;
            }
        })

        form.setFieldsValue(_dataSet);
        setLoading(false);
    }, [form])

    return (

        <FullScreenModal title={`${props.dataSet.datasetId > 0 ? 'Edit' : 'Add'} ${setupStep?.title}`}
            centered
            open={isModalOpen}
            closable={false}
            maskClosable={false}
            keyboard={false}
            width={800}
            destroyOnClose={true}
            onCancel={onCancel}
            okText="Save"
            onOk={form.submit}
        >
            <Spin spinning={loading}>
                <Form
                    layout="vertical"
                    form={form}
                    name="AddEditDataSet"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item name="contentTitle" label="Name"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="contentDescription"
                        label={`Provide a detailed information about the ${setupStep?.shortName?.toLowerCase()}, its features and benefits.`}>
                        <HtmlEditor setEditorRef={(editor: any) => editorRef.current = editor} />
                    </Form.Item>

                    <div className="ant-col ant-form-item-label">
                        <label>Provide links to webpages with detailed information about {setupStep?.shortName?.toLowerCase()}.</label>
                    </div>
                    <Form.List name="urlList">
                        {(fields, params) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div key={key} className="d-flex mb-3">
                                        <Form.Item
                                            className="flex-grow-1 me-2"
                                            {...restField}
                                            name={[name, 'contentDescription']}
                                            rules={[
                                                { required: true, message: `Please enter ${setupStep?.shortName} Web URL` },
                                                { type: 'url', message: 'Please enter a valid Web URL' },
                                                { type: 'string', min: 6, message: 'Please enter a Web URL with a valid length' }
                                            ]}
                                        >
                                            <Input placeholder={`Enter ${setupStep?.shortName?.toLowerCase()} Web URL`} />
                                        </Form.Item>
                                        <a href='#' onClick={() => params.remove(name)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr014.svg' className='svg-icon-4' />
                                        </a>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => onAddUrlListItem(params)} block>
                                        Add Web Page URL
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    <FileUpload form={form} maxCount={10} extraActions={true}
                        onUploadComplete={onFileUploadComplete}
                        label={`Upload documents with detailed information about ${setupStep?.shortName?.toLowerCase()}.`}
                    />

                </Form>
            </Spin>
        </FullScreenModal>
    )
};

export { AddEditDataSet };

