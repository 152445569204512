import { useState } from "react";
import FullScreenModal from '../../common/components/FullScreenModal';
import { LibrarySearchQuestionModel } from "../../models";
import { SearchQuestionMatch } from "./SearchQuestionMatch";

type ComponentProps = {
    onClose: (question?: LibrarySearchQuestionModel | null) => void
}

const SearchLibrary: React.FC<ComponentProps> = (props) => {
    const [selectedQuestion, setSelectedQuestion] = useState<LibrarySearchQuestionModel | null>(null);

    return (
        <FullScreenModal title="Search Library"
            open={true}
            maskClosable={false}
            keyboard={false}
            width={800}
            closable={false}
            destroyOnClose={true}
            onCancel={(e) => props.onClose()}
            okText="Select"
            onOk={() => props.onClose(selectedQuestion)}
            okButtonProps={{ disabled: !selectedQuestion }}
        >
            <SearchQuestionMatch onSelect={setSelectedQuestion} />
        </FullScreenModal>
    )
}

export { SearchLibrary };

