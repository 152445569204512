import { useState } from "react";
// @ts-ignore
import { pdfFromReact } from "generate-pdf-from-react-html";
import FullScreenModal from "../../common/components/FullScreenModal";

export const PDFDownload = ({ PdfInstance, show, closeModal, fileName }: any) => {
    const [download, setDownload] = useState(false);

    const onCancel = () => {
        setDownload(false);
        closeModal();
    }

    const onDownload = () => {
        setDownload(true);
        pdfFromReact("#pdf-to-print", fileName, "p", true, false);
        onCancel();
    }

    return (
        <FullScreenModal title={`Preview - ${fileName}`}
            className="pdf-preview"
            fullScreen={true}
            hideFullScreenBtn={true}
            open={show}
            onCancel={onCancel}
            onOk={onDownload}
            okText="Download"
            okButtonProps={{ loading: download }}
        >
            <div id="pdf-to-print">
                {PdfInstance}
            </div>
        </FullScreenModal>
    );
};