import { App, Button, Card, DatePicker, Form, Input, Select, Space, Spin, message } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { RTKBaseApi } from "../../../redux/rtkquery/BaseApi";
import { useGetCustomerSelectListQuery } from "../../../redux/rtkquery/CustomerApi";
import { useCreateProjectMutation } from "../../../redux/rtkquery/ProjectApi";
import { useGetUserSelectListQuery } from "../../../redux/rtkquery/UserApi";
import { ProjectStatus, RTKQueryTags } from "../../common/Enums";
import { ActiveUserModel, CustomerSelectModel, ProjectModel } from "../../models";
import { useAuth } from "../auth";

const AddProject: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const { user_metadata } = useAuth();
    const { notification } = App.useApp();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [userList, setUserList] = useState<ActiveUserModel[]>([]);
    const [customerList, setCustomerList] = useState<CustomerSelectModel[]>([]);
    const allCustomerListState = useGetCustomerSelectListQuery();
    const allUserListState = useGetUserSelectListQuery();
    const [triggerCreateProject, createProjectResult] = useCreateProjectMutation();
    const [project] = useState<ProjectModel>({
        projectId: 0,
        projectName: '',
        dueDate: '',
        projectOwnerId: user_metadata?.userId || null,
        approvers: [],
        team: [],
        statusId: ProjectStatus.Open
    });

    let timeout: ReturnType<typeof setTimeout> | null;

    const onFinishFailed = (errorInfo: any) => {
        message.error("Please fix highlighted errors");
    }

    const onFinish = (_project: ProjectModel) => {

        if (!_project.customerId)
            _project.customerName = customerList.find(x => x.customerId === _project.customerId)?.customerName;

        triggerCreateProject(_project);
    }

    const onCancel = () => {
        navigate('/projects');
    }

    useEffect(() => {
        setLoading(createProjectResult.isLoading);

        if (createProjectResult.requestId && !createProjectResult.isLoading) {
            let _project = createProjectResult.data?.data;

            if (_project) {

                notification.success({
                    message: "Successfull",
                    description: `Project '${_project.projectName}' added successfully`,
                    placement: "topRight"
                });
                
                dispatch(RTKBaseApi.util.invalidateTags([RTKQueryTags.CustomerSelectList]));
                navigate(`/projects/${_project.projectId}`);
                return;
            }
        }

    }, [createProjectResult]);

    useEffect(() => {
        if (!userList.length && allUserListState.data?.length) {
            setUserList(cloneDeep(allUserListState.data));
            const currentActiveUser = _.find(allUserListState.data, x => x.userId === user_metadata?.userId);
            if (currentActiveUser) {
                form.setFieldValue('team', [currentActiveUser.userId]);
            }
        }
    }, [allUserListState]);

    useEffect(() => {
        if (!customerList.length && allCustomerListState.data?.length)
            setCustomerList(cloneDeep(allCustomerListState.data));
    }, [allCustomerListState]);

    const fetchCustomer = (value: string, callback: Function) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        const filterCustomer = () => {
            let list = cloneDeep(allCustomerListState.data?.filter(x => x.customerName.toLowerCase().includes(_.trim(value).toLowerCase())) || []);
            if (!list.length)
                list.push({ customerId: 0, customerName: _.trim(value) });
            callback(list);
        }

        if (_.isNumber(value))
            return;

        if (value)
            timeout = setTimeout(filterCustomer, 300);
        else
            callback(cloneDeep(allCustomerListState.data));
    }

    const onCustomerSearch = (newValue: string) => {
        fetchCustomer(newValue, setCustomerList);
    }

    return (
        <Card title={<h2>Add Project</h2>}>
            <Spin spinning={loading}>
                <Form
                    layout="vertical"
                    form={form}
                    name="AddProject"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={project}
                    autoComplete="off"
                >
                    <Form.Item name="projectName" label="Name"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="customerId" label="Customer"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Select
                            showSearch
                            allowClear
                            placeholder="Select Customer from list or type name to add new"
                            filterOption={false}
                            onSearch={onCustomerSearch}
                            onChange={onCustomerSearch}
                            notFoundContent={null}
                            fieldNames={{ label: 'customerName', value: 'customerId' }}
                            options={customerList}
                            loading={allCustomerListState.isFetching}
                        />
                    </Form.Item>

                    <Form.Item name="dueDate" label="Due Date"
                        getValueProps={i => ({ value: i ? (i.format ? i : dayjs(i)) : undefined })}
                        normalize={value => value?.format("MM/DD/YYYY") || value}
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <DatePicker format="MM/DD/YYYY" className="w-100" />
                    </Form.Item>

                    <Form.Item name="team" label="Team">
                        <Select
                            mode="multiple"
                            fieldNames={{ label: "fullName", value: "userId" }}
                            options={userList.map(x => {
                                x.disabled = x.userId === user_metadata?.userId;
                                return x
                            })}
                            loading={allUserListState.isFetching}
                        />
                    </Form.Item>

                    <Form.Item name="approvers" label="Approvers">
                        <Select
                            mode="multiple"
                            options={allUserListState.data}
                            fieldNames={{ label: "fullName", value: "userId" }}
                            loading={allUserListState.isFetching}
                        />
                    </Form.Item>

                    <Form.Item className="text-end mb-0">
                        <Space>
                            <Button size="large" onClick={onCancel}>
                                Cancel
                            </Button>
                            <Button type="primary" size="large" htmlType="submit"
                                disabled={allCustomerListState.isFetching || allUserListState.isFetching}>
                                Create Project
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </Card>
    )
}

export { AddProject }