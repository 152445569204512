import { App, Form, Input, Select, Spin, Switch } from "antd";
import _, { cloneDeep } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useGetDatasetSelectListQuery } from "../../../redux/rtkquery/DatasetApi";
import { useSaveProductPricingMutation } from "../../../redux/rtkquery/PricingApi";
import FullScreenModal from "../../common/components/FullScreenModal";
import HtmlEditor from "../../common/components/HtmlEditor";
import { DataSetType } from "../../common/Enums";
import { ProductPricingModel } from "../../models";

type ComponentProps = {
    onModalClose: (productPricing?: ProductPricingModel | null) => void,
    productPricing: ProductPricingModel
}

const AddEditProductPricing: React.FC<ComponentProps> = (props) => {
    const productPricing = _.cloneDeep(props.productPricing);
    const [datasetName, setDatasetName] = useState("");
    const [form] = Form.useForm<ProductPricingModel>();
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [triggerSaveProductPricing, saveProductPricingResult] = useSaveProductPricingMutation();
    const { notification } = App.useApp();
    const descriptionEditorRef = useRef<any>(null),
          instructionsEditorRef = useRef<any>(null);

    const onFinishFailed = () => {
        notification.error({
            message: "Validation Error",
            description: "Please fix highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = async (values: ProductPricingModel) => {

        if (!_.trim(descriptionEditorRef.current.getContent({ format: "text" }))) {
            form.setFieldValue("description", '');
            form.validateFields();
            return;
        }

        if (!_.trim(instructionsEditorRef.current.getContent({ format: "text" }))) {
            form.setFieldValue("instructions", '');
            form.validateFields();
            return;
        }

        values.pricingName = _.trim(values.pricingName);
        values.instructions = _.trim(values.instructions);
        values.description = _.trim(values.description);

        const _productPricing = {
            ...productPricing,
            ...values
        }

        let response = await triggerSaveProductPricing(_productPricing);

        if (response && 'data' in response && response.data.success) {
            props.onModalClose(cloneDeep(response.data.data));
        }
    }

    const onDatasetChange = (_: number, dataset: any) => {
        setDatasetName(dataset.datasetName);
    }

    const productDatasets = useMemo(() => {

        // Set selected Dataset name
        if (productPricing.id > 0 && allDatasetListState.data?.length) {
            setDatasetName(_.find(allDatasetListState.data, x => x.datasetId === productPricing.datasetId)?.datasetName || "")
        }

        let productDatasets = _.filter(allDatasetListState.data || [], x => x.datasetTypeId === DataSetType.Products);
        return _.orderBy(productDatasets, x => x.datasetName);

    }, [allDatasetListState]);

    useEffect(() => {
        if (productPricing.id <= 0) {
            form.setFieldValue("datasetId", null);
        }
    }, [form])

    return (
        <FullScreenModal title={
            <h3>{productPricing.id ? 'Edit' : 'Add'} Product Pricing {datasetName ? `for ${datasetName}` : ""}</h3>
        }
            centered
            open={true}
            closable={false}
            maskClosable={false}
            keyboard={false}
            width={800}
            destroyOnClose={true}
            onCancel={() => props.onModalClose()}
            okText="Save"
            onOk={form.submit}
            confirmLoading={saveProductPricingResult.isLoading}
        >
            <Spin spinning={saveProductPricingResult.isLoading}>
                <Form
                    layout="vertical"
                    form={form}
                    name="AddEditProductPricing"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={productPricing}
                >
                    <div className="text-gray-700 fs-6 fw-normal text-wrap">
                        <p className="mb-1">Provide Price instructions for the base price for the product.  Avoid including prices for add-ons or surcharges - those should be added using the "Surcharges" functionality after saving this price.</p>
                        <p>The pricing can be provided in any format. Simple to understand formats, such as a table or step by step directions work best.</p>
                    </div>

                    <Row>
                        <Col sm={10}>
                            <Form.Item name="pricingName" label="Name"
                                rules={[{ required: true, message: "'${label}' is required" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col sm={2}>
                            <Form.Item name="isActive" label="Active" valuePropName="checked">
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Form.Item name="datasetId" label="Product"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Select
                            showSearch
                            options={productDatasets}
                            placeholder="Select Product"
                            loading={allDatasetListState.isFetching}
                            onChange={onDatasetChange}
                            fieldNames={{label: 'datasetName', value: 'datasetId'}}
                            filterOption={(input, option) =>
                                (option?.datasetName ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>

                    <Form.Item name="description" label="Description"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <HtmlEditor setEditorRef={(editor: any) => descriptionEditorRef.current = editor} 
                            placeholder={`Provide any general directions for calculating prices for your products.\nFor example, assumptions to make, format for the desired output, etc. Note, these instructions will be applied across all prices.`}
                        />
                    </Form.Item>

                    <Form.Item name="instructions" label="Pricing Instructions"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <HtmlEditor setEditorRef={(editor: any) => instructionsEditorRef.current = editor} />
                    </Form.Item>

                </Form>
            </Spin>
        </FullScreenModal>
    )
};

export { AddEditProductPricing };

