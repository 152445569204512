import { useEffect, useRef, useState } from "react";

type ClipboardParams = {
  copiedText: string, 
  copiedHTML?: string, 
  fontFamily?: string
}

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);
  const timeoutRef = useRef<any>(null);

  const resetCopied = () => {
    setIsCopied(false);
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }

  useEffect(() => {
    return resetCopied;
  }, []);

  const copyToClipboard = async (params: ClipboardParams) => {
    try {
      
      const typeHtml = "text/html", typePlain = "text/plain";
      const fontFamily = params.fontFamily !== null ? (params.fontFamily ?? "Arial") : null;
      const copiedHTML = params.copiedHTML ? (fontFamily ? `<div style='font-family:${fontFamily}'>${params.copiedHTML}</div>` : params.copiedHTML) : params.copiedText;
      const blobHTML = new Blob([copiedHTML], { type: typeHtml });
      const blobText = new Blob([params.copiedText], { type: typePlain });

      const data = [new ClipboardItem({
        [typePlain]: blobText,
        [typeHtml]: blobHTML,
      })];

      navigator.clipboard.write(data).then(() => {
        setIsCopied(true);
        timeoutRef.current = setTimeout(resetCopied, 5000);
      })
    } catch (error) {
      setIsCopied(false);
      console.error('Unable to copy to clipboard:', error);
    }
  };

  return { isCopied, copyToClipboard, resetCopied };
}

export default useCopyToClipboard;