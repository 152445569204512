import { DeleteFilled, EditOutlined, ExclamationCircleTwoTone, FileSearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { usePrevious } from "@uidotdev/usehooks";
import { Alert, Button, Card, Form, Input, List, Modal, Popconfirm, Popover, Skeleton, Space, Spin, StepProps, Steps, Tag, Tooltip, UploadFile } from "antd";
import _, { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card as RCard } from 'react-bootstrap';
import { selectMasterDataDictByType } from "../../../redux/MasterDataSlice";
import { deleteAnswer, replaceAnswers, saveAnswer, selectAnswers, selectProjectParamsByKey, selectProjectSettings, selectQuestion, updateQuestion } from "../../../redux/ProjectsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useComposeAnswerMutation, useDeleteAnswerMutation, useRequestChangesAnswerMutation, useSaveAnswerMutation, useSaveAttachmentMutation, useSelectAnswerMutation, useUpdateQuestionStatusMutation } from "../../../redux/rtkquery/QuestionApi";
import { getFormattedDate } from '../../common/CommonFunctions';
import { CONST_DATETIME_DB_FORMAT, CONST_DATETIME_UI_FORMAT } from '../../common/Constants';
import { ActionType, AddToLibraryStatus, AnswerJobStatus, AnswerSourceType, QuestionStatus, QuestionStatusDetails, QuestionUserRole } from "../../common/Enums";
import AttachmentTag from '../../common/components/AttachmentTag';
import FileUpload from '../../common/components/FileUpload';
import FullScreenModal from '../../common/components/FullScreenModal';
import HTMLContent from '../../common/components/HtmlContent';
import HtmlEditor from '../../common/components/HtmlEditor';
import { APIResponseModel, AnswerModel, AttachmentModel, LibrarySearchQuestionModel, QuestionModel } from "../../models";
import { RecommendToLibrary } from '../library/RecommendToLibrary';
import { SearchLibrary } from '../library/SearchLibrary';
const { confirm } = Modal;

type AnswerGroupType = {
  [answerNo: number]: {
    suggested: AnswerModel[],
    selected: AnswerModel | null
  }
}

export const AnswersRenderer: React.FC<{ sectionId: number, questionId: number }> = (props) => {
  const dispatch = useAppDispatch();
  const question = useAppSelector(state => selectQuestion(state, props.sectionId, props.questionId));
  const answers = useAppSelector(state => selectAnswers(state, props.questionId));
  const answerSourceTypeDict = useAppSelector(state => selectMasterDataDictByType(state, "AnswerSourceType"));
  const responseFormatsDict = useAppSelector(state => selectMasterDataDictByType(state, "ResponseFormat"));
  const projectSettings = useAppSelector(state => selectProjectSettings(state));
  const [triggerDeleteAnswer, deleteAnswerResult] = useDeleteAnswerMutation();
  const [triggerSelectAnswer, selectAnswerResult] = useSelectAnswerMutation();
  const [triggerUpdateQuestionStatus, updateQuestionStatusResult] = useUpdateQuestionStatusMutation();
  const [triggerRequestChanges, requestChangesResult] = useRequestChangesAnswerMutation();
  const [triggerSaveAttachment] = useSaveAttachmentMutation();
  const [answerGroup, setAnswerGroup] = useState<AnswerGroupType>({});
  const [editingAnswer, setEditingAnswer] = useState<AnswerModel | null>(null);
  const [searchLibraryAnswerNo, setSearchLibraryAnswerNo] = useState(0);
  const [addToLibraryAnswerModal, setAddToLibraryAnswerModal] = useState<boolean>(false);
  const [questionAccessRole, setQuestionAccessRole] = useState(QuestionUserRole.TeamMember);
  const prevQuestion = usePrevious(question);
  const [isLoading, setIsLoading] = useState(true);
  const propLayoutOption = useAppSelector(state => selectProjectParamsByKey(state, "layoutOption"));

  const QuestionStatuses = useMemo(() => _.transform(QuestionStatusDetails, (r: StepProps[], v) => {
    if (v.value === QuestionStatus.RequestChanges && question.statusId !== QuestionStatus.RequestChanges)
      return;

    let step: StepProps = {
      title: v.name,
      status: question.statusId >= v.value ? 'finish' : 'wait'
    };

    if (v.value === QuestionStatus.RequestChanges && question.statusId === QuestionStatus.RequestChanges)
      step.icon = <ExclamationCircleTwoTone rev={0} twoToneColor="#ffcc80" />

    r.push(step)
  }, []), [question.statusId]);

  const hasAccess = useCallback((role?: QuestionUserRole) => {
    return projectSettings?.isProjectOpen && (projectSettings?.hasFullAccess ||
      (role ? question.userAccessRole === role :
        (question.userAccessRole === QuestionUserRole.Approver || question.userAccessRole === QuestionUserRole.Assignee)))
  }, [projectSettings, question]);

  const hasAllAnswered = useMemo(() => {
    let allAnswered = true;

    _.forOwn(answerGroup, group => {
      if (!group.selected)
        allAnswered = false;
    });

    return allAnswered;
  }, [answerGroup]);

  const onDiscard = async (answer: AnswerModel | null) => {
    if (answer) {
      let response = await triggerDeleteAnswer({
        projectId: question.projectId,
        answerId: answer.answerId,
        questionId: answer.questionId
      });

      if ("error" in response || !response.data.success) {
        return;
      }

      // Delete from Store
      dispatch(deleteAnswer(answer));

      // Delete from State
      if (answer.isSelected)
        answerGroup[answer.answerNo].selected = null;
      else
        _.remove(answerGroup[answer.answerNo].suggested, x => x.answerId === answer.answerId);

      setAnswerGroup({ ...answerGroup });
    }
  }

  const onSelect = async (answer: AnswerModel) => {
    triggerSelectAnswer({
      projectId: question.projectId,
      questionId: answer.questionId,
      answerId: answer.answerId,
      answerNo: answer.answerNo
    }).then(response => {
      if ('data' in response && response.data.success) {
        let _answers = response.data.data?.answers || [];

        if (_answers.length) {

          dispatch(replaceAnswers({
            answers: _answers,
            documents: response.data.data?.documents || [],
            questionStatus: QuestionStatus.Drafted
          }));

          // Set loading so updated answers can be reflected
          setIsLoading(true);
        }
      }
    })
  }

  const onEditAnswerClose = (updated?: boolean) => {
    if (updated) {
      // Set loading so updated answers can be reflected
      setIsLoading(true);
    }

    setEditingAnswer(null);
  }

  const onSubmitAnswer = () => {
    updateStatus(QuestionStatus.Submitted);
  }

  const onAcceptAnswer = async () => {
    updateStatus(QuestionStatus.Accepted);
  }

  const onReopenAcceptedAnswer = () => {
    updateStatus(QuestionStatus.Submitted);
  }

  const onRequestChanges = (answerNo: number) => {
    let answer = answerGroup[answerNo].selected;
    let comment = _.trim(answer?.comment || '');

    confirm({
      title: 'Request Changes',
      icon: null,
      okText: 'Submit',
      cancelText: 'Cancel',
      cancelButtonProps: { disabled: requestChangesResult.isLoading },
      width: 600,
      content: <>
        <div className='mb-2'>Ask Assignee to make suggested changes:</div>
        <Input.TextArea rows={4}
          defaultValue={comment}
          className='w-100'
          placeholder='Enter Comments (Optional)'
          onChange={input => comment = input.target.value}
        />
      </>,
      async onOk() {
        if (answer) {
          comment = _.trimEnd(comment, '\n');
          let response = await triggerRequestChanges({
            projectId: question.projectId,
            questionId: question.questionId,
            answerId: answer.answerId,
            comment
          });

          if ('data' in response && response.data.success && response.data.data) {

            let answer = cloneDeep(response.data.data);

            dispatch(saveAnswer({
              answer,
              questionStatus: QuestionStatus.RequestChanges
            }));

            // Set loading so updated answers can be reflected
            setIsLoading(true);

          }
        }
      }
    })
  }

  const updateStatus = async (statusId: QuestionStatus) => {
    let response = await triggerUpdateQuestionStatus({
      projectId: question.projectId,
      questionIds: [question.questionId],
      statusId: statusId
    });

    let success = false;

    if ("data" in response && response.data.success && response.data.data) {
      success = true;
      let _question = cloneDeep(question);
      _question.statusId = response.data.data[0].statusId;

      dispatch(updateQuestion(_question));
    }

    return success;
  }

  const onComposeAnswer = (answerNo: number) => {
    setEditingAnswer({
      answerId: 0,
      answer: '',
      answerNo,
      questionId: question.questionId,
      sectionId: question.sectionId,
      projectId: question.projectId,
      sourceTypeId: AnswerSourceType.Composed
    });
  }

  const onEditAnswer = (answer: AnswerModel | null) => {
    answer && setEditingAnswer(answer);
  }

  const setAnswers = (answers: AnswerModel[]) => {
    let answersDict = _.groupBy(cloneDeep(answers), x => x.answerNo);
    let _answerGroup: AnswerGroupType = {}

    _.forOwn(answersDict, (_answers, k) => {
      let selected = _.remove(_answers, x => x.isSelected)[0];
      _answerGroup[Number(k)] = { suggested: _answers, selected: selected || null };
    });

    setAnswerGroup({ ..._answerGroup });
  }

  const onSearchLibraryClose = (_question?: LibrarySearchQuestionModel | null) => {
    if (_question) {
      setEditingAnswer({
        answerId: 0,
        answer: _question.answer,
        answerNo: searchLibraryAnswerNo,
        questionId: question.questionId,
        sectionId: question.sectionId,
        projectId: question.projectId,
        sourceTypeId: AnswerSourceType.LibraryGenerated,
        libraryQuestionId: _question.library_question_id,
        statusId: question.statusId === QuestionStatus.Unanswered ? QuestionStatus.Drafted : question.statusId
      });
    }

    setSearchLibraryAnswerNo(0);
  }

  const onAddToLibraryClose = (libraryStatusId?: AddToLibraryStatus | null) => {
    setAddToLibraryAnswerModal(false);

    if (libraryStatusId) {
      let _question = cloneDeep(question);
      _question.libraryStatusId = libraryStatusId;

      dispatch(updateQuestion(_question));
    }
  }

  const onFileUploadComplete = (file: UploadFile<APIResponseModel<any>>, answerNo: number) => {
    let answer = answerGroup[answerNo].selected;

    if (answer) {
      answer.documents = answer.documents || [];
      answer.documents?.push({
        answerId: answer.answerId,
        attachmentId: file.response?.data.attachmentId,
        fileDisplayName: file.name,
        fileName: file.fileName || '',
        questionId: answer.questionId,
        action: null
      });

      dispatch(saveAnswer({
        answer,
        questionStatus: question.statusId,
        replaceDocs: true
      }));

      setAnswerGroup({ ...answerGroup });
    }
  }

  const onFileRemove = async (file: AttachmentModel, answerNo: number) => {
    let formData = new FormData();
    formData.append("action", ActionType.Delete);
    formData.append("attachmentId", file.attachmentId.toString());
    formData.append("answerId", (file.answerId || '0').toString());
    formData.append("questionId", file.questionId.toString());

    let response = await triggerSaveAttachment(formData);

    if ('data' in response && response.data.success) {
      let answer = answerGroup[answerNo].selected;

      if (answer && answer.documents) {
        _.remove(answer.documents, x => x.attachmentId === file.attachmentId);

        dispatch(saveAnswer({
          answer,
          questionStatus: question.statusId,
          replaceDocs: true
        }));

      }
    }

    setAnswerGroup({ ...answerGroup });
  }

  useEffect(() => {
    let _answerGroup: AnswerGroupType = {};
    _.forEach(question.responseFormat, (r, i) => {
      _answerGroup[i + 1] = { suggested: [], selected: null }
    });
    setAnswerGroup(_answerGroup);
  }, []);

  // Set answers fetched through polling on first load
  useEffect(() => {
    if (isLoading && !_.isNil(answers)) {
      setAnswers(answers);
      setIsLoading(false);
    }
  }, [answers, isLoading]);

  useEffect(() => {
    if (projectSettings?.hasFullAccess)
      setQuestionAccessRole(QuestionUserRole.Owner)
    else if (question.userAccessRole === QuestionUserRole.Approver)
      setQuestionAccessRole(QuestionUserRole.Approver)
    else if (question.userAccessRole === QuestionUserRole.Assignee)
      setQuestionAccessRole(QuestionUserRole.Assignee)
    else
      setQuestionAccessRole(QuestionUserRole.TeamMember)
  }, [question, projectSettings]);

  //Replace Answers when Generate answers processing completed
  useEffect(() => {
    if (prevQuestion?.answerJobStatus === AnswerJobStatus.Processing && question.answerJobStatus === AnswerJobStatus.Idle) {
      setAnswers(answers);
    }
    else if(prevQuestion?.statusId !== question.statusId){
      setAnswers(answers);
    }
  }, [question]);

  return (<>
    <RCard>
      <RCard.Body className='p-0'>
        <Steps
          className='p-2 bg-gray-100'
          size='small'
          items={QuestionStatuses}
        />

        {
          isLoading ? <Card loading /> :
            <>
              {
                question.answerJobStatus === AnswerJobStatus.Processing &&
                <div className="my-3 px-4 d-flex align-items-end">
                  <div className="flex-grow-1">
                    Generating responses...
                    <Skeleton.Input active block size="small" style={{ height: '10px' }} />
                  </div>
                </div>
              }
              {
                _.transform(question.responseFormat, (nodes: React.ReactNode[], r, i) => {
                  let group = answerGroup[i + 1];

                  group && nodes.push(
                    <RCard key={i} className='m-3 mt-4 shadow-sm border border-gray-300 rounded-0'>
                      <RCard.Header className='px-5 py-3 d-block border-bottom border-2 border-secondary rounded-0' style={{ minHeight: 'unset' }}>
                        <h5 className='mb-0'>Format: <span className='fw-normal'>{responseFormatsDict[r.responseFormatId]?.name}</span></h5>
                      </RCard.Header>
                      <RCard.Body className='p-5'>
                        {
                          group.suggested.length > 0 &&
                          <List
                            itemLayout="horizontal"
                            dataSource={group.suggested}
                            renderItem={(item, index) => (
                              <List.Item>
                                <List.Item.Meta
                                  title={
                                    <div className="d-flex justify-content-between">
                                      {
                                        !_.isNil(item.sourceTypeId) &&
                                        <Tag color="blue">
                                          {answerSourceTypeDict[item.sourceTypeId]?.name}
                                        </Tag>
                                      }
                                      {
                                        projectSettings?.isProjectOpen && questionAccessRole !== QuestionUserRole.TeamMember &&
                                        <Space.Compact>
                                          <Button size='small'
                                            onClick={() => onSelect(item)}
                                            disabled={selectAnswerResult.isLoading || deleteAnswerResult.isLoading}>
                                            Use
                                          </Button>
                                          <Popconfirm
                                            title="Discard"
                                            description="Are you sure to discard this answer?"
                                            onConfirm={() => onDiscard(item)}
                                            okText="Yes"
                                            cancelText="No"
                                            disabled={selectAnswerResult.isLoading || deleteAnswerResult.isLoading}
                                          >
                                            <Button size='small' disabled={selectAnswerResult.isLoading || deleteAnswerResult.isLoading}>
                                              Discard
                                            </Button>
                                          </Popconfirm>
                                        </Space.Compact>
                                      }
                                    </div>
                                  }
                                  description={
                                    <>
                                      {
                                        item.sourceTypeId === AnswerSourceType.LibraryGenerated && item.libraryQuestion &&
                                        <div className='bg-gray-100 px-2 py-1 fs-8 border-start border-5 border-secondary mb-1'>
                                          <p className='fw-bold mb-1'>Matched Library Question</p>
                                          {item.libraryQuestion}
                                        </div>
                                      }
                                      <HTMLContent content={item.answer} lines={2} className='flex-grow-1' defaultExpanded={propLayoutOption ? true : false} />
                                      {
                                        _.map(item.documents, x => (
                                          <AttachmentTag key={x.attachmentId} style={{ maxWidth: '200px' }}
                                            fileName={x.fileDisplayName} fileGuid={x.fileName} />
                                        ))
                                      }
                                    </>
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        }
                        {
                          group.selected !== null &&
                          <>
                            <div className="p-2 mb-2 border-start border-5 border-secondary bg-gray-100">
                              <div className='d-flex'>
                                <HTMLContent content={group.selected.answer || ''} lines={5} className='flex-grow-1' defaultExpanded={propLayoutOption ? true : false} />
                                {
                                  question.statusId !== QuestionStatus.Accepted && hasAccess() &&
                                  <Popconfirm
                                    title="Delete Answer"
                                    placement='topLeft'
                                    description={<span>Are you sure to delete this answer?</span>}
                                    onConfirm={() => onDiscard(group.selected)}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button size='small' type='text'
                                      className='text-muted'
                                      icon={<DeleteFilled rev={0} />}
                                      title='Delete Answer' />
                                  </Popconfirm>
                                }
                              </div>
                            </div>
                            <div className='d-flex'>
                              <div>
                                {
                                  !_.isNil(group.selected.sourceTypeId) &&
                                  <Tag color="blue">
                                    {answerSourceTypeDict[group.selected.sourceTypeId]?.name}
                                    {
                                      group.selected.sourceTypeId === AnswerSourceType.LibraryGenerated && group.selected.libraryQuestion &&
                                      <Popover content={group.selected.libraryQuestion} title="Matched Library Question">
                                        <InfoCircleOutlined rev={0} className='ps-1' />
                                      </Popover>
                                    }
                                  </Tag>
                                }
                              </div>
                              <div className="fs-7 text-gray-700 ms-auto">
                                Last updated by : <span className="fw-bold">{group.selected?.modifiedBy} </span>
                                on {getFormattedDate(group.selected?.modifiedDate || "", CONST_DATETIME_DB_FORMAT)}
                              </div>
                            </div>
                            <div className='mt-3'>
                              {
                                question.statusId !== QuestionStatus.Accepted && hasAccess() &&
                                <div className='mb-2'>
                                  <FileUpload compact={true}
                                    label='Attachments'
                                    maxCount={Number.MAX_SAFE_INTEGER}
                                    keepUploadedFiles={false}
                                    uploadUrl='question/save_attachment'
                                    allowedFileTypes={[".docx", ".pdf", ".txt", ".xlsx", ".pptx", ".jpg", ".jpeg", ".png"]}
                                    onUploadComplete={(file) => onFileUploadComplete(file, group.selected?.answerNo || 0)}
                                    data={{
                                      questionId: group.selected.questionId,
                                      answerId: group.selected.answerId,
                                      action: ActionType.Insert
                                    }}
                                  />
                                </div>
                              }
                              {
                                _.size(group.selected.documents) > 0 ?
                                  _.map(group.selected.documents, x => (
                                    <AttachmentTag key={x.attachmentId}
                                      fileName={x.fileDisplayName}
                                      fileGuid={x.fileName}
                                      closable={question.statusId !== QuestionStatus.Accepted && hasAccess()}
                                      onClose={() => onFileRemove(x, group.selected?.answerNo || 0)}
                                    />
                                  )) : null
                              }
                            </div>
                            {
                              _.trim(group.selected.comment || '') !== "" && question.statusId !== QuestionStatus.Accepted &&
                              <Alert className='mt-2 p-3 d-linebreak' description={
                                <>
                                  <div className='mb-3'>{group.selected.comment}</div>
                                  <div className='text-end fs-7 text-gray-700'>
                                    <span>By: </span>
                                    <span className="fw-bold">{group.selected.commentBy} </span>
                                    on {getFormattedDate(group.selected.commentDate || "", CONST_DATETIME_UI_FORMAT)}
                                  </div>
                                </>
                              } type="warning" showIcon />
                            }
                          </>
                        }
                        {
                          group.selected === null &&
                          <div className='border border-secondary py-4 mt-2 text-center'>
                            <Button type="primary"
                              icon={<EditOutlined rev={0} />}
                              onClick={() => onComposeAnswer(i + 1)}
                              disabled={selectAnswerResult.isLoading || deleteAnswerResult.isLoading}>
                              Compose Answer
                            </Button>
                          </div>
                        }
                      </RCard.Body>
                      {
                        question.statusId !== QuestionStatus.Accepted && hasAccess() &&
                        <RCard.Footer className='px-5 py-3'>
                          <div className="d-flex justify-content-between">
                            <div>
                              {
                                question.statusId !== QuestionStatus.Accepted &&
                                <Button size='small' icon={<FileSearchOutlined rev={0} />}
                                  onClick={() => setSearchLibraryAnswerNo(i + 1)}
                                  disabled={updateQuestionStatusResult.isLoading}
                                >
                                  Search Library
                                </Button>
                              }
                            </div>
                            {
                              group.selected !== null &&
                              <Space>
                                {
                                  questionAccessRole === QuestionUserRole.Assignee &&
                                  question.statusId !== QuestionStatus.Accepted &&
                                  <Button size='small'
                                    onClick={() => onEditAnswer(group.selected)}
                                    disabled={updateQuestionStatusResult.isLoading}>
                                    Edit Answer
                                  </Button>
                                }
                                {
                                  (questionAccessRole === QuestionUserRole.Approver ||
                                    questionAccessRole === QuestionUserRole.Owner) &&
                                  <>
                                    {
                                      (question.statusId === QuestionStatus.Submitted ||
                                        question.statusId === QuestionStatus.RequestChanges) &&
                                      <Button size='small' type='primary' ghost
                                        onClick={() => onRequestChanges(group.selected?.answerNo || 0)}
                                        disabled={updateQuestionStatusResult.isLoading}>
                                        Request Changes
                                      </Button>
                                    }
                                    {
                                      question.statusId !== QuestionStatus.Accepted &&
                                      <Button size='small'
                                        onClick={() => onEditAnswer(group.selected)}
                                        disabled={updateQuestionStatusResult.isLoading}>
                                        Edit Answer
                                      </Button>
                                    }
                                  </>
                                }
                              </Space>
                            }
                          </div>
                        </RCard.Footer>
                      }
                    </RCard>)
                }, []
                )
              }
            </>
        }
      </RCard.Body>
      {
        !isLoading && hasAccess() &&
        <RCard.Footer className='px-5 py-3 bg-gray-100'>
          <div className="text-end">
            <Space>
              {
                (question.statusId === QuestionStatus.Unanswered || question.statusId === QuestionStatus.Drafted) &&
                ((questionAccessRole === QuestionUserRole.Owner &&
                  (_.size(question.approvers) > 0 && _.indexOf(question.approvers, projectSettings?.currentUser || 0) < 0)) || // Owner but not approver
                  questionAccessRole === QuestionUserRole.Assignee) &&
                <Button type="primary" onClick={onSubmitAnswer}
                  disabled={!hasAllAnswered || updateQuestionStatusResult.isLoading}
                  loading={updateQuestionStatusResult.isLoading &&
                    updateQuestionStatusResult.originalArgs?.statusId === QuestionStatus.Submitted}>
                  Submit Answer
                </Button>
              }
              {
                (questionAccessRole === QuestionUserRole.Approver ||
                  questionAccessRole === QuestionUserRole.Owner) &&
                <>
                  {
                    question.statusId !== QuestionStatus.Accepted &&
                    ((questionAccessRole === QuestionUserRole.Owner && _.size(question.approvers) === 0) || // Owner but no any approvers
                      (_.indexOf(question.approvers, projectSettings?.currentUser || 0) >= 0)) && // Owner but as approver
                    <Button type="primary" onClick={onAcceptAnswer}
                      disabled={!hasAllAnswered || updateQuestionStatusResult.isLoading}
                      loading={updateQuestionStatusResult.isLoading &&
                        updateQuestionStatusResult.originalArgs?.statusId === QuestionStatus.Accepted}>
                      Accept Answer
                    </Button>
                  }
                  {
                    question.statusId === QuestionStatus.Accepted &&
                    <Button onClick={onReopenAcceptedAnswer}
                      disabled={!hasAllAnswered || updateQuestionStatusResult.isLoading}
                      loading={updateQuestionStatusResult.isLoading &&
                        updateQuestionStatusResult.originalArgs?.statusId === QuestionStatus.Submitted}>
                      Revise Response
                    </Button>
                  }

                  {
                    (question.statusId === QuestionStatus.Accepted ||
                      question.statusId === QuestionStatus.Submitted) &&
                    <Tooltip title={"Add response to Library" +
                      (question.libraryStatusId === AddToLibraryStatus.Pending ? ' - Request Pending' :
                        question.libraryStatusId === AddToLibraryStatus.Rejected ? ' - Request Rejected' :
                          question.libraryStatusId === AddToLibraryStatus.Accepted ? ' - Request Accepted' : '')
                    }>
                      <i onClick={() => setAddToLibraryAnswerModal(true)}
                        className={
                          "cursor-pointer fs-2x bi bi-journal-bookmark-fill " +
                          (question.libraryStatusId === AddToLibraryStatus.Pending ? 'text-warning' :
                            question.libraryStatusId === AddToLibraryStatus.Rejected ? 'text-danger' :
                              question.libraryStatusId === AddToLibraryStatus.Accepted ? 'text-success' : 'text-primary')
                        }></i>
                    </Tooltip>
                  }

                </>
              }
            </Space>
          </div>
        </RCard.Footer>
      }
    </RCard>
    {
      editingAnswer !== null &&
      <AnswerEditRenderer question={question}
        accessRole={questionAccessRole}
        answer={editingAnswer}
        onClose={onEditAnswerClose}
      />
    }
    {
      searchLibraryAnswerNo > 0 && <SearchLibrary onClose={onSearchLibraryClose} />
    }
    {
      addToLibraryAnswerModal &&
      <RecommendToLibrary question={question}
        answers={_.transform(answerGroup, (r: {answer: string[], files: AttachmentModel[]}, v) => {
          if(v.selected?.answer)
            r.answer.push(v.selected?.answer || '');
          if(v.selected?.documents?.length)
            r.files.push(...v.selected.documents);
        }, {answer: [] as string[], files: [] as AttachmentModel[]})}
        onClose={onAddToLibraryClose}
      />
    }
  </>)
}

export const AnswerEditRenderer: React.FC<{
  question: QuestionModel,
  answer: AnswerModel,
  accessRole: QuestionUserRole,
  onClose: (updated?: boolean) => void
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<AnswerModel>();
  const [triggerSaveAnswer, saveAnswerResult] = useSaveAnswerMutation();
  const [triggerComposeAnswer, composeAnswerResult] = useComposeAnswerMutation();
  const dispatch = useAppDispatch();
  const editorRef = useRef<any>(null);

  const onFinish = (values: AnswerModel) => {

    if (!_.trim(editorRef.current.getContent({ format: "text" }))) {
      form.setFieldValue("answer", null);
      form.validateFields();
      return;
    }

    values.answer = _.replace(values.answer, /<p><br><\/p>+/g, "");
    values.answer = _.replace(values.answer, /[\n\r]/g, "");

    // Case: Compose
    if (props.answer.answerId === 0) {
      const _answer = cloneDeep(props.answer);
      _answer.statusId = props.question.statusId === QuestionStatus.Unanswered ? QuestionStatus.Drafted : props.question.statusId;
      _answer.answer = values.answer;

      _answer.sourceTypeId = _answer.sourceTypeId || AnswerSourceType.Composed;

      triggerComposeAnswer(_answer);
    }
    else {
      // Case: Update
      triggerSaveAnswer({
        projectId: props.question.projectId,
        questionId: props.question.questionId,
        answerId: props.answer.answerId,
        answer: values.answer,
        statusId: props.question.statusId
      })
    }
  }

  useEffect(() => {
    setLoading(saveAnswerResult.isLoading);

    if (saveAnswerResult.requestId && !saveAnswerResult.isLoading) {

      if (saveAnswerResult.isError) {
        return;
      }

      if (saveAnswerResult.data?.success && saveAnswerResult.data.data) {
        let answer = cloneDeep(saveAnswerResult.data.data);

        dispatch(saveAnswer({
          answer,
          questionStatus: props.question.statusId === QuestionStatus.Unanswered ? QuestionStatus.Drafted : props.question.statusId
        }));

        props.onClose(true);
      }
    }

  }, [saveAnswerResult]);

  useEffect(() => {
    setLoading(composeAnswerResult.isLoading);

    if (composeAnswerResult.requestId && !composeAnswerResult.isLoading) {

      if (composeAnswerResult.isError) {
        return;
      }

      if (composeAnswerResult.data?.success && composeAnswerResult.data.data) {
        let response = cloneDeep(composeAnswerResult.data.data);

        dispatch(replaceAnswers({
          answers: cloneDeep(response.answers || []),
          documents: cloneDeep(response.documents || []),
          questionStatus: props.question.statusId === QuestionStatus.Unanswered ? QuestionStatus.Drafted : props.question.statusId
        }));

        props.onClose(true);
      }
    }

  }, [composeAnswerResult]);

  return (

    <FullScreenModal title={props.answer.answerId > 0 ? 'Edit Response' : 'Add Response'}
      open={true}
      maskClosable={false}
      keyboard={false}
      closable={false}
      width={800}
      destroyOnClose={true}
      onCancel={(e) => props.onClose()}
      cancelButtonProps={{ disabled: loading }}
      onOk={form.submit}
      okText="Save Changes"
      okButtonProps={{ loading: saveAnswerResult.isLoading || composeAnswerResult.isLoading }}>

      <Spin spinning={loading}>

        <div className="border-start border-5 border-secondary px-5 py-3 bg-gray-100 mb-4 fs-3">
          <span>{props.question.question}</span>
        </div>

        <Form
          layout="vertical"
          form={form}
          name="EditAnswer"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={props.answer}
        >
          <Form.Item name="answer" label='Response'
            rules={[{ required: true, message: "'${label}' is required" }]}>
            <HtmlEditor setEditorRef={(editor: any) => editorRef.current = editor} />
          </Form.Item>
        </Form>
      </Spin>
    </FullScreenModal>
  )
}