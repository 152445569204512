import { App, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { RTKBaseApi } from '../../../redux/rtkquery/BaseApi';
import { useRebuildIndexMutation } from '../../../redux/rtkquery/CompanyApi';
import { RTKQueryTags } from '../../common/Enums';
import _ from 'lodash';

const RebuildIndex: React.FC<{ isIndexing: boolean }> = ({ isIndexing }) => {
    const [triggerRebuildIndex, indexResult] = useRebuildIndexMutation();
    const { notification } = App.useApp();
    const dispatch = useAppDispatch();
    const [indexStarted, setIndexStarted] = useState(false);

    const onRebuildIndex = () => {
        triggerRebuildIndex();
    }

    useEffect(() => {
        if (indexResult.requestId && !indexResult.isLoading) {
            if (indexResult.data?.success) {
                setIndexStarted(true);
                dispatch(RTKBaseApi.util.invalidateTags([RTKQueryTags.CompanySetupConfig]));

                notification.success({
                    message: "Successfull",
                    description: "Re-building index started",
                    placement: "topRight"
                })

                return;
            }

            if (indexResult.isError || !indexResult.data?.success) {
                notification.error({
                    message: "Save Error",
                    description: _.get(indexResult.error, "error"),
                    placement: "topRight"
                });
            }
        }
    }, [indexResult]);

    return (
        <>
            <p>Rebuild the Ninja Generator model if you have made changes to your company's information. This typically takes a few minutes.</p>
            <strong>Important</strong>
            <ol>
                <li>This will stop any Ninja Generator questions currently underway.</li>
                <li>The generated responses will change.  Any previously generated responses may not be reproduced.    </li>
            </ol>
            <Button type="primary"
                disabled={isIndexing || indexStarted}
                onClick={onRebuildIndex}
                loading={indexResult.isLoading}>
                Rebuild Index
            </Button>
        </>
    )
};

export { RebuildIndex };
