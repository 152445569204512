import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DashboardFilterModel } from '../app/models';
import { RootState } from './store';

interface SliceDataType {
    [type: string]: DashboardFilterModel | null
}

interface SliceState {
    filters: SliceDataType
}

const initialState: SliceState = {
    filters: {}
}

export const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setFilters(state, { payload }: PayloadAction<{ type: string, filters: DashboardFilterModel }>) {
            state.filters[payload.type] = payload.filters
        }
    },
})

export const selectFilters = (state: RootState, type: string) => state.dashboardData.filters[type] || null;

export const { setFilters } = DashboardSlice.actions;

export default DashboardSlice.reducer;
