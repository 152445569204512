import { ArrowLeftOutlined, CopyOutlined, DeleteOutlined, DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import { App, Button, Card, Form, Input, Popconfirm, Space, Spin, Switch, TableColumnsType } from "antd";
import Table from 'antd/es/table';
import _, { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    useCopyProductPricingMutation,
    useDeletePricingSurchargeMutation,
    useDeleteProductPricingMutation,
    useLazyGetPricingInstructionQuery, useSavePricingInstructionMutation,
    useUpdatePricingSurchargeStatusMutation,
    useUpdateProductPricingStatusMutation
} from '../../../redux/rtkquery/PricingApi';
import HtmlEditor from '../../common/components/HtmlEditor';
import { PricingInstructionModel, PricingSurchargeModel, ProductPricingModel } from '../../models';
import { AddEditProductPricing } from './AddEditProductPricing';
import { AddEditSurcharge } from './AddEditSurcharge';
import { Col, Row } from 'react-bootstrap';

const Pricing: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { notification } = App.useApp();
    const { id: instructionId } = useParams();
    const [pricingInstruction, setPricingInstruction] = useState<PricingInstructionModel | null>(null);
    const [productPricingList, setProductPricingList] = useState<ProductPricingModel[]>([]);
    const [pricingSurcharges, setPricingSurcharges] = useState<PricingSurchargeModel[]>([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [instructionExpanded, setInstructionExpanded] = useState(true);
    const [triggerGetPricingInstruction, getPricingInstructionResult] = useLazyGetPricingInstructionQuery();
    const [triggerSavePricingInstruction, savePricingInstructionResult] = useSavePricingInstructionMutation();
    const [triggerUpdateProductPricingStatus, updateProductPricingStatusResult] = useUpdateProductPricingStatusMutation();
    const [triggerCopyProductPricing, copyProductPricingResult] = useCopyProductPricingMutation();
    const [triggerDeleteProductPricing, deleteProductPricingResult] = useDeleteProductPricingMutation();
    const [triggerUpdateSurchargeStatus, updateSurchargeStatusResult] = useUpdatePricingSurchargeStatusMutation();
    const [triggerDeleteSurcharge, deleteSurchargeResult] = useDeletePricingSurchargeMutation();
    const [selectedProductPricing, setSelectedProductPricing] = useState<ProductPricingModel | null>(null);
    const [selectedSurcharge, setSelectedSurcharge] = useState<PricingSurchargeModel | null>(null);
    const formatEditorRef = useRef<any>(null),
        promptEditorRef = useRef<any>(null);

    const productPricingColumns: TableColumnsType<ProductPricingModel> = [
        {
            title: 'Name',
            dataIndex: 'pricingName'
        },
        {
            title: 'Product',
            dataIndex: 'datasetName'
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            align: 'center',
            width: 150,
            render: (_, record) => <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={record.isActive}
                checked={record.isActive}
                onChange={checked => onProductPricingStatusChange(checked, record)}
            />
        },
        {
            title: 'Surcharges',
            dataIndex: 'surcharges',
            align: 'center',
            width: 150,
            render: (_, record) => <Button size='small'
                onClick={() => onExpandSurcharges(record.id)}>
                Surcharges ({record._surchargeCount || 0})
            </Button>
        },
        {
            title: 'Actions',
            key: 'action',
            align: 'center',
            width: 150,
            render: (_, record) => (
                <Space>
                    <Button size='small'
                        onClick={() => onEditProductPricing(record)}
                        icon={<EditOutlined rev={0} />}
                    />
                    <Popconfirm
                        placement='topLeft'
                        title="Delete"
                        description={
                            <>
                                <p>Are you sure you would like to delete this pricing?</p>
                                <p className='text-danger fw-bold'>This action cannot be undone.</p>
                            </>
                        }
                        onConfirm={() => onDeleteProductPricing(record)}
                        okText="Yes"
                        cancelText="No">
                        <Button size='small' icon={<DeleteOutlined rev={0} />} />
                    </Popconfirm>
                    <Button size='small'
                        onClick={() => onCopyProductPricing(record)}
                        icon={<CopyOutlined rev={0} />}
                    />
                </Space>
            )
        }
    ]

    const surchargesRender = (productPricing: ProductPricingModel, index: number, indent: number, expanded: boolean) => {
        const columns: TableColumnsType<PricingSurchargeModel> = [
            {
                title: 'Surcharge Name',
                dataIndex: 'surchargeName'
            },
            {
                title: 'Active',
                dataIndex: 'isActive',
                align: 'center',
                width: 150,
                render: (_, record) => <Switch
                    size='small'
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={record.isActive}
                    checked={record.isActive}
                    onChange={checked => onSurchargeStatusChange(checked, record)}
                />
            },
            {
                title: 'Actions',
                key: 'action',
                align: 'center',
                width: 150,
                render: (_, record) => (
                    <Space>
                        <Button size='small'
                            onClick={() => onEditSurcharge(record, productPricing)}
                            icon={<EditOutlined rev={0} />}
                        />
                        <Popconfirm
                            placement='topLeft'
                            title="Delete"
                            description={
                                <>
                                    <p>Are you sure you would like to delete this surcharge?</p>
                                    <p className='text-danger fw-bold'>This action cannot be undone.</p>
                                </>
                            }
                            onConfirm={() => onDeleteSurcharge(record)}
                            okText="Yes"
                            cancelText="No">
                            <Button size='small' icon={<DeleteOutlined rev={0} />} />
                        </Popconfirm>
                        <Button size='small'
                            onClick={() => onCopySurcharge(record)}
                            icon={<CopyOutlined rev={0} />}
                        />
                    </Space>
                )
            }
        ];

        const surcharges = _.filter(pricingSurcharges, x => x.productPricingId === productPricing.id);

        return <>
            <div className='text-end'>
                <Button size='small'
                    type="default"
                    onClick={() => onAddNewSurchargeClick(productPricing)}
                >
                    Add New Surcharge
                </Button>
            </div>

            <Table
                size='small'
                className='mt-2'
                rowKey={record => record.id}
                columns={columns}
                dataSource={surcharges}
                pagination={false}
                loading={
                    (updateSurchargeStatusResult.isLoading && updateSurchargeStatusResult.originalArgs?.productPricingId === productPricing.id) ||
                    (deleteSurchargeResult.isLoading && deleteSurchargeResult.originalArgs?.productPricingId === productPricing.id)
                }
            />
        </>
    }

    const onFinishFailed = () => {
        notification.error({
            message: "Validation Error",
            description: "Please fix highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = async (values: PricingInstructionModel) => {

        if (!_.trim(_.trim(promptEditorRef.current.getContent({ format: "text" })))) {
            form.setFieldValue("prompt", '');
            form.validateFields();
            return;
        }

        if (!_.trim(_.trim(formatEditorRef.current.getContent({ format: "text" })))) {
            form.setFieldValue("format", '');
            form.validateFields();
            return;
        }

        values.instructionName = _.trim(values.instructionName);
        values.prompt = _.trim(values.prompt);
        values.format = _.trim(values.format);

        let _pricingInstruction = {
            ...pricingInstruction,
            ...values
        }

        const response = await triggerSavePricingInstruction(_pricingInstruction);

        if ('data' in response && response.data.success) {
            let id = response.data.data?.id || 0;

            if (_pricingInstruction.id === 0) {
                navigate(`/settings/price-book/${id}`, { replace: true });
                loadData(id);
            }

            notification.success({
                message: "Successfull",
                description: "Price book saved.",
                placement: "topRight"
            });
        }
    }

    const onEditProductPricing = (record: ProductPricingModel) => {
        let _productPricing = cloneDeep(record);
        setSelectedProductPricing(_productPricing);
    }

    const onDeleteProductPricing = async (record: ProductPricingModel) => {

        let response = await triggerDeleteProductPricing({ id: record.id });

        if ('data' in response && response.data.success) {
            setProductPricingList(productPricingList.filter(x => x.id !== record.id));
        }
    }

    const onProductPricingStatusChange = async (status: boolean, record: ProductPricingModel) => {

        let pricing = productPricingList.find(x => x.id === record.id);
        pricing && (pricing.isActive = status);
        setProductPricingList([...productPricingList]);

        let response = await triggerUpdateProductPricingStatus({
            id: record.id,
            status: status
        });

        // Reset in case of error
        if (!('data' in response) || !response.data.success) {
            pricing && (pricing.isActive = !status);
            setProductPricingList([...productPricingList]);
        }

        updateRowSelection();
    }

    const onCopyProductPricing = async (record: ProductPricingModel) => {

        let response = await triggerCopyProductPricing({ id: record.id });

        if ('data' in response && response.data.success) {
            let pricing = cloneDeep(response.data.data);

            if (pricing) {
                let productPricing = pricing.productPricingList[0]
                pricing.pricingSurcharge ??= [];
                productPricing._surchargeCount = pricing.pricingSurcharge.length;
                productPricingList.push(productPricing);
                pricingSurcharges.push(...pricing.pricingSurcharge);

                setProductPricingList(_.orderBy(productPricingList, x => [x.datasetId, x.id]));
                setPricingSurcharges([...pricingSurcharges]);

                updateRowSelection(productPricing.id);
            }
        }
    }

    const onProductPricingModalClose = (productPricing?: ProductPricingModel | null) => {
        if (productPricing) {
            // Check for existing pricing
            let index = _.findIndex(productPricingList, x => x.id === productPricing.id);

            if (index >= 0) {
                productPricingList[index] = productPricing;
            } else {
                productPricingList.push(productPricing);
            }

            setProductPricingList(_.orderBy(productPricingList, x => [x.datasetId, x.id]));

            // Select updated row
            updateRowSelection(productPricing.id);
        }

        setSelectedProductPricing(null);
    }

    const onInstructionExpandChange = (status: boolean) => {
        setInstructionExpanded(status);
    }

    const onAddNewPricingClick = () => {
        setSelectedProductPricing({
            id: 0,
            pricingInstructionId: pricingInstruction?.id || 0,
            pricingName: '',
            datasetId: 0,
            datasetName: '',
            description: '',
            instructions: '',
            isActive: true
        })
    }

    const updateRowSelection = (selectedRowKey?: number | null) => {
        // Keep expanded
        let keys = _.filter(selectedRowKeys, x => expandedRowKeys.indexOf(x) >= 0);

        selectedRowKey && keys.push(selectedRowKey);
        setSelectedRowKeys(keys);
    }

    const onExpandSurcharges = (key: number) => {
        let keys = [...expandedRowKeys];
        // Remove if already exists
        let removed = _.remove(keys, x => x === key);

        // if not then add
        if (!removed.length) {
            keys.push(key);
        }

        setSelectedRowKeys(keys);
        setExpandedRowKeys(keys);
    }

    const onEditSurcharge = (record: PricingSurchargeModel, productPricing: ProductPricingModel) => {
        let _pricingSurcharge = cloneDeep(record);
        _pricingSurcharge._datasetId = productPricing.datasetId;
        setSelectedSurcharge(_pricingSurcharge);
    }

    const onDeleteSurcharge = async (record: PricingSurchargeModel) => {

        let response = await triggerDeleteSurcharge({ id: record.id, productPricingId: record.productPricingId });

        if ('data' in response && response.data.success) {
            setPricingSurcharges(pricingSurcharges.filter(x => x.id !== record.id));

            let pricing = productPricingList.find(x => x.id === record.productPricingId);

            if (pricing) {
                pricing._surchargeCount = ((pricing._surchargeCount || 1) - 1);
                setProductPricingList([...productPricingList]);
            }
        }
    }

    const onSurchargeStatusChange = async (status: boolean, record: PricingSurchargeModel) => {
        let surcharge = pricingSurcharges.find(x => x.id === record.id);
        surcharge && (surcharge.isActive = status);
        setPricingSurcharges([...pricingSurcharges]);

        let response = await triggerUpdateSurchargeStatus({
            id: record.id,
            status: status,
            productPricingId: record.productPricingId
        });

        // Reset in case of error
        if (!('data' in response) || !response.data.success) {
            surcharge && (surcharge.isActive = !status);
            setProductPricingList([...productPricingList]);
        }
    }

    const onCopySurcharge = (record: PricingSurchargeModel) => {
        let _pricingSurcharge = cloneDeep(record);
        _pricingSurcharge.surchargeName = `Copy of ${record.surchargeName}`
        _pricingSurcharge.id = 0;
        _pricingSurcharge.createdBy = null;
        _pricingSurcharge.createdDate = null;
        _pricingSurcharge.modifiedBy = null;
        _pricingSurcharge.modifiedDate = null;

        setSelectedSurcharge(_pricingSurcharge);
    }

    const onSurchargeModalClose = (pricingSurcharge?: PricingSurchargeModel | null) => {
        if (pricingSurcharge) {
            // Check for existing pricing
            let index = _.findIndex(pricingSurcharges, x => x.id === pricingSurcharge.id);

            if (index >= 0) {
                pricingSurcharges[index] = pricingSurcharge;
            } else {
                pricingSurcharges.push(pricingSurcharge);

                // Check for existing pricing
                let pricing = _.find(productPricingList, x => x.id === pricingSurcharge.productPricingId);

                if (pricing) {
                    pricing._surchargeCount = (pricing._surchargeCount ?? 0) + 1;
                    setProductPricingList([...productPricingList]);
                }
            }

            setPricingSurcharges([...pricingSurcharges]);
        }

        setSelectedSurcharge(null);
    }

    const onAddNewSurchargeClick = (record: ProductPricingModel) => {
        setSelectedSurcharge({
            id: 0,
            productPricingId: record.id,
            _datasetId: record.datasetId,
            surchargeName: '',
            description: '',
            instructions: '',
            isActive: true
        })
    }

    const loadData = (id: number) => {
        triggerGetPricingInstruction(id, false)
            .then(response => {
                if (response && 'data' in response) {
                    const pricing = response.data;

                    setInstructionExpanded(id > 0 ? false : true);

                    if (pricing?.pricingInstruction) {
                        let _pricingInstruction = cloneDeep(pricing.pricingInstruction);
                        let _productPricingList = cloneDeep(pricing.productPricingList || []);
                        let _pricingSurcharges = cloneDeep(pricing.pricingSurcharge || []);

                        if (pricing.pricingSurcharge?.length) {
                            let surchargeGroups = _.groupBy(pricing.pricingSurcharge, x => x.productPricingId);

                            _.forEach(_productPricingList, x => {
                                x._surchargeCount = surchargeGroups[x.id]?.length || 0;
                            })
                        }

                        setPricingInstruction(_pricingInstruction);
                        setProductPricingList(_.orderBy(_productPricingList, x => [x.datasetId, x.id]));
                        setPricingSurcharges(_pricingSurcharges);

                        form.setFieldsValue(_pricingInstruction);
                    }
                }
            });
    }

    useEffect(() => {
        let id = Number(instructionId);

        if (!_.isNaN(id) && id > 0) {
            loadData(id);
        }
        else {
            let _pricingInstruction = {
                id: 0,
                format: '',
                prompt: '',
                instructionName: '',
                isActive: true
            };

            setPricingInstruction(_pricingInstruction);
            form.setFieldsValue(_pricingInstruction);
        }
    }, []);

    return (
        <>
            <Link to={'../list'}>
                <Button type='link' className='ps-0 pt-0' icon={<ArrowLeftOutlined rev={0} />}>
                    Back to list
                </Button>
            </Link>
            <Spin spinning={getPricingInstructionResult.isFetching || savePricingInstructionResult.isLoading}>
                <Card headStyle={{ paddingTop: '15px', paddingBottom: '15px' }}
                    bodyStyle={{ display: instructionExpanded ? 'block' : 'none' }}
                    title={<>
                        <h2 className="text-dark">Price Book</h2>
                        <div className="text-gray-700 fs-6 fw-normal text-wrap">
                            Add pricing instructions for your products
                        </div>
                    </>}
                    extra={
                        <Space>
                            <Button type="link" className='p-0'
                                onClick={() => onInstructionExpandChange(!instructionExpanded)}>
                                {
                                    instructionExpanded ? <>Hide Details <UpOutlined rev={0} /></> : <>Show Details <DownOutlined rev={0} /></>
                                }
                            </Button>
                        </Space>
                    }>

                    <Form
                        layout="vertical"
                        form={form}
                        name="PricingInstruction"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Row>
                            <Col sm={10}>
                                <Form.Item name="instructionName" label="Price Book Name"
                                    rules={[{ required: true, message: "'${label}' is required" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col sm={2}>
                                <Form.Item name="isActive" label="Active" valuePropName="checked">
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item name="prompt" labelCol={{ span: 24 }}
                            label={
                                <div>
                                    AI Pricing Prompt
                                    <div className="text-gray-700 fs-7 fw-normal text-wrap">
                                        <div>Provide any general directions to RFP Ninja for calculating prices for your products. For example, assumptions to make, format for the desired output, etc.</div>
                                        <div>Note: These instructions will be applied across all prices.</div>
                                        <div>Important: Being the prompt with "You are a pricing agent that helps calculate the price for..."</div>
                                    </div>
                                </div>
                            }
                            rules={[{ required: true, message: "'AI Pricing Prompt' is required" }]}>

                            <HtmlEditor placeholder={`Provide any general directions to RFP Ninja for calculating prices for your products.\nFor example, assumptions to make, format for the desired output, etc.\nNote: These instructions will be applied across all prices.`}
                                setEditorRef={(editor: any) => promptEditorRef.current = editor} />

                        </Form.Item>

                        <Form.Item name="format" label="Pricing Format"
                            rules={[{ required: true, message: "'${label}' is required" }]}>

                            <HtmlEditor placeholder="Describe the output format you prefer. "
                                setEditorRef={(editor: any) => formatEditorRef.current = editor} />
                        </Form.Item>

                        <Form.Item className="text-end mb-0">
                            <Button type="primary" htmlType="submit">
                                Save Changes
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>

                <Card
                    title={<h2>Products Pricing</h2>}
                    hidden={(pricingInstruction?.id ?? 0) <= 0}
                    className='mt-5'
                    extra={
                        <Button type="default"
                            onClick={onAddNewPricingClick}
                        >
                            Add New Pricing
                        </Button>
                    }>
                    <Table
                        rowKey={record => record.id}
                        columns={productPricingColumns}
                        dataSource={productPricingList}
                        rowSelection={{
                            selections: false,
                            hideSelectAll: true,
                            columnWidth: 0,
                            renderCell: () => "",
                            selectedRowKeys: selectedRowKeys
                        }}
                        expandable={{
                            expandedRowKeys: expandedRowKeys,
                            expandedRowRender: surchargesRender,
                            showExpandColumn: false,
                            expandedRowClassName: () => "expandable-table"
                        }}
                        pagination={false}
                        locale={{
                            emptyText: <>
                                {
                                    productPricingList.length === 0 &&
                                    <div className='py-15 bg-gray-200'>
                                        <h4 className='text-primary'>No pricing added yet.</h4>
                                    </div>
                                }
                            </>
                        }}
                        loading={
                            updateProductPricingStatusResult.isLoading ||
                            copyProductPricingResult.isLoading ||
                            deleteProductPricingResult.isLoading
                        }
                    />
                </Card>
            </Spin>

            {
                selectedProductPricing &&
                <AddEditProductPricing
                    productPricing={selectedProductPricing}
                    onModalClose={onProductPricingModalClose}
                />
            }

            {
                selectedSurcharge &&
                <AddEditSurcharge
                    pricingSurcharge={selectedSurcharge}
                    onModalClose={onSurchargeModalClose}
                />
            }
        </>
    )
}

export { Pricing };

