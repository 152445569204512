import Docxtemplater from 'docxtemplater';
import HTMLModule from 'docxtemplater-html-module';
import expressionParser from 'docxtemplater/expressions';
import PizZip from 'pizzip';
import { downloadBlobSilently, getExportWordTemplate, sanitizeAndLimitFileName } from '../CommonFunctions';
import { ExportTemplateType } from '../Enums';
import { useState } from 'react';

const useWordExport = () => {
    const [isExporting, setIsExporting] = useState(false);

    const exportToWord = async (type: ExportTemplateType, fileName: string, exportData: any) => {
        try {
            setIsExporting(true);

            const templateFile = getExportWordTemplate(type);
            const templateBlob = await (await templateFile).arrayBuffer();
            const zip = new PizZip(templateBlob);
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
                parser: expressionParser,
                modules: [
                    new HTMLModule({ ignoreUnknownTags: true, ignoreCssErrors: true })
                ]
            });

            doc.render(exportData);

            const docxBlob = doc.getZip().generate({
                type: 'blob',
                mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            });

            let _fileName = sanitizeAndLimitFileName(fileName);
            downloadBlobSilently(docxBlob, `${_fileName}.docx`);
        }
        catch (e: any) { console.log(e) }
        finally {
            setIsExporting(false);
        }
    }

    return { isExporting, exportToWord };
};

export default useWordExport;