import { Action, Reducer, ThunkAction, configureStore, createAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { combineReducers } from 'redux';
import {
    FLUSH,
    PAUSE,
    PERSIST, PURGE,
    REGISTER,
    REHYDRATE, persistReducer, persistStore
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import masterDataReducer from './MasterDataSlice';
import projectsReducer from './ProjectsSlice';
import dashboardReducer from './DashboardSlice';
import { RTKBaseApi } from './rtkquery/BaseApi';
import { rtkQueryErrorLogger } from './rtkquery/RTKQueryErrorLogger';


const RESET_STATE_ACTION_TYPE = 'resetState';
export const resetStateAction = createAction(RESET_STATE_ACTION_TYPE,
    () => {
        return { payload: null };
    }
);

const initialState = {};

const persistConfig = {
    key: 'rfpninja',
    version: 1,
    storage,
    whitelist: ['masterData', 'dashboardData']
};

const reducers = {
    masterData: masterDataReducer,
    projectsData: projectsReducer,
    dashboardData: dashboardReducer,
    [RTKBaseApi.reducerPath]: RTKBaseApi.reducer
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

const rootReducer: Reducer<RootState> = (state, action) => {
    if (action.type === RESET_STATE_ACTION_TYPE) {
        storage.removeItem(`persist:${persistConfig.key}`);
        state = initialState as RootState;
    }

    return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
        ,
    }).concat(RTKBaseApi.middleware)
      .concat(rtkQueryErrorLogger)
})

setupListeners(store.dispatch)

export default rootReducer;
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;