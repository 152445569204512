import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { App, Button, Popconfirm, Space, Spin, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import _, { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDeleteDatasetMutation, useLazyGetDatasetsQuery } from '../../../redux/rtkquery/CompanyApi';
import { DataSetContentType } from '../../common/Enums';
import { DataSetModel } from '../../models';
import { AddEditDataSet } from './AddEditDataSet';

interface DataSetListItemModel extends DataSetModel {
    filesCount: number;
    webUrlsCount: number;
}

type ComponentProps = {
    nextStep?: () => void,
    prevStep?: () => void,
    source: string,
    dataSetType: number,
    isIndexing?: boolean
}

const DataSets: React.FC<ComponentProps> = (props) => {
    const [loading, setLoading] = useState(true);
    const [dataSet, setDataSet] = useState<DataSetModel | null>(null);
    const [dataSets, setDataSets] = useState<DataSetListItemModel[]>([]);
    const [triggerGetDatasets] = useLazyGetDatasetsQuery();
    const [triggerDeleteDataset] = useDeleteDatasetMutation();
    const { notification } = App.useApp();

    const columns: ColumnsType<DataSetListItemModel> = [
        {
            title: 'Name',
            dataIndex: 'datasetName',
            key: 'id',
            sorter: true,
        },
        {
            title: 'Documents',
            dataIndex: 'filesCount',
            key: 'filesCount',
            align: 'center',
            sorter: true
        },
        {
            title: 'Webpages',
            dataIndex: 'webUrlsCount',
            key: 'webUrlsCount',
            align: 'center',
            sorter: true
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <Button size='small'
                        onClick={() => editDataSet(record)}
                        icon={<EditOutlined rev={0} />} disabled={props.isIndexing} />
                    <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this item?"
                        onConfirm={() => deleteDataSet(record)}
                        okText="Yes"
                        cancelText="No">
                        <Button size='small'
                            onClick={() => editDataSet(record)}
                            icon={<DeleteOutlined rev={0} />} disabled={props.isIndexing} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const deleteDataSet = async (record: DataSetListItemModel) => {
        let removed = _.remove(dataSets, x => x.datasetId === record.datasetId);

        if (removed.length) {
            setDataSets(dataSets.map(x => x));
            let response = await triggerDeleteDataset(record.datasetId);

            if ('error' in response) {
                dataSets.push(...removed);

                notification.error({
                    message: "Delete Error",
                    description: _.get(response.error, "error"),
                    placement: "topRight"
                });
            }
        }
    }

    const addDataSet = () => {
        setDataSet({
            datasetId: 0,
            datasetName: "",
            datasetTypeId: props.dataSetType,
            datasetContent: []
        })
    }

    const editDataSet = (record: DataSetListItemModel) => {
        let _dataSet = _.cloneDeep(record);
        _.unset(_dataSet, "filesCount");
        _.unset(_dataSet, "webUrlsCount");
        setDataSet(_dataSet as DataSetModel);
    }

    const onDataSetModalClose = (updatedDataSet?: DataSetModel | null) => {
        if (updatedDataSet) {
            if (dataSet && dataSet.datasetId > 0) {
                let indexId = dataSets.findIndex(x => x.datasetId === updatedDataSet.datasetId);
                dataSets[indexId] = getPageModel(updatedDataSet);
            }
            else {
                dataSets.push(getPageModel(updatedDataSet));
            }

            setDataSets(dataSets.map(x => x));
        }
        setDataSet(null);
    }

    const getPageModel = (dataset: DataSetModel) => {
        let model = dataset as DataSetListItemModel;
        model.filesCount = 0;
        model.webUrlsCount = 0;

        _.forEach(model.datasetContent, c => {
            if (c.contentTypeId === DataSetContentType.File)
                model.filesCount = ++model.filesCount;
            else if (c.contentTypeId === DataSetContentType.WebUrl)
                model.webUrlsCount = ++model.webUrlsCount;
        });

        return model;
    }

    useEffect(() => {
        triggerGetDatasets([props.dataSetType])
            .then(response => {
                if (response.data) {
                    const pageData = _.map(cloneDeep(response.data), x => getPageModel(x));
                    setDataSets(pageData);
                    setLoading(false);
                }
                else if (response.error) {
                    notification.error({
                        message: "Dataset load error",
                        description: _.get(response.error, "error"),
                        placement: "topRight"
                    });
                }
            })
    }, []);

    return (<>
        <Spin spinning={loading}>
            <div className="text-end">
                <Button type="primary" className="mb-2" onClick={addDataSet} disabled={props.isIndexing}>
                    Add New
                </Button>
            </div>
            <Table rowKey={"datasetId"} columns={columns} dataSource={dataSets} size="small" pagination={{ hideOnSinglePage: true }} />

            <div className="text-end mt-5">
                {
                    (props.source === "InitialSetup") &&
                    <Space>
                        <Button size="large" onClick={props.prevStep}>
                            Back
                        </Button>
                        <Button type="primary" size="large" onClick={props.nextStep}>
                            Next Step
                        </Button>
                    </Space>
                }
            </div>
        </Spin>
        {dataSet && <AddEditDataSet dataSet={dataSet} onModalClose={onDataSetModalClose} dataSetType={props.dataSetType} />}
    </>
    )
}

export { DataSets };
