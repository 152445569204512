import { TimeRangePickerProps } from "antd";
import dayjs from 'dayjs';

export const CONST_MAX_DATE = '12/31/9998';
export const CONST_MIN_DATE = '01/01/1900';
export const CONST_DATE_FORMAT = 'MM/DD/YYYY';
export const CONST_DATETIME_DB_FORMAT = 'YYYY-MM-DDTHH:mm:ss.S';
export const CONST_DATE_DB_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const CONST_DATETIME_UI_FORMAT = 'MMM DD, YYYY hh:mm A';

export const AntDRangePresets: TimeRangePickerProps['presets'] = [
    { label: 'This Month', value: [dayjs().startOf('M'), dayjs()] },
    { label: 'Last Month', value: [dayjs().subtract(1, 'M').startOf('M'), dayjs().subtract(1, 'M').endOf('M')] },
    { label: 'Last 3 Months', value: [dayjs().subtract(3, 'M').startOf('M'), dayjs().subtract(1, 'M').endOf('M')] },
    { label: 'Last 6 Months', value: [dayjs().subtract(6, 'M').startOf('M'), dayjs().subtract(1, 'M').endOf('M')] },
    { label: 'This Year', value: [dayjs().startOf('y'), dayjs()] },
    { label: 'Last Year', value: [dayjs().subtract(1, 'y').startOf('y'), dayjs().subtract(1, 'y').endOf('y')] },
  ];