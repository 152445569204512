import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { CellClickedEvent, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { App, Button, Card, Progress } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDeleteProjectMutation, useLazyGetProjectListQuery } from '../../../redux/rtkquery/ProjectApi';
import { IsCompanyAdmin, IsProjectOwner } from '../../common/CommonFunctions';
import { ProjectListItemModel } from '../../models';
import { useAuth } from '../auth';

const Projects: React.FC = () => {
    const navigate = useNavigate();
    const { modal } = App.useApp();
    const { user_metadata } = useAuth();
    const [triggerGetProjectList] = useLazyGetProjectListQuery();
    const [triggerDeleteProject] = useDeleteProjectMutation();
    const gridRef = useRef<AgGridReact<ProjectListItemModel>>(null);
    const isCompanyAdmin = IsCompanyAdmin(user_metadata?.roles);
    const isProjectOwner = IsProjectOwner(user_metadata?.roles);

    const fetchProjects = async () => {
        const response = await triggerGetProjectList(undefined, false);
        gridRef.current?.api.setRowData(response.data || []);
    }

    const onDeleteClick = (params: CellClickedEvent<ProjectListItemModel, any>) => {
        if (!hasProjectAccessAsOwner(params.data))
            return;

        modal.confirm({
            title: "Confirm deletion",
            content: <>
                <p>Are you sure you would like to delete the project "{params.data?.projectName}"?</p>
                <p className='text-danger fw-bold'>This action cannot be undone.</p>
            </>,
            okText: "Delete",
            okButtonProps: { danger: true },
            cancelText: "No",
            onOk: () => onDeleteProject(params)
        });
    }

    const onDeleteProject = async (params: any) => {
        let response = await triggerDeleteProject({ projectId: params.data.projectId });

        if ('data' in response && response.data.success) {
            params.api.applyTransaction({ remove: [params.data] });
        }
    }

    const hasProjectAccessAsOwner = (data?: ProjectListItemModel) => {
        return isCompanyAdmin || user_metadata?.userId === data?.projectOwnerId;
    }

    const gridOptions: GridOptions<ProjectListItemModel> = {
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            autoHeight: true,
            suppressMovable: true,
            suppressAutoSize: true,
            onCellClicked: (event) => {
                navigate(`/projects/${event.data?.projectId}`)
            },
        },
        columnDefs:
            [
                {
                    field: 'projectName',
                    headerName: 'Project',
                    flex: 1,
                    wrapText: true,
                    cellRenderer: (params: ICellRendererParams<ProjectListItemModel>) =>
                        <Link to={`/projects/${params.data?.projectId}`}>
                            {params.data?.projectName}
                        </Link>
                },
                {
                    field: 'customerName',
                    headerName: 'Customer',
                    width: 125,
                },
                {
                    field: 'status',
                    width: 105,
                    suppressAutoSize: true,
                },
                {
                    field: 'dueDate',
                    width: 110,
                    valueFormatter: (params) => dayjs(params.value).format("MM/DD/YYYY")
                },
                {
                    field: 'projectOwner',
                    headerName: 'Owner',
                    width: 140,
                },
                {
                    headerName: '% Complete',
                    width: 130,
                    suppressMenu: true,
                    resizable: false,
                    cellRenderer: (params: ICellRendererParams<ProjectListItemModel>) => {
                        let { totalQuestions, totalQuestionsCompleted } = params.data || {};
                        let percent = 0;
                        if (totalQuestions && totalQuestionsCompleted)
                            percent = Math.ceil((totalQuestionsCompleted / totalQuestions) * 100);
                        return <Progress percent={percent} />
                    }
                },
                {
                    headerName: 'My Task % Complete',
                    width: 130,
                    suppressMenu: true,
                    resizable: false,
                    cellRenderer: (params: ICellRendererParams<ProjectListItemModel>) => {
                        let { totalAssignedQuestions, totalAssignedQuestionsCompleted } = params.data || {};
                        let percent = 0;
                        if (totalAssignedQuestions && totalAssignedQuestionsCompleted)
                            percent = Math.ceil((totalAssignedQuestionsCompleted / totalAssignedQuestions) * 100);
                        return <Progress percent={percent} />
                    }
                },
                {
                    headerName: '',
                    width: 50,
                    suppressMenu: true,
                    resizable: false,
                    pinned: 'right',
                    hide: !(isCompanyAdmin || isProjectOwner),
                    onCellClicked: onDeleteClick,
                    cellRenderer: (params: ICellRendererParams<ProjectListItemModel>) =>
                        <DeleteOutlined rev={0}
                            className={hasProjectAccessAsOwner(params.data) ? '' : 'text-muted'}
                        />
                }
            ],
        rowData: null,
        domLayout: 'autoHeight',
        onGridReady: (params) => {
            params.api.sizeColumnsToFit()
            fetchProjects();
        },
        onFirstDataRendered: () => {
            gridRef.current?.api.sizeColumnsToFit();
        }
    }

    useEffect(() => {

        const handleResize = () => {
            gridRef.current?.api?.sizeColumnsToFit();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const addProject = () => {
        navigate('/projects/add-project');
    }

    return (
        <Card title={<h2>My Projects</h2>}
            bodyStyle={{ minHeight: 'calc(100vh - 200px)' }}
            extra={(IsCompanyAdmin(user_metadata?.roles) || IsProjectOwner(user_metadata?.roles)) ?
                <Button type="primary"
                    icon={<PlusCircleOutlined rev={undefined} />}
                    onClick={addProject}>
                    Create a New Project
                </Button> : null}
        >
            <div className="ag-theme-alpine ag-theme-alpine-custom h-90 w-100">
                <AgGridReact
                    ref={gridRef}
                    gridOptions={gridOptions} />
            </div>
        </Card>
    )
}

export { Projects };

