import { Card, DatePicker, Spin } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { selectFilters, setFilters } from "../../../redux/DashboardSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useProjectCompletedStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { AntDRangePresets } from "../../common/Constants";
import { DashboardStatsType } from "../../common/Enums";

const ProjectCompletedStats: React.FC = () => {
  const [options, setOptions] = useState<any>(null);
  const [series, setSeries] = useState<any>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<any>(null);
  const [triggerCompletedStats, completedStatsResult] = useProjectCompletedStatsMutation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(state => selectFilters(state, DashboardStatsType.ProjectCompleted));

  const onRangeChange = (range: any) => {
    if (range) {
      let toDate: dayjs.Dayjs = range[1];
      range[1] = toDate.endOf('M');
    }

    setDateRange(range);
    setMonthCategories(range);
    loadStats(range);

    dispatch(setFilters({
      type: DashboardStatsType.ProjectCompleted,
      filters: {
        range: [range[0].format("MM/DD/YYYY"), range[1].format("MM/DD/YYYY")]
      }
    }));
  }

  const setMonthCategories = (dateRange: dayjs.Dayjs[]) => {
    let _categories = [], dateRanges = _.cloneDeep(dateRange);
    
    while (dateRanges[0].endOf('M') <= dateRanges[1].endOf('M')) {
      _categories.push(dateRanges[0].format('MMM YY'));
      dateRanges[0] = dateRanges[0].add(1, "M");
    };

    setCategories(_categories);
    return _categories;
  }

  const loadStats = (dateRange: dayjs.Dayjs[]) => {
    triggerCompletedStats({
      fromDate: dateRange?.[0].format("MM/DD/YYYY") || null,
      toDate: dateRange?.[1].format("MM/DD/YYYY") || null
    });
  }

  const initOptions = (chartSeries: number[], chartCategories: string[]) => {
    setSeries([
      {
        name: 'Projects Completed',
        data: chartSeries
      }
    ]);

    setOptions({
      chart: {
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '15%',
          borderRadius: 10,
          dataLabels: {
            position: 'top'
          },
        }
      },
      xaxis: {
        categories: chartCategories
      },
      yaxis: {
        min: 0,
        max: (_.max(chartSeries) || 0) <= 3 ? 6 : undefined,
        labels: {
          formatter: function(val:number) {
            return val.toFixed(0);
          }
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      }
    })
  }

  useEffect(() => {
    let dateRange = AntDRangePresets?.[0].value;

    if(filters?.range){
      dateRange = [dayjs(filters.range[0]), dayjs(filters.range[1])];
    }

    onRangeChange(dateRange);
  }, []);

  useEffect(() => {
    if (completedStatsResult.requestId && !completedStatsResult.isLoading &&
      completedStatsResult.data && completedStatsResult.data.success) {

      const chartSeries: number[] = [],
        categoriesDict: Record<string, number> = {};

      let chartCategories: string[] = [];

      let statsData = cloneDeep(completedStatsResult.data.data)

      _.forEach(statsData, x => {
        // Set Completion Month display label
        let completionOn = dayjs(`${x.completionYear}-${x.completionMonth}-01`, 'YYYY-M-D').format("MMM YY");

        // Set data grouping based on submission month
        categoriesDict[completionOn] = x.totalProject;
      });

      // Keep only categories which data is available
      chartCategories = categories//.filter(x => categoriesDict[x]);

      chartCategories.forEach(x => chartSeries.push(categoriesDict[x] || 0));

      initOptions(chartSeries, chartCategories);
    }
  }, [completedStatsResult]);

  return (
    <>
      <Card className="h-100">
        <Spin spinning={completedStatsResult.isLoading}>
          <div className='d-flex align-items-center justify-content-between'>
            <span className='fs-3 fw-bold text-dark me-2 lh-1'>Projects Completed</span>
            <DatePicker.RangePicker
              presets={AntDRangePresets}
              picker="month"
              size="small"
              value={dateRange}
              onChange={onRangeChange}
            />
          </div>

          <div className='card-body pt-2 pb-4'>
            {
              options !== null &&
              <ReactApexChart options={options}
                series={series}
                type="bar" height={'300'} width={'100%'} />
            }
          </div>
        </Spin>
      </Card>
    </>
  )
}

export { ProjectCompletedStats };

