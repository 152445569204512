import { SafetyCertificateOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { FactorTypes } from '../../../common/Enums';
import { useAuth } from '../core/Auth';
import { navigate2FA, supabase } from '../core/supabaseClient';
import { EnrollTOTP } from './EnrollTOTP';

export function RegisterMFA() {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [factor, setFactor] = useState("");

    useEffect(() => {
        (async () => {
            const result = await navigate2FA();
            if (!result.path || result.registered)
                await redirectToLogin();
        })();
    }, []);

    const onEnrolled = async () => {
        window.location.reload();
    }

    const onEnrollCancelled = (enrollId?: string) => {
        if (enrollId)
            supabase.auth.mfa.unenroll({ factorId: enrollId });
        setFactor('');
    }

    const redirectToLogin = async (delay: number = 0) => {
        await logout();
        setTimeout(() => {
            navigate('/auth/login');
        }, delay);
    }

    const onCancel = () => {
        redirectToLogin();
    }

    return (
        <>
            <div className='text-center mb-11'>
                <img alt='Logo' src={toAbsoluteUrl('/media/logos/rfp-ninja-logo-transparent.png')} className='h-75px mb-10' />
                <h1 className='text-dark fw-bolder mb-2'>
                    Ensure the security of your account
                </h1>
                <h4 className='fw-light mb-3'>
                    Your organization mandates the establishment of the following identity verification methods.
                </h4>
            </div>
            {
                !factor &&
                <Card className='shadow'
                    actions={[
                        <span key='signout'
                            className='text-dark'
                            onClick={onCancel}>
                            Cancel
                        </span>,
                        <span key={FactorTypes.totp.key}
                            className='text-dark'
                            onClick={() => setFactor(FactorTypes.totp.key)}>
                            Next
                        </span>

                    ]}>
                    <Card.Meta
                        title={<><SafetyCertificateOutlined rev={0} /> Authenticator App</>}
                        description={
                            <>
                                <p>Install an authenticator app on your mobile device, such as 1Password, Authy, Microsoft Authenticator, Google Authenticator, or Apple's Keychain.</p>
                                <p>Once the authenticator app is installed on your device,<br />select "Next".</p>
                            </>
                        }
                    />
                </Card>
            }

            {
                factor === FactorTypes.totp.key &&
                <Card size='small' className='shadow'>
                    <EnrollTOTP layout='vertical' onCancelled={onEnrollCancelled} onEnrolled={onEnrolled} />
                </Card>
            }

        </>
    )
}