import { App, Form, Spin } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSaveQuestionMutation } from '../../../redux/rtkquery/LibraryApi';
import FullScreenModal from '../../common/components/FullScreenModal';
import { LibraryQuestionModel } from "../../models";
import { AddEditForm } from './AddEditForm';

type ComponentProps = {
    question: LibraryQuestionModel,
    onClose: (question?: LibraryQuestionModel) => void,
    referenceLibraryId?: number | null
}

const AddEditQuestion: React.FC<ComponentProps> = (props) => {
    const [form] = Form.useForm<LibraryQuestionModel>();
    const [loading, setLoading] = useState(false);
    const { notification } = App.useApp();
    const [triggerSaveQuestion, saveQuestionResult] = useSaveQuestionMutation();

    const onSubmit = (question: LibraryQuestionModel) => {
        if(!_.isNil(props.referenceLibraryId))
           props.onClose(question);
        else
            triggerSaveQuestion(question);
    }

    useEffect(() => {
        setLoading(saveQuestionResult.isLoading);

        if (saveQuestionResult.requestId && !saveQuestionResult.isLoading) {

            if (saveQuestionResult.data?.success) {
                props.onClose(props.question);

                notification.success({
                    message: "Successfull",
                    description: "Changes saved successfully",
                    placement: "topRight"
                });

                return;
            }
        }
    }, [saveQuestionResult])

    return (
        <FullScreenModal title={ !_.isNil(props.referenceLibraryId) ? 
            (props.referenceLibraryId ? 'Update Existing Library Question' : 'Add New Library Question') : 
            (props.question.libraryQuestionId > 0 ? 'Update Library Question' : 'Add Library Question')}
            open={true}
            maskClosable={false}
            keyboard={false}
            closable={false}
            width={800}
            destroyOnClose={true}
            onCancel={(e) => props.onClose()}
            okText={!_.isNil(props.referenceLibraryId) ? "Save and Accept" : "Save Changes"}
            onOk={form.submit}
            confirmLoading={saveQuestionResult.isLoading}
        >
            <Spin spinning={loading}>
                <AddEditForm form={form} onSubmit={onSubmit} question={props.question} />
            </Spin>
        </FullScreenModal>
    )
}

export { AddEditQuestion };

