import { Chart, registerables } from 'chart.js'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { AuthProvider } from './app/modules/auth'
import { AppRoutes } from './app/routing/AppRoutes'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/sass/style.scss'
import './assets/style.scss'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'
import { ConfigProvider } from 'antd'

Chart.register(...registerables)

const container = document.getElementById('root')
if (container) {
    createRoot(container).render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AuthProvider>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#3d569b'
                            }
                        }}
                    >
                        <AppRoutes />
                    </ConfigProvider>
                </AuthProvider>
            </PersistGate>
        </Provider>
    )
}
