import { App, Button, Card, Drawer, Spin, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import { selectMasterDataByType } from '../../../redux/MasterDataSlice';
import { deleteDataAsyncOnAPI, getDataFromAPI } from '../../../redux/requests';
import { IsCompanyAdmin } from '../../common/CommonFunctions';
import { UserModel } from '../../models';
import { useAuth } from '../auth';
import { AddEditUser } from './AddEditUser';
import { ManageUserMFA } from './ManageUserMFA';

const Users: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const { user_metadata } = useAuth();
    const [selectedUser, setSelectedUser] = useState<UserModel | null>(null);
    const [userEditModalOpen, setUserEditModalOpen] = useState(false);
    const [userMFAModalOpen, setUserMFAModalOpen] = useState(false);
    const [userList, setUserList] = useState<UserModel[]>([]);
    const rolesDict = _.transform(useAppSelector(state => selectMasterDataByType(state, "Roles")),
        (r: Record<string, string>, v) => r[v.value] = v.name, {});
    const { companyId } = useParams();
    const { notification, modal } = App.useApp();

    useEffect(() => {
        getDataFromAPI<UserModel[]>(`user/list/${companyId ?? user_metadata?.companyId}`)
            .then(response => {
                setUserList(response.data || []);
                setLoading(false);
            })
            .catch(error => console.error('Error:', error));
    }, [])

    const columns: ColumnsType<UserModel> = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'userId',
            sorter: true,
            render: (_, record) => `${record.firstName} ${record.lastName}`
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true
        },
        {
            title: 'Role',
            dataIndex: 'roles',
            key: 'roles',
            sorter: false,
            render: (text, record) => _.sortBy(_.map(record.roles, x => rolesDict[x])).join(", ")
        },
        {
            title: '2FA',
            dataIndex: 'is2fa',
            key: 'is2fa',
            align: 'center',
            sorter: true,
            render: (_, record) => record.is2fa ? "Enabled" : "Disabled"
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            align: 'center',
            sorter: true,
            render: (_, record) => record.isActive ? "Active" : "Inactive"
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <div className="dropdown-center">
                    <div className="btn-group btn-group-sm">
                        <button type="button" className="btn btn-secondary" onClick={() => editUser(record)}>Edit</button>
                        <button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                            <span className="visually-hidden">Actions</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#" onClick={()=> toggleUserMFA(record)}>Manage 2FA</a></li>
                            {
                                record.userId !== user_metadata?.userId && IsCompanyAdmin(user_metadata?.roles) &&
                                <li><a className="dropdown-item" href="#" onClick={()=> deleteUser(record)}>Remove User</a></li>
                            }
                        </ul>
                    </div>
                </div>
            ),
        },
    ]

    const deleteUser = async (record: UserModel) => {
        modal.confirm({
            title: "Remove User",
            content: "Are you sure to remove selected user?",
            onOk: async()=> {
                let removed = _.remove(userList, x => x.userId === record.userId);

                if (removed.length) {
                    setUserList(userList.map(x => x));
                    let response = await deleteDataAsyncOnAPI(`user/${record.userId}/${record.companyId}`);
                    if (!response.success) {
                        userList.push(...removed);
                        notification.error({
                            message: "Delete Error",
                            description: response.message || "Unable to delete user",
                            placement: "topRight"
                        });
                    }
                }
            }
        })
        
    }

    const addUser = () => {
        setSelectedUser({
            userId: 0,
            companyId: Number(companyId) ?? user_metadata?.companyId ?? 0,
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNo: '',
            profilePic: '',
            isActive: true,
            is2fa: false,
            invite: false,
            roles: []
        });
        setUserEditModalOpen(true);
    }

    const editUser = (record: UserModel) => {
        const _user = _.cloneDeep(record);
        setSelectedUser(_user);
        setUserEditModalOpen(true);
    }

    const toggleUserMFA = (record?: UserModel) => {
        if (record) {
            setSelectedUser(record);
            setUserMFAModalOpen(true);
        }
        else {
            setSelectedUser(null);
            setUserMFAModalOpen(false);
        }
    }

    const onUserSave = (updatedUser?: UserModel) => {
        if (updatedUser) {
            if (selectedUser?.userId) {
                let indexId = userList.findIndex(x => x.userId === updatedUser.userId);
                userList[indexId] = updatedUser;
            }
            else {
                userList.push(updatedUser);
            }

            setUserList(userList.map(x => x));
        }
        setSelectedUser(null);
        setUserEditModalOpen(false);
    }

    return (<>
        <Spin spinning={loading}>
            <Card
                title={<div className='fs-1'>Users</div>}
                extra={
                    <Button type="primary" onClick={addUser}>
                        Add New
                    </Button>
                }
            >
                <Table rowKey={"userId"} size="small"
                    columns={columns}
                    dataSource={userList}
                    pagination={{ hideOnSinglePage: true }}
                />
            </Card>
        </Spin>
        {
            selectedUser && userEditModalOpen &&
            <Drawer title={`${selectedUser.userId > 0 ? 'Edit' : 'Add'} User`}
                open={true}
                maskClosable={false}
                keyboard={false}
                width={700}
                destroyOnClose={true}
                onClose={() => onUserSave()}
            >
                <AddEditUser user={selectedUser} onUserSave={onUserSave} />
            </Drawer>
        }

        {
            selectedUser && userMFAModalOpen &&
            <Drawer title="Manage User's 2FA"
                open={true}
                maskClosable={false}
                keyboard={false}
                width={700}
                destroyOnClose={true}
                onClose={() => toggleUserMFA()}
            >
                <ManageUserMFA user={selectedUser} onCancel={toggleUserMFA} />
            </Drawer>
        }
    </>
    )
}

export { Users };
