import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../requests";
import { RTKQueryTags } from "../../app/common/Enums";

const BASE_URL = process.env.REACT_APP_API_URL;

export const RTKBaseApi = createApi({
    reducerPath: 'rtkBaseApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: async (headers) => {
            const token = await getAccessToken().then(_token => _token);
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: [
        RTKQueryTags.UserSelectList, 
        RTKQueryTags.CustomerSelectList
    ],
    endpoints: () => ({})
})