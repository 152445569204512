import clsx from 'clsx'
import { useFormik } from 'formik'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useForgotPasswordMutation } from '../../../../redux/rtkquery/UserApi'
import { Button } from 'antd'

const initialValues = {
  email: ''
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [triggerForgotPassword] = useForgotPasswordMutation();
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setHasErrors(undefined)
      setSubmitting(true)

      let response = await triggerForgotPassword({ email: values.email });

      setSubmitting(false)

      if ('data' in response) {
        if (response.data.success) {
          setHasErrors(false)
          return
        }
        else {
          setHasErrors(true)
          setStatus(response.data.message || 'Unable to process request')
        }
      }

      setHasErrors(true)
      setStatus('Unable to process request')
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>

        <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password.
        </div>
      </div>

      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>The request has been successfully processed. If you have an active account on the system, a reset link will be sent to your email.</div>
        </div>
      )}

      {hasErrors === undefined && <>

        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            disabled={formik.isSubmitting}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex' style={{ gap: '8px' }}>
          <Link to='/auth/login' className='w-50'>
            <Button type='default'
              size='large'
              className='w-100'
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
          </Link>
          <Button block type='primary'
            htmlType='submit'
            size='large'
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.isValid}
            className='w-50'>
            Submit
          </Button>
        </div>

      </>}
    </form>
  )
}