import { RTKQueryTags } from '../../app/common/Enums';
import { APIResponseModel, SystemSettingModel } from '../../app/models';
import { RTKBaseApi } from './BaseApi';

interface MasterDataType {
  [type: string]: SystemSettingModel[]
}

const CommonApi = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMasterData: builder.query<MasterDataType, void>({
      query: () => ({
        url: '/master/getmasterdata',
      }),
      transformResponse: (response: APIResponseModel<SystemSettingModel[]>) => {
        const data: MasterDataType = {};
        response.data?.forEach(x => {
          !data[x.type] && (data[x.type] = []);
          data[x.type].push(x)
        });
        return data;
      },
      providesTags: (_) => [RTKQueryTags.QuestionReviewList],
      keepUnusedDataFor: 60 * 60,
    }),
    getFileDownloadUrl: builder.mutation<APIResponseModel<string>, {fileName: string, fileGuid: string}>({
      query: (params) => ({
        url: '/files/downloadlink',
        method: "POST",
        body: params
      })
    }),
  }),
})

export const {
  useLazyGetMasterDataQuery,
  useGetMasterDataQuery,
  useGetFileDownloadUrlMutation
} = CommonApi;