import _ from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useAppSelector } from "../../../redux/hooks";
import { selectMasterDataDictByType } from "../../../redux/MasterDataSlice";
import { useProjectStatusStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { ProjectStatus } from "../../common/Enums";
import { Card, Spin } from "antd";

const ProjectStatusColors: Record<number, string> = {
  [ProjectStatus.Open]: '#e0e0e0',
  [ProjectStatus.Submitted]: '#4fc3f7',
  [ProjectStatus.DidNotParticipate]: '#ffb74d',
  [ProjectStatus.Won]: '#4db6ac',
  [ProjectStatus.Lost]: '#e57373',
}

const ProjectStatusStats: React.FC = () => {
  const [options, setOptions] = useState<any>(null);
  const [series, setSeries] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [activeProjects, setActiveProjects] = useState<number>(0);
  const projectStatusDict = useAppSelector(state => selectMasterDataDictByType(state, "ProjectStatus"));
  const [triggerProjectStatusStats, projectStatusStatsResult] = useProjectStatusStatsMutation();

  useEffect(() => {

    setOptions({
      chart: {
        //width: '100%',
      },
      dataLabels: {
        enabled: false
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            show: false
          }
        }
      }],
      plotOptions: {
        pie: {
          offsetX: -40,
          customScale: 1,
          donut: {
            size: '75%',
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      legend: {
        position: 'right',
        horizontalAlign: 'center',
        //offsetX: -30,
        //offsetY: 0,
        fontSize: '13px',
        formatter: function (seriesName: any, opts: any) {
          return [seriesName, " : ", opts.w.globals.series[opts.seriesIndex]]
        },
        markers: {
          height: 3,
          width: 8,
          radius: 0,
          offsetY: -3,
          offsetX: -7
        }
      },

    })

    triggerProjectStatusStats({ fromDate: null, toDate: null });
  }, []);

  useEffect(() => {
    if (projectStatusStatsResult.requestId && !projectStatusStatsResult.isLoading &&
      projectStatusStatsResult.data && projectStatusStatsResult.data.success) {

      let chartLabels: string[] = [], chartSeries: number[] = [], activeProjects = 0, chartColors: string[] = [];

      _.forEach(projectStatusStatsResult.data.data, x => {
        chartLabels.push(projectStatusDict[x.statusId].name);
        chartSeries.push(x.total);
        chartColors.push(ProjectStatusColors[x.statusId]);

        if (x.statusId === ProjectStatus.Open || x.statusId === ProjectStatus.Submitted) {
          activeProjects += x.total;
        }
      });

      setActiveProjects(activeProjects);
      setLabels(chartLabels);
      setSeries(chartSeries);
      setColors(chartColors);
    }
  }, [projectStatusStatsResult]);

  return (
    <Card className="h-100">
      <Spin spinning={projectStatusStatsResult.isLoading}>
        <div className="d-flex w-100">
          <div>
            <div className="d-flex flex-column">
              <div className='d-flex align-items-center'>
                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{activeProjects}</span>
              </div>
              <span className='text-gray-400 pt-1 fw-semibold fs-6'>Active Projects</span>
            </div>
          </div>
        </div>
        {
          options !== null &&
          <ReactApexChart options={{
            ...options,
            labels: labels,
            colors: colors
          }}
            series={series}
            type="donut"
            width={'100%'}
            height={'155'} />
        }
      </Spin>
    </Card>

  )
}

export { ProjectStatusStats }
