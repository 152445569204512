import { Factor } from "@supabase/supabase-js";
import { Button, Card, Form, Input, Modal, Popconfirm, Spin } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useUpdateProfileMutation } from "../../../redux/rtkquery/UserApi";
import { FactorTypes } from "../../common/Enums";
import { UserMetaDataModel } from "../../models";
import { useAuth } from "../auth";
import { EnrollTOTP } from "../auth/components/EnrollTOTP";
import { refreshSession, saveProfile as saveProfileOnSupabase, supabase } from "../auth/core/supabaseClient";

const Profile: React.FC = (props) => {
    const { user_metadata, saveAuth } = useAuth();
    const [loading, setLoading] = useState(false);
    const [enrollModalOpen, setEnrollModalOpen] = useState(false);
    const [factors, setFactors] = useState<Factor[]>([]);
    const [form] = Form.useForm();
    const [triggerUpdateProfile, updateProfileResult] = useUpdateProfileMutation();
    const [userProfile, setUserProfile] = useState<any>({
        firstName: user_metadata?.firstName,
        lastName: user_metadata?.lastName,
        phoneNo: user_metadata?.phoneNo || ""
    });

    const onProfileFinishFailed = (errorInfo: any) => {
        //message.error("Please fix highlighted errors");
    }

    const onProfileFinish = (values: UserMetaDataModel) => {
        if(values.phoneNo && !isValidPhoneNumber(values.phoneNo)) {
            Modal.confirm({
                title: "Invalid Phone No. format",
                content: <>Phone No. {values.phoneNo} seems not valid. Are you sure to save it?</>,
                okText: "Yes",
                cancelText: "No",
                onOk: () => saveProfile(values)
            });
            return;
        }

        saveProfile(values);
    }

    const saveProfile = async (values: UserMetaDataModel) => {
        setLoading(true);

        let saveResponse = await triggerUpdateProfile(values);

        if('data' in saveResponse){
            let response = await saveProfileOnSupabase(values);
            
            if(response.data){
                let session = await refreshSession();
                session && saveAuth(session);
            }

            setLoading(false);
            return;
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchFactors();
    }, []);

    const fetchFactors = () => {
        setLoading(true);

        supabase.auth.mfa.listFactors().then(list => {
            setFactors(list.data?.totp || []);
        }).finally(() => {
            setLoading(false);
        });
    }

    const remove2fa = async (id: string) => {
        const { data } = await supabase.auth.mfa.listFactors();
        let unverified_factor = data?.all.find(x => x.id === id && x.status === "unverified");

        if (unverified_factor || enableTwoFactor) {
            setLoading(true);
            supabase.auth.mfa.unenroll({ factorId: id }).then(response => {
                if (!response.error) {
                    fetchFactors();
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const onEnrolled = () => {
        fetchFactors();
        setEnrollModalOpen(false);
    }

    const onEnrollCancelled = (enrollId?: string) => {
        if (enrollId)
            remove2fa(enrollId);

        setEnrollModalOpen(false);
    }

    const enableTwoFactor = useMemo(() => {
        return user_metadata?.is2fa || user_metadata?.c_is2fa ? true : false;
    }, [factors]);

    return (<>
        <Spin spinning={loading}>

            <>
                <Card title="Profile" className="mb-5">
                    <Form
                        layout="vertical"
                        form={form}
                        name="AddEditUser"
                        initialValues={userProfile}
                        onFinish={onProfileFinish}
                        onFinishFailed={onProfileFinishFailed}
                        autoComplete="off"
                    >
                        <Row>
                            <Col md={6}>
                                <Form.Item name="firstName" label="First Name"
                                    rules={[{ required: true, message: "'${label}' is required" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={6}>
                                <Form.Item name="lastName" label="Last Name"
                                    rules={[{ required: true, message: "'${label}' is required" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Item name="phoneNo" label="Phone">
                                    <PhoneInput international
                                        defaultCountry="US"
                                        placeholder="Enter phone number"
                                        onChange={()=> {}}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className="text-end">
                            <Button type="primary" htmlType="submit">Save Changes</Button>
                        </div>
                    </Form>
                </Card>

                <Card title='2FA Setup'
                    extra={ 
                        enableTwoFactor && 
                        <Button type="primary" ghost onClick={() => setEnrollModalOpen(true)}>
                            Configure New
                        </Button>
                    }
                >
                    <table className="table table-striped">
                        <thead>
                            <tr className="fw-semibold">
                                <th>TYPE</th>
                                <th>STATUS</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(factors, x => (
                                    <tr key={x.id}>
                                        <td>
                                            <span>{(FactorTypes as any)[x.factor_type].value}</span>
                                            {
                                                _.size(x.friendly_name || '') > 0 ?
                                                    <div className="text-muted mt-2">{x.friendly_name}</div> : null
                                            }
                                        </td>
                                        <td>{x.status.toUpperCase()}</td>
                                        <td>
                                            <Popconfirm
                                                title="Remove Factor"
                                                description="Are you sure to remove this factor?"
                                                onConfirm={() => remove2fa(x.id)}
                                                okText="Yes"
                                                cancelText="No"
                                                disabled={!enableTwoFactor}
                                            >
                                                <Button size='small' danger ghost
                                                    disabled={!enableTwoFactor}>
                                                    Remove
                                                </Button>
                                            </Popconfirm>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Card>
            </>
        </Spin>

        <Modal open={enrollModalOpen}
            footer={null}
            width={800}
            destroyOnClose={true}
            onCancel={() => onEnrollCancelled()}>
            <EnrollTOTP onCancelled={onEnrollCancelled} onEnrolled={onEnrolled} />
        </Modal>
    </>
    )
}

export { Profile };

