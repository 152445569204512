import { Button, Card, DatePicker, Form, Input, Select, Space, Spin, message } from "antd";
import dayjs from 'dayjs';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { selectMasterDataByType } from "../../../redux/MasterDataSlice";
import { selectProjectById, selectProjectSettings, updateProject } from "../../../redux/ProjectsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RTKBaseApi } from "../../../redux/rtkquery/BaseApi";
import { useGetCustomerSelectListQuery } from "../../../redux/rtkquery/CustomerApi";
import { useUpdateProjectMutation } from "../../../redux/rtkquery/ProjectApi";
import { useGetUserSelectListQuery } from "../../../redux/rtkquery/UserApi";
import { RTKQueryTags, Roles } from "../../common/Enums";
import { ActiveUserModel, CustomerSelectModel, ProjectModel } from "../../models";
import { useAuth } from "../auth";

type ComponentProps = {
    projectId: number
}

const EditProject: React.FC<ComponentProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const { user_metadata } = useAuth();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [userList, setUserList] = useState<ActiveUserModel[]>([]);
    const [customerList, setCustomerList] = useState<CustomerSelectModel[]>([]);
    const [triggerUpdateProject, updateProjectResult] = useUpdateProjectMutation();
    const allCustomerListState = useGetCustomerSelectListQuery();
    const allUserListState = useGetUserSelectListQuery();
    const allProjectStatus = useAppSelector(state => selectMasterDataByType(state, "ProjectStatus"));
    const project = useAppSelector(state => selectProjectById(state, props.projectId)) as ProjectModel;
    const projectSettings = useAppSelector(state => selectProjectSettings(state));
    let timeout: ReturnType<typeof setTimeout> | null;

    const onFinishFailed = (errorInfo: any) => {
        message.error("Please fix highlighted errors");
    }

    const onFinish = async (_project: ProjectModel) => {

        if (!_project.customerId)
            _project.customerName = customerList.find(x => x.customerId === _project.customerId)?.customerName;

        _project.projectId = project.projectId;

        await triggerUpdateProject(_project);
    }

    useEffect(() => {
        setLoading(updateProjectResult.isLoading);

        if (updateProjectResult.requestId && !updateProjectResult.isLoading) {
            const _project = updateProjectResult.data?.data;

            if (_project) {
                if (updateProjectResult.originalArgs?.customerId === 0)
                    dispatch(RTKBaseApi.util.invalidateTags([RTKQueryTags.CustomerSelectList]));

                dispatch(updateProject({ project: _project, user: user_metadata || null }));
                form.setFieldsValue(_.cloneDeep(_project));
            }
        }

    }, [updateProjectResult]);

    useEffect(() => {
        if (!userList.length && allUserListState.data?.length) {
            setUserList(_.cloneDeep(allUserListState.data));
        }
    }, [allUserListState]);

    useEffect(() => {
        if (!customerList.length && allCustomerListState.data?.length)
            setCustomerList(_.cloneDeep(allCustomerListState.data));
    }, [allCustomerListState]);

    useEffect(() => {
        form.setFieldValue('approvers', project.approvers || []);
    }, [])

    const onReset = () => {
        form.setFieldsValue(_.cloneDeep(project))
    }

    const fetchCustomer = (value: string, callback: Function) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        const filterCustomer = () => {
            let list = _.cloneDeep(allCustomerListState.data?.filter(x => x.customerName.toLowerCase().includes(_.trim(value).toLowerCase())) || []);
            if (!list.length)
                list.push({ customerId: 0, customerName: _.trim(value) });
            callback(list);
        }

        if (_.isNumber(value))
            return;

        if (value)
            timeout = setTimeout(filterCustomer, 300);
        else
            callback(_.cloneDeep(allCustomerListState.data));
    }

    const onCustomerSearch = (newValue: string) => {
        fetchCustomer(newValue, setCustomerList);
    }

    return (
        <Spin spinning={loading}>
            <Card>
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    form={form}
                    name="AddProject"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={project}
                    disabled={!projectSettings?.hasFullAccess}
                >
                    <Form.Item name="projectName" label="Name"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="customerId" label="Customer"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Select
                            showSearch
                            allowClear
                            placeholder={"Select Customer from list or type name to add new"}
                            filterOption={false}
                            onSearch={onCustomerSearch}
                            onChange={onCustomerSearch}
                            notFoundContent={null}
                            fieldNames={{ label: 'customerName', value: 'customerId' }}
                            options={customerList}
                            loading={allCustomerListState.isFetching}
                        />
                    </Form.Item>

                    <Form.Item name="dueDate" label="Due Date"
                        getValueProps={i => ({ value: i ? (i.format ? i : dayjs(i)) : undefined })}
                        normalize={value => value?.format("MM/DD/YYYY") || value}
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <DatePicker
                            format="MM/DD/YYYY"
                            className="w-100"
                        />
                    </Form.Item>

                    <Form.Item name="team" label="Team"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Select
                            mode="multiple"
                            loading={allUserListState.isFetching}
                        >
                            {
                                _.map(allUserListState.data?.filter(x => _.intersection(x.roles, [Roles.CompanyAdmin, Roles.ProjectOwner, Roles.Collaborator]).length), x => (
                                    <Select.Option key={x.userId}
                                        value={x.userId}
                                        disabled={x.userId === user_metadata?.userId}>
                                        {x.fullName}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item name="projectOwnerId" label="Owner"
                    rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Select
                            fieldNames={{ label: "fullName", value: "userId" }}
                            options={allUserListState.data?.filter(x => _.intersection(x.roles, [Roles.CompanyAdmin, Roles.ProjectOwner]).length)}
                            loading={allUserListState.isFetching}
                        />
                    </Form.Item>

                    <Form.Item name="approvers" label="Approvers">
                        <Select
                            mode="multiple"
                            options={allUserListState.data?.filter(x => _.intersection(x.roles, [Roles.CompanyAdmin, Roles.ProjectOwner]).length)}
                            fieldNames={{ label: "fullName", value: "userId" }}
                            loading={allUserListState.isFetching}
                        />
                    </Form.Item>

                    <Form.Item name="statusId" label="Status"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Select
                            options={allProjectStatus}
                            fieldNames={{ label: "name" }}
                        />
                    </Form.Item>

                    <div className="text-end mb-0">
                        <Space>
                            <Button size="large" onClick={onReset}>
                                Reset
                            </Button>
                            <Button type="primary" size="large" htmlType="submit">
                                Save Changes
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Card>
        </Spin>
    )
}

export { EditProject };

