import { App, Button, Checkbox, Drawer, Form, Input, Select, Space, Spin } from "antd";
import _, { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useGetCustomerSelectListQuery } from "../../../redux/rtkquery/CustomerApi";
import { useGetPricingInstructionSelectListQuery } from "../../../redux/rtkquery/PricingApi";
import { useGetUserSelectListQuery } from "../../../redux/rtkquery/UserApi";
import { Roles } from "../../common/Enums";
import { ActiveUserModel, CustomerSelectModel, PricingInstructionListItemModel, PricingRequestModel } from "../../models";
import { useAuth } from "../auth";

type ComponentProps = {
    onClose: (model?: PricingRequestModel | null) => void,
    pricingRequest: PricingRequestModel,
    enablePricingInstruction: boolean
}

const PricingRequestEdit: React.FC<ComponentProps> = (props) => {
    const pricingRequest = cloneDeep(props.pricingRequest);
    const { notification } = App.useApp();
    const { user_metadata } = useAuth();
    const [form] = Form.useForm();
    const [pricingInstructionChanged, setPricingInstructionChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userList, setUserList] = useState<ActiveUserModel[] | null>(null);
    const [customerList, setCustomerList] = useState<CustomerSelectModel[]>([]);
    const allPricingInstructionSelectList = useGetPricingInstructionSelectListQuery();
    const allCustomerList = useGetCustomerSelectListQuery();
    const allUserList = useGetUserSelectListQuery();
    let timeout: ReturnType<typeof setTimeout> | null;

    const onFinishFailed = (errorInfo: any) => {
        notification.warning({
            message: "Validation failed",
            description: "Please fix the highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = (values: PricingRequestModel) => {

        if (!values.customerId)
            values.customerName = customerList.find(x => x.customerId === values.customerId)?.customerName;

        values.pricingRequestId = pricingRequest.pricingRequestId;

        const _pricingRequest = {
            ...pricingRequest,
            ...values
        }

        setLoading(true);
        props.onClose(_pricingRequest);
    }

    const onPricingInstructionChange = (value: PricingInstructionListItemModel) => {
        setPricingInstructionChanged(value.id !== pricingRequest.pricingInstructionId);
    }

    useEffect(() => {
        if (!allUserList.isFetching && userList == null && allUserList.data?.length) {
            let _userList = allUserList.data.filter(x => _.intersection(x.roles, [Roles.PricingAdmin, Roles.PricingMember]).length > 0);
            setUserList(_userList);
        }
    }, [allUserList, userList]);

    useEffect(() => {
        if (!allCustomerList.isFetching && !customerList.length && allCustomerList.data?.length)
            setCustomerList(_.cloneDeep(allCustomerList.data));
    }, [allCustomerList]);

    useEffect(() => {
        form.setFieldValue('approver', pricingRequest.approver || []);
    }, []);

    const fetchCustomer = (value: string, callback: Function) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        const filterCustomer = () => {
            let list = _.cloneDeep(allCustomerList.data?.filter(x => x.customerName.toLowerCase().includes(_.trim(value).toLowerCase())) || []);
            if (!list.length)
                list.push({ customerId: 0, customerName: _.trim(value) });
            callback(list);
        }

        if (_.isNumber(value))
            return;

        if (value)
            timeout = setTimeout(filterCustomer, 300);
        else
            callback(_.cloneDeep(allCustomerList.data));
    }

    const onCustomerSearch = (newValue: string) => {
        fetchCustomer(newValue, setCustomerList);
    }

    return (
        <Drawer
            title="Pricing Request Details"
            open={true}
            closable={true}
            maskClosable={false}
            keyboard={false}
            width={700}
            destroyOnClose={true}
            onClose={() => props.onClose()}>
            <Spin spinning={loading}>
                <Form
                    layout="vertical"
                    form={form}
                    name="PricingRequestEdit"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={pricingRequest}
                >
                    <Form.Item name="requestName" label="Pricing Request Name"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Input placeholder="Enter Request Name" />
                    </Form.Item>

                    <Form.Item name="customerId" label="Customer">
                        <Select
                            showSearch
                            allowClear
                            placeholder={"Select Customer from list or type name to add new"}
                            filterOption={false}
                            onSearch={onCustomerSearch}
                            onChange={onCustomerSearch}
                            notFoundContent={null}
                            fieldNames={{ label: 'customerName', value: 'customerId' }}
                            options={customerList}
                            loading={allCustomerList.isFetching}
                        />
                    </Form.Item>

                    <Form.Item name="pricingInstructionId" label="Price Book" className="mb-0"
                        rules={[{ required: true, message: "'${label}' is required" }]}
                        tooltip={ !props.enablePricingInstruction && "The Price Book cannot be changed once the chat has begun." }>
                        <Select
                            options={allPricingInstructionSelectList.data || []}
                            fieldNames={{ label: "instructionName", value: "id" }}
                            loading={allPricingInstructionSelectList.isFetching}
                            onChange={onPricingInstructionChange}
                            disabled={!props.enablePricingInstruction}
                        />
                    </Form.Item>

                    <Form.Item name="defaultInstruction" valuePropName="checked"
                        className="mb-0" hidden={!pricingInstructionChanged}>
                        <Checkbox className="fs-7 text-primary">
                            Use this price book as the default for future requests.
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name="team" label="Team" className="mt-3">
                        <Select mode="multiple"
                            loading={allUserList.isFetching}
                            placeholder="Select Team members"
                        >
                            {
                                _.map(userList, x => (
                                    <Select.Option key={x.userId}
                                        value={x.userId}
                                        disabled={x.userId === user_metadata?.userId || x.userId === pricingRequest.createdById}>
                                        {x.fullName}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item name="approver" label="Approvers">
                        <Select
                            mode="multiple"
                            options={userList || []}
                            fieldNames={{ label: "fullName", value: "userId" }}
                            loading={allUserList.isFetching}
                            placeholder="Select Approvers"
                        />
                    </Form.Item>

                    <Form.Item className="text-end mt-10">
                        <Space>
                            <Button size="large"
                                disabled={loading}
                                onClick={() => props.onClose()}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit"
                                size="large"
                                disabled={allPricingInstructionSelectList.isFetching}
                                loading={loading}>
                                Save Changes
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}

export { PricingRequestEdit };

