import type { UploadFile } from "antd";
import { Button, Form, Space, Spin } from "antd";
import _, { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useLazyGetDatasetsQuery, useSaveDatasetMutation } from "../../../redux/rtkquery/CompanyApi";
import { ActionType, DataSetContentType, DataSetType } from "../../common/Enums";
import FileUpload from "../../common/components/FileUpload";
import HtmlEditor from "../../common/components/HtmlEditor";
import { DataSetModel } from "../../models";

type ComponentProps = {
    nextStep?: () => void,
    prevStep?: () => void,
    source: string,
    isIndexing?: boolean
}

interface CompanyInfoModel {
    contentDescription: string
    fileList: UploadFile[]
}

const CompanyInfo: React.FC<ComponentProps> = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const formData = React.useRef<DataSetModel | null>(null);
    const [triggerGetDataSets] = useLazyGetDatasetsQuery();
    const [triggerSaveDataset] = useSaveDatasetMutation();
    const editorRef = useRef<any>(null);

    const loadData = () => {
        setLoading(true);
        triggerGetDataSets([DataSetType.CompanyInfo])
            .then(response => {
                if (response.data) {
                    let data = cloneDeep(response.data?.[0] || null);
                    formData.current = data;
                    let dataset: CompanyInfoModel = {
                        contentDescription: '',
                        fileList: []
                    }

                    _.forEach(data?.datasetContent, x => {
                        switch (x.contentTypeId) {
                            case DataSetContentType.RichText:
                                dataset.contentDescription = x.contentDescription;
                                break;
                            case DataSetContentType.File:
                                dataset.fileList.push({
                                    uid: x.datasetContentId.toString(),
                                    name: x.contentTitle || "no-name",
                                    status: 'done',
                                    fileName: x.contentDescription,
                                    linkProps: {
                                        includeForAttachment: x.includeForAttachment,
                                        includeForTraining: x.includeForTraining
                                    }
                                })
                                break;
                        }
                    });

                    form.setFieldsValue(dataset);
                    setLoading(false);
                }
            });
    }

    const onReset = () => {
        form.resetFields();
    }

    const onFinish = async (values: CompanyInfoModel) => {
        setLoading(true);

        if (!_.trim(editorRef.current.getContent({ format: "text" }))) {
            form.setFieldValue("contentDescription", '');
            values.contentDescription = '';
        }

        let dataset: DataSetModel = formData.current || {
            datasetId: 0,
            datasetName: "Company Information",
            datasetTypeId: DataSetType.CompanyInfo,
            datasetContent: []
        }

        if (!dataset.datasetContent)
            dataset.datasetContent = []

        dataset.datasetContent.forEach(x => x.action = ActionType.Delete);

        values.contentDescription = _.replace(values.contentDescription, /<p><br><\/p>+/g, "");
        values.contentDescription = _.replace(values.contentDescription, /[\n\r]/g, "");
        values.contentDescription = _.trim(values.contentDescription);

        let datasetContentId = 0,
            existingContent = dataset.datasetId > 0 ?
                dataset.datasetContent.find(d => d.contentTypeId === DataSetContentType.RichText) : undefined;

        if (existingContent) {
            existingContent.action = !values.contentDescription ? ActionType.Delete :
                (_.toLower(_.trim(existingContent.contentDescription)) !== _.toLower(values.contentDescription) ? ActionType.Update : null);
            existingContent.contentDescription = values.contentDescription;
        }
        else if (values.contentDescription) {
            dataset.datasetContent.push({
                datasetContentId: --datasetContentId,
                datasetId: dataset.datasetId,
                contentTitle: 'Company Information',
                contentTypeId: DataSetContentType.RichText,
                contentDescription: values.contentDescription,
                action: ActionType.Insert
            });
        }

        _.forEach(values.fileList, x => {
            if (x.status === "done") {
                let existingFile = dataset.datasetContent.find(d => d.datasetContentId === Number(x.uid));

                if (existingFile) {

                    existingFile.action = null;
                    let includeForAttachment = x.linkProps?.includeForAttachment ?? false;
                    let includeForTraining = x.linkProps?.includeForTraining ?? false;

                    if ((existingFile.includeForAttachment ?? false) !== includeForAttachment ||
                        (existingFile.includeForTraining ?? false) !== includeForTraining)
                        existingFile.action = ActionType.Update;

                    existingFile.includeForAttachment = includeForAttachment;
                    existingFile.includeForTraining = includeForTraining;
                    return;
                }

                dataset.datasetContent.push({
                    datasetContentId: Number(x.uid),
                    datasetId: dataset.datasetId,
                    contentTypeId: DataSetContentType.File,
                    contentTitle: x.name,
                    contentDescription: x.fileName || '',
                    action: ActionType.Insert,
                    includeForAttachment: x.linkProps?.includeForAttachment ?? false,
                    includeForTraining: x.linkProps?.includeForTraining ?? false
                })
            }
        })

        let response = await triggerSaveDataset(dataset);
        setLoading(false);

        if ('data' in response) {
            if (props.source === "InitialSetup")
                props.nextStep?.();
            else
                loadData();
        }
    }

    const onFileUploadComplete = (file: UploadFile, validFileForActions?: boolean) => {
        if(validFileForActions)
            file.linkProps = { includeForTraining: true };
    }

    useEffect(loadData, []);

    return (
        <Spin spinning={loading}>
            <Form
                layout="vertical"
                form={form}
                name="CompanyInfo"
                onFinish={onFinish}
                autoComplete="off"
                disabled={props.isIndexing}
            >
                <Form.Item name="contentDescription"
                    label="Provide a detailed background about your company, it's history, how you help customers, results you help customers achieve, things that make your company, etc.">
                    <HtmlEditor setEditorRef={(editor: any) => editorRef.current = editor} />
                </Form.Item>

                <FileUpload form={form} maxCount={10} extraActions={true} 
                    onUploadComplete={onFileUploadComplete}
                    allowedFileTypes={[".pdf", ".docx", ".jpg", ".jpeg"]}
                    label="Upload documents with detailed information about your company."
                />

                <Form.Item className="text-end mb-0">
                    {
                        (props.source === "InitialSetup") ?
                            <Space>
                                <Button size="large" onClick={props.prevStep}>
                                    Back
                                </Button>
                                <Button type="primary" htmlType="submit" size="large">
                                    Next Step
                                </Button>
                            </Space> :
                            <Space>
                                <Button size="large" onClick={onReset}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit" size="large">
                                    Save Changes
                                </Button>
                            </Space>
                    }
                </Form.Item>
            </Form>
        </Spin>
    )
}

export { CompanyInfo };

